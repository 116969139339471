import React, { useEffect, useState } from "react";
import InputField from "../../components/form/input/input";
import ButtonField from "../../components/form/button";
import SearchSelectField from "../../components/form/select/SearchSelect";
import {
  FieldOptions,
  PaymentOptions,
  PaymentReturnOptions,
} from "../../constant";
import FilterAccordian from "../../components/common/Filter/filter";
import { useSelector } from "react-redux";

const Filterform = ({ type, setApply, filters, setFilters, onChange }) => {
  const [profilesList, setProfilesList] = useState();
  const { customerProfiles, supplierProfiles } = useSelector(
    (state) => state.profiles
  );

  const handleReset = () => {
    setFilters({
      orderId: "",
      paymentMethod: "",
      paymentReturn: "",
      startDate: "",
      endDate: "",
      profile: "",
    });
  };

  useEffect(() => {
    if (type === "customer") {
      setProfilesList(customerProfiles);
    } else {
      setProfilesList(supplierProfiles);
    }
  }, [type]);

  return (
    <FilterAccordian>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setApply((pre) => !pre);
        }}
      >
        <div className="border-top p-3">
          <div className="row">
            <div className="col-2">
              <label htmlFor="" className="text-capitalize">
                Profile
              </label>
              <SearchSelectField
                className={"inputclass rounded flex-grow-1"}
                name="profile"
                value={filters.profile}
                valueKey="id"
                labelKey="name"
                onChange={(e) => onChange("profile", e.id)}
                options={profilesList}
              />
            </div>
            <div className="col-2">
              <InputField
                label="Order id"
                placeholder={"Order Id"}
                name="orderId"
                value={filters.orderId}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </div>
            <div className="col-2">
              <label htmlFor="" className="text-capitalize">
                Payment return
              </label>
              <SearchSelectField
                className={"inputclass rounded flex-grow-1"}
                name="paymentReturn"
                value={filters.paymentReturn}
                valueKey="id"
                labelKey="name"
                onChange={(e) => onChange("paymentReturn", e.id)}
                options={PaymentReturnOptions}
              />
            </div>
            <div className="col-2">
              <label htmlFor="" className="text-capitalize">
                Payment method
              </label>
              <SearchSelectField
                className={"inputclass rounded flex-grow-1"}
                name="paymentMethod"
                value={filters.paymentMethod}
                valueKey="val"
                labelKey="name"
                onChange={(e) => onChange("paymentMethod", e.val)}
                options={PaymentOptions}
              />
            </div>
            <div className="col-2">
              <InputField
                type="date"
                label="Start Date"
                placeholder={"From"}
                classes={"mb-0"}
                name="startDate"
                value={filters.startDate}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </div>
            <div className="col-2">
              <InputField
                type="date"
                label="End Date"
                placeholder={"To"}
                classes={"mb-0"}
                name="endDate"
                value={filters.endDate}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </div>

            <div className="d-flex justify-content-end">
              <div className="d-flex align-items-center gap-2 h-100">
                <ButtonField
                  buttonText="Reset"
                  onClick={handleReset}
                  className="btn btn-danger"
                />
                <ButtonField type="Submit" buttonText="Apply" />
              </div>
            </div>
          </div>
        </div>
      </form>
    </FilterAccordian>
  );
};

export default Filterform;
