import React from "react";

const PayableInFormation = ({
  payable,
  paidAmount,
  isPaid,
  walletBalance,
  total,
}) => {
  // line 18 && !walletBalance <= 0
  return (
    <>
      {total ? (
        <p>
          {paidAmount > total
            ? `Your extra paid amount ${
                paidAmount - total
              } will be deposited in your wallet balance.`
            : paidAmount < total && isPaid === "NO" && walletBalance > 0
            ? paidAmount + walletBalance < total
              ? `Your paid amount is less than payable, your wallet balance ${walletBalance} will be used as well.`
              : `Your Paid amount is less than payable, ${
                  total - paidAmount
                } will be adjusted from your wallet`
            : ""}

          {/* {paidAmount + (walletBalance >= 0 ? walletBalance : 0) > total
            ? `Your extra paid amount ${Math.abs(payable)} will be deposited in
                      your wallet balance.`
            : paidAmount + walletBalance < total && walletBalance > 0
            ? `Your paid amount is less than payable, your wallet balance ${walletBalance} will be used as well.`
            : ""} */}
        </p>
      ) : (
        <></>
      )}
    </>
  );
};

export default PayableInFormation;
