import { Form, Formik } from "formik";
import { parseInt } from "lodash";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { ErrorMessage, Field, FieldArray } from "formik";
import {
  calculateTotalAmount,
  getAllProfiles,
  getProductService,
} from "../../../services";
import { PaymentOptionsOrder } from "../../../constant";
import Select from "react-select";
import { debounce } from "lodash";

import InputField from "../../../components/form/input/input";
import ButtonField from "../../../components/form/button";
import httpRequest from "../../../axios";
import show_Toast from "../../../helpers/toast.helper";
import SearchSelectField from "../../../components/form/select/SearchSelect";
import "./index.css";
import "./index.scss";

import TextArea from "../../../components/form/textarea/input";
import OrderAmount from "./OrderAmount";
import PayableAmount from "./PayableAmount";
import ReturnAmount from "./returnAmount";
import ReturnProductDetailTable from "./ReturnProductDetailTable";
import ReturnMultipleUnits from "./ReturnMultipleUnits";
import {
  CUSTOMER_RETURN_ORDERS,
  SUPPLIER_RETURN_ORDERS,
} from "../../../constant/apiEndPoints";

const ReturnOrderForm = ({
  initialValues,
  type,
  id,
  preSelctedProducts = [],
  orderType = "",
  order = "",
  user,
}) => {
  // console.log("initial values: ", initialValues);
  const history = useHistory();
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate());
  const minDate = currentDate.toISOString().split("T")[0];

  const [isLoading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [profiles, setprofiles] = useState([]);
  const [removedProducts, setRemovedProducts] = useState([]);
  const [removeIndex, setRemoveIndex] = useState(null);
  const [isReceived, setIsReceived] = useState(initialValues.is_received);
  const [isPaid, setIsPaid] = useState(initialValues.is_paid);
  const [selectedProductNames, setSelectedProductNames] = useState([]);
  const [selectedProfileId, setSelectedProfileId] = useState();
  const [walletBalance, setWalletBalance] = useState();
  const [selectedProductOptions, setSelectedProductOptions] = useState([]);
  const [total, setTotal] = useState(0);
  const [payable, setPayable] = useState(0);
  const [returnAmount, setReturnAmount] = useState(0);
  const [toastShown, setToastShown] = useState(false);

  const [isCashbackDisable, setIsCashbackDisable] = useState();
  const [isDepositInDisable, setIsDepositInDisable] = useState();

  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [totalStock, setTotalStock] = useState(0);

  const calculateAdjustedSum = (id) => {
    let currProduct = initialValues?.products.find((item) => item?.id === id);
    let sum = 0;
    const units = currProduct.units;
    units.forEach((item) => {
      if (item?.isBaseUnit === 1) {
        sum = sum + parseFloat(item?.value ? item?.value : 0);
      } else {
        let res =
          parseFloat(item?.value ? item?.value : 0) * item?.originalValue;
        sum = sum + res;
      }
    });

    return sum;
  };

  const getPosts = async () => {
    try {
      const { data } = await getProductService();
      if (data.status === "Success") {
        let poss = data?.data?.data;
        if (poss.length) {
          poss = poss.map((item) => {
            return {
              ...item,
              label: item?.data?.code + " - " + item?.data?.name,
              productcode: item?.data?.code,
              unitPrice: item?.data?.unit_price,
              productgst: item?.data?.gst,
              sellingUnitId: item?.data?.selling_unit_id,
              sellingUnitName: item?.data?.selling_unit_title,
              sellingUnitSymbol: item?.data?.selling_unit_symbol,
            };
          });
          setPosts(poss);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getProfiles = async () => {
    try {
      const { data } = await getAllProfiles(-1, 1, "", orderType);
      if (data.status === "Success") {
        let poss = data?.data?.data;

        if (poss.length) {
          poss = poss.map((item) => {
            return {
              ...item,
              label:
                item?.data?.name + "     ( " + item?.data?.mobile_number + " )",
              value:
                item?.data?.name + "     ( " + item?.data?.mobile_number + " )",
            };
          });
          if (user === "customer") {
            poss.unshift({
              label: "walk-in customer",
              value: "walk-in customer",
              id: 0,
            });
          }
        } else {
          if (user === "customer") {
            poss.unshift({
              label: "walk-in customer",
              value: "walk-in customer",
              id: 0,
            });
          }
        }
        setprofiles(poss);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPosts();
    getProfiles();
  }, []);

  useEffect(() => {
    if (type === "edit") {
      setSelectedProducts([...preSelctedProducts]);
    }
  }, [type, preSelctedProducts]);

  useEffect(() => {
    setSelectedProfileId(initialValues.profile_id);
  }, [initialValues.profile_id]);

  useEffect(() => {
    setIsPaid(initialValues.is_paid);
    setIsReceived(initialValues.is_received);
  }, [
    initialValues.is_paid,
    initialValues.is_received,
    initialValues.profile_id,
  ]);

  useEffect(() => {
    let arr = initialValues.products;
    let totalPrice = 0;
    for (let product of arr) {
      totalPrice += product.price;
    }
  }, [removeIndex]);

  // Calculate total cost based on unit values and cost input
  const calculateTotalCost = (units, cost, sellingunitid) => {
    const unitSumOtherThanBaseUnit = units
      ?.filter((item) => !item.isBaseUnit)
      .map(
        ({ value, originalValue }) =>
          (parseFloat(value) || 0) * (parseFloat(originalValue) || 0)
      )
      .reduce((sum, value) => sum + value, 0);

    const baseUnitValue = units.find((item) => item?.isBaseUnit)?.value || 0;

    const sum = unitSumOtherThanBaseUnit + parseFloat(baseUnitValue);

    const sellingUnitOriginalVal = units.find(
      (item) => item?.id === parseInt(sellingunitid)
    )?.originalValue;
    return calculateTotalAmount(sum / sellingUnitOriginalVal, parseFloat(cost));
  };

  const handleProductChange = (value) => {
    const lastSelectedProduct = selectedProducts[selectedProducts?.length - 1];
    if (lastSelectedProduct) {
      const hasNonEmptyValue = lastSelectedProduct?.units?.some(
        (unit) => unit?.value !== ""
      );
      if (!hasNonEmptyValue) {
        show_Toast({
          status: false,
          message: "At least one unit value is required.",
        });
        return;
      }
    }

    if (!selectedProducts.some((product) => product.id === value.id)) {
      setSelectedProducts([...selectedProducts, value]);
    }
  };

  const filteredOptions = initialValues?.products?.filter((product) => {
    return !selectedProducts?.some(
      (selectedProduct) => selectedProduct?.id === product?.id
    );
  });

  const createReturn = async (values, setSubmitting) => {
    setLoading(true);
    // console.log(values);
    let setValuesForApi;
    if (values?.return_adjust === "depositin") {
      delete values?.payment_method;
    }
    setValuesForApi = {
      ...values,
    };
    let modifyProducts = selectedProducts?.map((item) => {
      let stocks = [];
      if (item.units.length) {
        stocks = item.units.map((uni) => ({
          unit_id: uni.id,
          stock: uni.value,
        }));
      }
      return {
        product_id: item.name,
        product_cost: item.price,
        gst: item?.productGst,
        discount: item.discount,
        selling_unit_id: item?.sellingUnitId,
        requested_stock: stocks,
      };
    });

    try {
      var { data } = await httpRequest.post(
        user === "customer"
          ? `${CUSTOMER_RETURN_ORDERS}${id}`
          : `${SUPPLIER_RETURN_ORDERS}${id}`,
        {
          ...setValuesForApi,
          products: selectedProducts?.length ? modifyProducts : [],
          profile_id: parseInt(values.profile_id),
        }
      );
      setSubmitting(false);
      setLoading(false);
      show_Toast({
        status: true,
        message: data.status === "Success" && "Order returned successfully",
      });
      history.push("/orders/" + orderType);
    } catch (error) {
      setSubmitting(false);
      setLoading(false);
      show_Toast({
        status: false,
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };

  const showToasterDebounced = debounce(() => {
    show_Toast({
      status: false,
      message: "Requested stock limit",
    });
  }, 500);

  useEffect(() => {}, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        createReturn(values, setSubmitting);
      }}
      enableReinitialize
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          setFieldValue,
          handleBlur,
          handleChange,
          isSubmitting,
        } = props;

        return (
          <Form className="form theme-form">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex justify-content-end absolute-total gap-4">
                    <OrderAmount
                      totalOrderAmount={initialValues?.total_order_amount}
                      products={initialValues?.products}
                    />
                    <PayableAmount
                      payable={payable}
                      setPayable={setPayable}
                      setFieldValue={setFieldValue}
                      products={selectedProducts}
                      paid_amount={values.paid_amount}
                    />
                    <ReturnAmount
                      returnAmountInitial={initialValues?.return_amount}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="d-flex justify-content-end align-items-center gap-4">
                    <div className="radio radio-primary">
                      <input
                        id="cashback"
                        type="radio"
                        name="topradio"
                        value={"cashback"}
                        onChange={(e) => {
                          setFieldValue("return_adjust", e.target.value);
                        }}
                        disabled={isCashbackDisable}
                        checked={values?.return_adjust === "cashback"}
                      />
                      <label htmlFor="cashback">Cashback</label>
                    </div>

                    <div className="radio radio-primary">
                      <input
                        id="depositin"
                        type="radio"
                        name="topradio"
                        value={"depositin"}
                        onChange={(e) => {
                          setFieldValue("return_adjust", e.target.value);
                        }}
                        disabled={isDepositInDisable}
                        checked={values?.return_adjust === "depositin"}
                      />
                      <label htmlFor="depositin">Deposit in</label>
                    </div>
                  </div>

                  <div className="col-md-8">
                    <div className="mb-3">
                      <h6 className="">Add Product</h6>
                      <SearchSelectField
                        className={"inputclass rounded"}
                        name={"productfieldRow"}
                        onChange={handleProductChange}
                        placeholder="Select Product"
                        labelKey={"label"}
                        valueKey={"id"}
                        onBlur={handleBlur}
                        options={filteredOptions}
                      />
                    </div>
                    <div className="row">
                      <div className="col-md-12 product-scroll-custom-return mt-3">
                        <FieldArray name="products">
                          {({ push, remove }) => (
                            <>
                              {selectedProducts.map((product, index) => {
                                const sellingUnit = posts.find(
                                  (post) => post.id == product.name
                                );
                                return (
                                  <div
                                    key={index}
                                    className={`row highlight mb-2 pt-0`}
                                    style={{ fontSize: "12px" }}
                                  >
                                    <div className="d-flex justify-content-end align-items-start mb-2 mt-1 p-0">
                                      <button
                                        type="button"
                                        className="btn btn-red p-0"
                                        style={{
                                          width: "35px",
                                          borderRadius: "3px",
                                          marginRight: "3px",
                                        }}
                                        onClick={() => {
                                          setSelectedProducts(
                                            (prevProducts) => {
                                              const updatedProducts = [
                                                ...prevProducts,
                                              ];
                                              updatedProducts.splice(index, 1);
                                              setFieldValue(
                                                "products",
                                                updatedProducts
                                              );
                                              //new
                                              return updatedProducts;
                                            }
                                          );
                                        }}
                                      >
                                        -
                                      </button>
                                    </div>

                                    <div>
                                      <ReturnProductDetailTable
                                        index={index}
                                        products={initialValues?.products}
                                        code={product?.productcode}
                                        totalPrice={product?.totalPrice}
                                        productPrice={product?.price}
                                        discount={product?.discount}
                                        productGst={product?.productGst}
                                        label={product?.label}
                                      />
                                    </div>

                                    <div className="col-lg-2 mt-2">
                                      <label htmlFor="">
                                        Cost /
                                        {initialValues?.products[index]
                                          ?.sellingUnitName
                                          ? `${` ${initialValues?.products[index]?.sellingUnitName}`}`
                                          : ""}{" "}
                                        /{" "}
                                        {initialValues?.products[index]?.price}
                                      </label>
                                      <Field
                                        className="form-control inputclass"
                                        name={`products.${index}.price`}
                                        value={selectedProducts[index].price}
                                        placeholder="cost"
                                        type="number"
                                        style={{ fontSize: "12px" }}
                                        onChange={(e) => {
                                          const { value } = e.target;
                                          const units =
                                            selectedProducts[index].units;

                                          const updatedTotalPrice =
                                            calculateTotalCost(
                                              units,
                                              parseFloat(value),
                                              selectedProducts[index]
                                                ?.sellingUnitId
                                            );

                                          const updatedProducts =
                                            selectedProducts.map((prod, idx) =>
                                              idx === index
                                                ? {
                                                    ...prod,
                                                    totalPrice:
                                                      updatedTotalPrice,
                                                    price: value,
                                                  }
                                                : prod
                                            );
                                          setSelectedProducts(updatedProducts);
                                        }}
                                      />
                                      <ErrorMessage
                                        name={`products.${index}.price`}
                                        component="div"
                                        className="error-message"
                                      />
                                    </div>
                                    <div className="col-lg-2 mt-2">
                                      <label htmlFor="">
                                        Discount /{" "}
                                        {
                                          initialValues?.products[index]
                                            ?.discount
                                        }
                                      </label>
                                      <Field
                                        className="form-control inputclass"
                                        name={`products.${index}.discount`}
                                        placeholder="discount"
                                        type="number"
                                        style={{ fontSize: "12px" }}
                                        value={selectedProducts[index].discount}
                                        onChange={(e) => {
                                          const { value } = e.target;
                                          const updatedProducts =
                                            selectedProducts.map((prod, idx) =>
                                              idx === index
                                                ? {
                                                    ...prod,
                                                    discount: value,
                                                  }
                                                : prod
                                            );

                                          setSelectedProducts(updatedProducts);
                                        }}
                                      />
                                      <ErrorMessage
                                        name={`products.${index}.price`}
                                        component="div"
                                        className="error-message"
                                      />
                                    </div>
                                    <ReturnMultipleUnits
                                      index={index}
                                      product={product}
                                      currentSelectedUnits={
                                        sellingUnit?.data?.units || []
                                      }
                                      setFieldValue={setFieldValue}
                                      values={values}
                                      initialValues={initialValues}
                                      onChangeUnit={(
                                        e,
                                        productIndex,
                                        unitIndex
                                      ) => {
                                        const adjustedSum =
                                          calculateAdjustedSum(product?.id);
                                        let sum = 0;
                                        const { value } = e.target;
                                        const updatedUnits = selectedProducts[
                                          productIndex
                                        ].units.map((item, index) =>
                                          index === unitIndex
                                            ? {
                                                ...item,
                                                value: value,
                                              }
                                            : item
                                        );
                                        updatedUnits.forEach((item) => {
                                          if (item?.isBaseUnit === 1) {
                                            sum =
                                              sum +
                                              parseFloat(
                                                item?.value ? item?.value : 0
                                              );
                                          } else {
                                            let res =
                                              parseFloat(
                                                item?.value ? item?.value : 0
                                              ) * item?.originalValue;
                                            sum = sum + res;
                                          }
                                        });

                                        if (sum > adjustedSum) {
                                          showToasterDebounced();
                                          return;
                                        }

                                        const updatedProducts =
                                          selectedProducts.map((prod, idx) =>
                                            idx === productIndex
                                              ? {
                                                  ...prod,
                                                  units: updatedUnits,
                                                }
                                              : prod
                                          );

                                        setSelectedProducts(updatedProducts);

                                        const updatedTotalPrice =
                                          calculateTotalCost(
                                            updatedUnits,
                                            parseInt(
                                              updatedProducts[productIndex]
                                                .price
                                            ),
                                            product?.sellingUnitId
                                          );

                                        const updatedProduct = {
                                          ...updatedProducts[productIndex],
                                          totalPrice: updatedTotalPrice,
                                        };
                                        const updatedProductsArray = [
                                          ...updatedProducts,
                                        ];
                                        updatedProductsArray[productIndex] =
                                          updatedProduct;

                                        setFieldValue(
                                          "products",
                                          updatedProductsArray
                                        );
                                        setSelectedProducts(
                                          updatedProductsArray
                                        );
                                      }}
                                    />
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </FieldArray>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="row">
                      <div className="col-md-6">
                        <InputField
                          placeholder="Paid Amount"
                          label="Paid Amount"
                          type="number"
                          name="paid_amount"
                          value={values.paid_amount}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={true}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="" className="text-capitalize">
                          Payment method
                        </label>
                        <SearchSelectField
                          className={
                            "inputclass rounded flex-grow-1 zindex-top"
                          }
                          name="payment_method"
                          value={values.payment_method}
                          onChange={(e) => {
                            setFieldValue("payment_method", e.id);
                          }}
                          onBlur={handleBlur}
                          options={PaymentOptionsOrder}
                          disabled={values.return_adjust !== "cashback"}
                        />
                      </div>
                      <div className="col-md-12 mt-3 mb-3">
                        <label>
                          {orderType === "customer" ? "Customer" : "Supplier"}
                        </label>
                        <Select
                          className="border rounded"
                          options={profiles}
                          name="profile_id"
                          value={profiles.find(
                            (profile) => profile.id === values.profile_id
                          )}
                          onChange={(selectedOption) => {
                            setSelectedProfileId(selectedOption.id);
                            setWalletBalance(selectedOption?.balance);
                            setFieldValue("profile_id", selectedOption.id);
                            if (selectedOption.id === 0) {
                              setFieldValue("is_paid", "YES");
                              setFieldValue("is_received", "YES");
                            }
                          }}
                          onBlur={handleBlur}
                          placeholder={`Search for ${
                            orderType === "customer" ? "Customer" : "Seller"
                          }`}
                          isSearchable
                          isDisabled={true}
                        />
                      </div>
                      <div className="col-md-12" style={{ width: "100%" }}>
                        <div className="row">
                          <div className="col-md-12">
                            <TextArea
                              placeholder="Description"
                              label="Description"
                              type="text"
                              name="description"
                              textAreaRows="2"
                              value={`${
                                values?.description ? values?.description : ""
                              }`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              // disabled={true}
                              error={
                                errors.description && touched.description
                                  ? true
                                  : false
                              }
                              errorMessage={
                                errors.description &&
                                touched.description &&
                                errors.description
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 card-footer d-flex justify-content-end gap-2 me-0 pe-0">
                        <ButtonField
                          className={"btn btn-red"}
                          buttonText={"Cancel"}
                          type="button"
                          onClick={() => {
                            history.goBack();
                          }}
                        />
                        <ButtonField
                          className={"btn btn-primary"}
                          buttonText={"Return"}
                          disabled={isSubmitting}
                          type="submit"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isLoading && <div>Loading...</div>}
          </Form>
        );
      }}
    </Formik>
  );
};

export default ReturnOrderForm;
