export const PAGE_TITLES = {
  DASHBOARD: "Dashboard",

  SIGNIN: "SignIn",

  ForgotPassword: "Forgot Password",

  ChangePassword: "Change Password",

  EditOwnProfile: "Update Own Profile",

  CustomerOrders: "Customer Orders",
  SupplierOrders: "Supplier Orders",

  ReturnCustomerOrders: "Return Customer Orders",
  ReturnSupplierOrders: "Return Supplier Orders",

  Products: "Products",

  CustomerProfiles: "Customer Profiles",
  SupplierProfiles: "Supplier Profiles",

  Attributes: "Attributes",
  AttributesKey: "Attribute Keys",

  Units: "Units",
  UnitCategories: "Unit Categories",

  Expense: "Expenses",
  ExpenseCategories: "Expense Categories",

  Users: "Users",

  SummaryReport: "Summary Report",

  DetailReport: "Detail Report",

  Roles: "Roles",

  Configurations: "Configurations",

  Payments: "Payments",
  OrderAddOn: "Order Add-On",

  Transaction: "Transactions",
  WalletTransactions: "Wallet Transactions",
  InOutStock: "In/Out Stock",
};
