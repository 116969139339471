import * as Yup from "yup";

export const ExpenseValidations = Yup.object().shape({
  amount: Yup.string().required("Amount is required"),
  expense_category: Yup.string().required("Category is required"),
});

export const ExpenseCategoryValidations = Yup.object().shape({
  title: Yup.string().required("Title is required"),
});


