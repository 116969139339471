import React, { Fragment, useEffect, useState, useRef } from "react";
import { getAllAttributeKeys, getUnitsService } from "../../../services";
import show_Toast from "../../../helpers/toast.helper";
import CustomLoader from "../../../components/common/customLoader/customLoader";
import { Edit } from "react-feather";
import { Pagination } from "../../../components/common/pagination";
import KeysFilter from "./keysFilter";
import Search from "../../../components/common/search/search";
import PermissionBase from "../../../components/common/permissionBase/permissionBase";
import AddAttributeKeyForm from "./form";

const KeyListings = () => {
  const formRef = useRef(null);
  const [keysList, setKeyList] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [apply, setApply] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState({
    id: "",
    name: "",
    status: "",
  });

  const [initialValues, setIntialValues] = useState({
    attr_type: "",
  });
  const [formType, setFormType] = useState("add");
  const [transition, setTransition] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);
  const [editId, setEditId] = useState("");

  const onChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
    perPage: 10,
  });

  const getAttributeKeyListing = async () => {
    try {
      setLoading(true);
      const { data } = await getAllAttributeKeys(
        paginationData.perPage,
        paginationData.currentPage,
        searchQuery,
        filters
      );
      if (data.status === "Success") {
        setKeyList(data?.data?.data);
      }
      setPaginationData((pre) => ({
        ...pre,
        currentPage: data?.data?.current_page,
        totalRecords: data?.data?.total,
        perPage: data?.data?.per_page,
        totalPages: Math.ceil(data?.data?.total / data?.data?.per_page),
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      show_Toast({
        status: false,
        message:
          error?.response?.data?.message ||
          "Something went wrong please reload the page or contact with admin",
      });
    }
  };
  useEffect(() => {
    getAttributeKeyListing();
  }, [paginationData.currentPage, formSubmit, apply, searchQuery]);

  return (
    <div className={`row`}>
      <div className="col-sm-12">
        <div
          className={`card mt-2 section ${
            transition ? "show-all-categories" : "hide"
          }`}
          ref={formRef}
        >
          <AddAttributeKeyForm
            initialValues={initialValues}
            setTransition={setTransition}
            formRef={formRef}
            setFormSubmit={setFormSubmit}
            formType={formType}
            type="add"
            id={editId}
            setIntialValues={setIntialValues}
            keysList={keysList}
          />
        </div>
        {!isLoading ? (
          <div className="card mt-2">
            <div className="card-header d-flex justify-content-between">
              <h5 className="text-capitalize">Attribute Key</h5>
              <PermissionBase permissionName={["create_attribute_keys"]}>
                <button
                  className="btn btn-success"
                  onClick={() => {
                    setFormType("add");
                    setTransition(true);
                    setIntialValues({
                      attr_type: "",
                    });
                  }}
                >
                  Add New
                </button>
              </PermissionBase>
            </div>
            <div className="card-body">
              <div className="mb-3 d-flex justify-content-end">
                <Search
                  classes="form-control search-input"
                  placeholder="Search"
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    setPaginationData((pre) => ({
                      ...pre,
                      currentPage: 1,
                    }));
                  }}
                  value={searchQuery}
                />
              </div>

              <div className="mb-3">
                <KeysFilter
                  filters={filters}
                  setFilters={setFilters}
                  setApply={setApply}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Id</th>
                      <th scope="col">Name</th>
                      <th scope="col">Status</th>
                      <PermissionBase
                        permissionName={["update_attribute_keys"]}
                      >
                        <th scope="col">Action</th>
                      </PermissionBase>
                    </tr>
                  </thead>
                  <tbody>
                    {keysList.map((unit, index) => (
                      <tr key={index}>
                        <td>{unit.id}</td>
                        <td>{unit.title}</td>
                        <td>{unit.status}</td>

                        <PermissionBase
                          permissionName={["update_attribute_keys"]}
                        >
                          <td>
                            <span
                              className="pointer-cursor"
                              onClick={() => {
                                setEditId(unit.id);
                                setTransition(true);
                                setIntialValues({
                                  attr_type: unit.title,
                                });
                                setFormType("edit");
                                window.scrollTo({
                                  top: formRef.current.offsetTop,
                                  behavior: "smooth",
                                });
                              }}
                            >
                              <Edit size={20} color="green" />
                            </span>
                          </td>
                        </PermissionBase>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="mt-4 mb-1">
                  <Pagination
                    selectedPage={paginationData.currentPage}
                    pageCount={paginationData.totalPages}
                    onPageChangeHandler={(page) => {
                      setPaginationData({
                        ...paginationData,
                        currentPage: page,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <CustomLoader />
        )}
      </div>
    </div>
  );
};

export default KeyListings;
