import React from "react";
import { Route, Redirect } from "react-router-dom";
import { hasPermissions } from "../helpers/permissions.helper";

export const PrivateRoutes = ({
  isAuthenticated,
  permissions = [],
  givenPermissions = "",
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (!isAuthenticated) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }
      if (permissions.length) {
        if (!hasPermissions(permissions, givenPermissions)) {
          return <Redirect to={{ pathname: "/not-allowed" }} />;
        }
      }
      return <Component {...props} />;
    }}
  />
);
