import React, { useState } from "react";
import { useSelector } from "react-redux";
import { hasPermissions } from "../../../helpers/permissions.helper";

const PermissionBase = ({ permissionName, children }) => {
  const { permissions } = useSelector((state) => state.login);
  const hasPermission = hasPermissions(permissions, permissionName);
  if (hasPermission) {
    return <>{children}</>;
  }
  return null;
};

export default PermissionBase;
