import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../assets/images/endless-logo.png";
import { LOGIN } from "../../constant";
import { signInValidation } from "../../utils/validations/auth.validations";
import InputField from "../../components/form/input/input";
import ButtonField from "../../components/form/button";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import {
  setIsAuthenticated,
  setPermissions,
  setUser,
} from "../../store/slices/login";
import { setAttributeMenus, setUnitsMenus } from "../../store/slices/menus";
import httpRequest from "../../axios/index";
import show_Toast from "../../helpers/toast.helper";
import {
  ATTRIBUTE_KEYS,
  All_UNITS,
  CONFIGRATIONS,
  LOGIN_ENDPOINT,
} from "../../constant/apiEndPoints";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import CheckBoxField from "../../components/form/checkbox/checkbox";
import HelmetComponent from "../../components/common/Helmet";
import { useSelector } from "react-redux";
import {
  setCustomerProfiles,
  setSupplierProfiles,
} from "../../store/slices/profiles";
import {
  getAllCustomerProfiles,
  getAllSupplierProfiles,
  getProductService,
} from "../../services";
import { setProducts } from "../../store/slices/products";
import { PAGE_TITLES } from "../../constant/pageTitles";
const SignIn = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { configurations } = useSelector((state) => state.configurations);

  const [isLoading, setLoading] = useState(false);
  const [mainLogo, setMainLogo] = useState();

  useEffect(() => {
    const logo = configurations.mainLogo;
    setMainLogo(logo);
  }, [configurations]);

  const loginHandler = async (values, setSubmitting) => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await httpRequest.post(LOGIN_ENDPOINT, values);
      dispatch(setUser(data.user));
      dispatch(setIsAuthenticated(true));
      dispatch(setPermissions(data?.user?.role?.permissions));
      localStorage.setItem("accessToken", data.access_token);

      const attribute = await httpRequest.get(ATTRIBUTE_KEYS);
      if (attribute.data.status === "Success") {
        dispatch(setAttributeMenus(attribute?.data?.data?.data));
      }

      const units = await httpRequest.get(All_UNITS);
      if (units.data.status === "Success") {
        dispatch(setUnitsMenus(units?.data?.data));
      }

      const customerProfiles = await getAllCustomerProfiles();
      if (customerProfiles?.data?.status === "Success") {
        let arr = customerProfiles?.data?.data?.data?.map((item) => ({
          id: item?.id,
          name: item?.data?.name,
          phone: item?.data?.mobile_number,
        }));

        const walinCustomer = { id: 0, name: "walk-in customer", phone: "" };
        arr.unshift(walinCustomer);

        dispatch(setCustomerProfiles(arr));
      }

      const supplierProfiles = await getAllSupplierProfiles();
      if (supplierProfiles?.data?.status === "Success") {
        let arr = supplierProfiles?.data?.data?.data?.map((item) => ({
          id: item?.id,
          name: item?.data?.name,
          phone: item?.data?.mobile_number,
        }));
        dispatch(setSupplierProfiles(arr));
      }

      const products = await getProductService();
      if (products?.data?.status === "Success") {
        let productsArray = products?.data?.data?.data.map((item) => {
          return {
            id: item?.id,
            name: item?.data?.name,
          };
        });
        dispatch(setProducts(productsArray));
      }

      setSubmitting(false);
      setLoading(false);
      show_Toast({
        status: true,
        message:
          data.status === "Success"
            ? "Login successful!"
            : "Login successfully",
      });
      history.push("/");
    } catch (error) {
      setSubmitting(false);
      setLoading(false);
      show_Toast({
        status: false,
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };

  return (
    <>
      <HelmetComponent titleText={PAGE_TITLES["SIGNIN"]} />
      <div className="page-wrapper">
        <div className="container-fluid p-0">
          {/* <!-- login page start--> */}
          <div className="authentication-main">
            <div className="row">
              <div className="col-md-12">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="text-center">
                      <img src={mainLogo} width={"80px"} alt="" />
                    </div>
                    <div className="card mt-4">
                      <div className="card-body">
                        <div className="text-center">
                          <h4>{LOGIN}</h4>
                        </div>
                        {isLoading && <div>Loading...</div>}
                        <Formik
                          initialValues={{
                            email_username: "",
                            password: "",
                          }}
                          onSubmit={(values, { setSubmitting }) => {
                            loginHandler(values, setSubmitting);
                          }}
                          validationSchema={signInValidation}
                        >
                          {(props) => {
                            const {
                              values,
                              touched,
                              errors,
                              handleBlur,
                              handleChange,
                              isSubmitting,
                            } = props;
                            return (
                              <Form className="theme-form">
                                <InputField
                                  placeholder="Enter email or username"
                                  label="Email/Username"
                                  // type="email"
                                  name="email_username"
                                  value={values.email_username}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  errorMessage={
                                    errors.email_username &&
                                    touched.email_username &&
                                    errors.email_username
                                  }
                                  error={
                                    errors.email_username &&
                                    touched.email_username
                                      ? true
                                      : false
                                  }
                                />
                                <InputField
                                  placeholder="Enter Password"
                                  label="Password"
                                  type="password"
                                  name="password"
                                  value={values.password}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  errorMessage={
                                    errors.password &&
                                    touched.password &&
                                    errors.password
                                  }
                                  error={
                                    errors.password && touched.password
                                      ? true
                                      : false
                                  }
                                />
                                <div className="d-flex justify-content-end align-items-center">
                                  <Link
                                    to="/forgot-password"
                                    className="pointer-cursor"
                                  >
                                    Forgot password?
                                  </Link>
                                </div>

                                <div className="form-group form-row mt-3 mb-0 d-grid">
                                  <ButtonField
                                    buttonText="Login"
                                    disabled={isSubmitting}
                                  />
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default SignIn;
