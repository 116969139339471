import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../components/common/breadcrumb";

import UserForm from "./form";
import { useHistory, useParams } from "react-router-dom";
import { getUserById } from "../../services";
import show_Toast from "../../helpers/toast.helper";
import HelmetComponent from "../../components/common/Helmet";
import { PAGE_TITLES } from "../../constant/pageTitles";

const EditUser = () => {
  const history = useHistory();
  const { id } = useParams();

  const [initialValues, setIntialValues] = useState({
    name: "",
    username: "",
    email: "",
    password: "",
    userRole: "",
    status: "",
  });

  const getUserValues = async (id) => {
    try {
      const { data } = await getUserById(id);
      if (data.status === "Success") {
        const {
          name = "",
          username = "",
          email = "",
          role: { id } = {},
          status,
        } = data?.data;
        setIntialValues({
          name: name,
          username: username,
          email,
          userRole: id,
          status, // ACTIVE or INACTIVE
        });
      }
    } catch (error) {
      show_Toast({
        status: false,
        message:
          error?.response?.data?.message ||
          "Something went wrong please reload the page or contact with admin",
      });
    }
  };

  useEffect(() => {
    if (id) {
      getUserValues(id);
    }
  }, [id]);

  return (
    <Fragment>
      <HelmetComponent titleText={PAGE_TITLES["Users"]} />
      <Breadcrumb title="Edit User" parent="Users" parentlink="users" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card mt-2">
              <div className="card-header">
                <h5 className="text-capitalize">Edit User</h5>
              </div>
              <UserForm id={id} type="edit" initialValues={initialValues} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditUser;
