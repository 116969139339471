import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../components/common/breadcrumb";

import { Link } from "react-router-dom";
import {
  getCustomerReturnService,
  getProductService,
  getSupplierReturnService,
} from "../../services";
import show_Toast from "../../helpers/toast.helper";
import UnitForm from "../units/form";

import CustomLoader from "./../../components/common/customLoader/customLoader";
import PermissionBase from "../../components/common/permissionBase/permissionBase";
import { PAGE_TITLES } from "../../constant/pageTitles";
import HelmetComponent from "../../components/common/Helmet";
import ModalBox from "../../components/common/modal/modal";
import { PRODUCT_PRICE_UPDATE } from "../../constant/apiEndPoints";
import httpRequest from "../../axios/index";
import ReturnOrderListing from "./listings";

import { useParams } from "react-router-dom";

const ReturnOrders = () => {
  const { type } = useParams();
  const [returnsList, setReturnsList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(0);
  const [search, setSearch] = useState([]);
  const [price, setPrice] = useState("");
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
  const [productId, setProductId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const toggleModel = () => {
    setShowModal(!showModal);
  };

  const [filters, setFilters] = useState({
    orderId: "",
    paymentMethod: "",
    paymentReturn: "",
    startDate: "",
    endDate: "",
    profile: "",
  });
  const [apply, setApply] = useState(false);
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
    perPage: 10,
  });

  const onChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  const getUsersListing = async () => {
    try {
      setLoading(true);
      const {
        data: { data = [] },
      } = await (type === "customer"
        ? getCustomerReturnService
        : getSupplierReturnService)(
        paginationData.perPage,
        paginationData.currentPage,
        search,
        filters
      );

      setReturnsList(data?.data);
      setLoading(false);
      setPaginationData((pre) => ({
        ...pre,
        currentPage: data.current_page,
        totalRecords: data.total,
        perPage: data?.per_page,
        totalPages: Math.ceil(data.total / data?.per_page),
      }));
    } catch (error) {
      setLoading(false);
      show_Toast({
        status: false,
        message:
          error?.response?.data?.message ||
          "Something went wrong please reload the page or contact with admin",
      });
    }
  };

  useEffect(() => {
    getUsersListing();
  }, [paginationData.currentPage, search, apply, type]);

  useEffect(() => {
    setFilters({
      orderId: "",
      paymentMethod: "",
      paymentReturn: "",
      startDate: "",
      endDate: "",
      profile: "",
    });
  }, [type]);

  return (
    <Fragment>
      <HelmetComponent
        titleText={
          type === "supplier"
            ? PAGE_TITLES["ReturnSupplierOrders"]
            : PAGE_TITLES["ReturnCustomerOrders"]
        }
      />
      {isLoading ? (
        <CustomLoader />
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mt-2">
                <div className="card-header d-flex justify-content-between">
                  <h5 className="text-capitalize">
                    {type === "supplier"
                      ? "Supplier Return Orders"
                      : "Customer Return Orders"}
                  </h5>
                </div>
                <ReturnOrderListing
                  type={type}
                  returnsList={returnsList}
                  search={search}
                  setSearch={setSearch}
                  paginationData={paginationData}
                  setPaginationData={setPaginationData}
                  isLoading={isLoading}
                  filters={filters}
                  setFilters={setFilters}
                  setApply={setApply}
                  onChange={onChange}
                  setIsOpen={setIsOpen}
                  isOpen={isOpen}
                  toggle={toggle}
                  price={price}
                  setPrice={setPrice}
                  setShowModal={setShowModal}
                  showModal={showModal}
                  toggleModel={toggleModel}
                  setProductId={setProductId}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ReturnOrders;
