import React from "react";
import "./index.scss";

const ButtonField = ({
  type = "submit",
  buttonText = "button",
  disabled = false,
  className = "btn btn-primary",
  onClick,
}) => {
  return (
    <button
      type={type}
      disabled={disabled}
      className={className}
      onClick={onClick}
    >
      {buttonText}
    </button>
  );
};

export default ButtonField;
