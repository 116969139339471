import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { getAttributeValuesByKey } from "../../services";
import SelectField from "../../components/form/select";
import SearchSelectField from "../../components/form/select/SearchSelect";

const SubAttributeForm = ({
  index,
  product,
  setFieldValue,
  values,
  allAttributes,
}) => {
  const [subAttributes, setSubAttributes] = useState([]);

  const getAttributeValuesByKeyHandle = async (id) => {
    try {
      let { data } = await getAttributeValuesByKey(id, -1, 1);
      if (data.status === "Success") {
        let att = data?.data;

        // const attributeNames = values.attributes.map((attr) => attr.name);

        // const filteredSubAttributes = att.filter((subAttr) => {
        //   return !attributeNames.includes(String(subAttr.attribute_value_id));
        // });
        setSubAttributes(att);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (product.name) {
      getAttributeValuesByKeyHandle(product.name);
    }
  }, [product.name]);
  return (
    <FieldArray name={`attributes.${index}.units`}>
      {() => (
        <div>
          {/* <SelectField
            className={"form-control digits"}
            name={`attributes.${index}.attribute_value`}
            value={product.attribute_value}
            onChange={(event) => {
              setFieldValue(
                `attributes.${index}.attribute_value`,
                event.target.value
              );
            }}
            valueKey="attribute_value_id"
            labelKey={"value"}
            options={subAttributes}
          /> */}
          <SearchSelectField
            className={"inputclass rounded"}
            name={`attributes.${index}.attribute_value`}
            value={product.attribute_value}
            onChange={(event) => {
              if (event) {
                setFieldValue(
                  `attributes.${index}.attribute_value`,
                  event.value
                );
              }
            }}
            valueKey={"attribute_value_id"}
            labelKey={"value"}
            options={subAttributes}
          />
          <ErrorMessage
            name={`attributes.${index}.attribute_value`}
            component="div"
            className="error-message"
          />
        </div>
      )}
    </FieldArray>
  );
};

export default SubAttributeForm;
