import React, { Fragment, useEffect, useState, useRef } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import { getReportDetail } from "../../services";
import show_Toast from "../../helpers/toast.helper";
import CustomLoader from "../../components/common/customLoader/customLoader";
// import UnitForm from "./form";
import Search from "../../components/common/search/search";
import { Pagination } from "../../components/common/pagination";
import { PER_PAGE_API } from "../../constant/pagination";
import { ChevronDown, ChevronUp, Filter, Minus, Plus } from "react-feather";
import InputField from "../../components/form/input/input";
import "./index.scss";
import ButtonField from "./../../components/form/button/index";
import { Accordion, Button, Collapse } from "reactstrap";
import { Card } from "react-bootstrap";
import ReportFilter from "./reportFilter";
import CollapsLayout from "../../components/common/collapsLayout";
import HelmetComponent from "../../components/common/Helmet";
import { PAGE_TITLES } from "../../constant/pageTitles";

const DetailReport = () => {
  const [reportListing, setReportList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("SALE");
  const [toDate, setToDate] = useState(new Date());
  const [fromDate, setFromDate] = useState(new Date());
  const [productCheck, setProductCheck] = useState(true);
  const [expandedUser, setExpandedUser] = useState(
    Array.from({ length: reportListing.length }).fill(true)
  );
  const filtersAppliedRef = useRef(false);

  const [tableHeadings, setTableHeadings] = useState([]);
  const [apply, setApply] = useState(false);
  // const [show, setShow] = useState(false);

  const [filters, setFilters] = useState({
    start_date: new Date().toISOString().slice(0, 10),
    end_date: new Date().toISOString().slice(0, 10),
    opotion_mode: "SALE",
    product_breakdown: "NO",
  });

  const onChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  const [search, setSearch] = useState("");
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
    perPage: 10,
  });
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
  const [isOpen, setIsOpen] = useState(0);

  const getReportListing = async () => {
    try {
      setLoading(true);
      const { data } = await getReportDetail(
        paginationData.perPage,
        paginationData.currentPage,
        filters
      );
      if (data.status === "Success") {
        setReportList(data?.data?.data);
      }
      setPaginationData((pre) => ({
        ...pre,
        currentPage: data?.data?.current_page,
        totalRecords: data?.data?.total,
        perPage: data?.data?.per_page,
        totalPages: Math.ceil(data?.data?.total / data?.data?.per_page),
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      show_Toast({
        status: false,
        message:
          error?.response?.data?.message ||
          "Something went wrong please reload the page or contact with admin",
      });
    }
  };

  useEffect(() => {
    getReportListing();
  }, [paginationData.currentPage, search, apply]);
  useEffect(() => {
    setExpandedUser((prevExpandedUser) => {
      if (filters.product_breakdown === "YES") {
        return Array.from({ length: reportListing.length }).fill(true);
      } else {
        return Array.from({ length: reportListing.length }).fill(false);
      }
    });
  }, [reportListing]);

  const toggleTable = (userIndex) => {
    setExpandedUser((prevExpandedUser) => {
      const newExpandedUser = [...prevExpandedUser];
      newExpandedUser[userIndex] = !newExpandedUser[userIndex];
      return newExpandedUser;
    });
  };

  const ProductRender = ({ products, index }) => (
    <>
      {expandedUser[index] && products?.length && (
        <tr>
          {products ? (
            <td colSpan="6" className="inner-table-bg">
              <table className="inner-table table-responsive table">
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>Code</th>
                    <th>Cost</th>
                    <th>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {products?.map((product, i) => (
                    <tr key={i}>
                      <td>{product.name}</td>
                      <td>{product.code}</td>
                      <td>{product.cost}</td>
                      <td>{product.quantity}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </td>
          ) : (
            <></>
          )}
        </tr>
      )}
    </>
  );

  return (
    <Fragment>
      <HelmetComponent titleText={PAGE_TITLES["DetailReport"]} />
      <Breadcrumb parent="Reports" title="listing" parentlink="users" />

      {isLoading ? (
        <CustomLoader />
      ) : (
        <div className="container-fluid">
          <div className={`row`}>
            <div className="col-sm-12">
              <div className="card mt-2">
                <div className="card-header d-flex justify-content-between items-center">
                  <h5 className="text-capitalize">{"Detail Report"}</h5>
                </div>
                <div className="card-body">
                  <ReportFilter
                    filters={filters}
                    setFilters={setFilters}
                    onChange={onChange}
                    setApply={setApply}
                  />
                  <div className="table-responsive">
                    <table className="outer-table table">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Phone Number</th>
                          <th scope="col">Balance</th>
                          <th scope="col">
                            {selectedOption === "sale"
                              ? "Total Sale Amount"
                              : "Total Purchase Amount"}
                          </th>
                          {/* <th scope="col"></th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {reportListing?.map((user, index) => (
                          <>
                            <React.Fragment key={index}>
                              <tr>
                                <td>{user.name}</td>
                                <td>{user.email}</td>
                                <td>{user.mobile}</td>
                                <td>{user.balance}</td>
                                {user.total_purchase_amount ? (
                                  <td>{user.total_purchase_amount}</td>
                                ) : (
                                  <td>{user.total_sale_amount}</td>
                                )}
                                {user?.products?.length && (
                                  <td>
                                    <button
                                      className="btn-outline-dark"
                                      onClick={() => toggleTable(index)}
                                      style={{
                                        outline: "none",
                                        backgroundColor: "transparent",
                                        border: "1px solid gray",
                                        borderRadius: "2px",
                                      }}
                                      // disabled={
                                      //   filters?.product_breakdown === "NO"
                                      // }
                                    >
                                      <span
                                        className={`toggle-icon  ${
                                          expandedUser === index
                                            ? "minus-icon"
                                            : "plus-icon"
                                        }`}
                                      >
                                        {expandedUser[index] ? (
                                          <ChevronDown size={24} />
                                        ) : (
                                          <ChevronUp size={24} />
                                        )}
                                      </span>
                                    </button>
                                  </td>
                                )}
                                {/* ) : (
                                  <></>
                                )} */}
                              </tr>
                              {expandedUser[index] && (
                                <ProductRender
                                  products={user.products}
                                  index={index}
                                />
                              )}
                            </React.Fragment>
                          </>
                        ))}
                      </tbody>
                    </table>
                    <div className="mt-4 mb-1">
                      <Pagination
                        selectedPage={paginationData.currentPage}
                        pageCount={paginationData.totalPages}
                        onPageChangeHandler={(page) => {
                          setPaginationData({
                            ...paginationData,
                            currentPage: page,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default DetailReport;
