import React from "react";
import {
  DollarSign,
  MapPin,
  TrendingDown,
  ShoppingCart,
  Users,
  DivideCircle,
  Send,
  ChevronsDown,
} from "react-feather";
import Slider from "react-slick";
import CountUp from "react-countup";

import "./index.css";

const Tiles = ({
  stats: {
    totaProducts = 0,
    totalCustomers = 0,
    totalSuppliers = 0,
    totalUsers = 0,
  },
}) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 370,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="row">
      <div className="col-md-12 m-0 ecommerce-slider">
        <Slider {...settings}>
          <div className="single-card">
            <div className="ecommerce-icons text-center">
              {/* <ShoppingCart /> */}
              <div>
                <span>Total Products</span>
              </div>
              <h4 className="font-primary mb-0">
                <CountUp
                  className="counter"
                  end={totaProducts ? totaProducts : 0}
                />
              </h4>
            </div>
          </div>
          <div className="single-card">
            <div className="ecommerce-icons text-center">
              {/* <DollarSign /> */}
              <div>
                <span>Total Customers</span>
              </div>
              <h4 className="font-primary mb-0">
                <CountUp
                  className="counter"
                  end={totalCustomers ? totalCustomers : 0}
                />
              </h4>
            </div>
          </div>
          <div className="single-card">
            <div className="ecommerce-icons text-center">
              {/* <ChevronsDown /> */}
              <div>
                <span>Total Suppliers</span>
              </div>
              <h4 className="font-primary mb-0">
                <CountUp
                  className="counter"
                  end={totalSuppliers ? totalSuppliers : 0}
                />
              </h4>
            </div>
          </div>
          <div className="single-card">
            <div className="ecommerce-icons text-center">
              {/* <Users /> */}
              <div>
                <span>Total Users</span>
              </div>
              <h4 className="font-primary mb-0">
                <CountUp
                  className="counter"
                  end={totalUsers ? totalUsers : 0}
                />
              </h4>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default Tiles;
