export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const LOGIN_ENDPOINT = "/login";
export const FORGET_ENDPOINT = "/users/forgot/password";
export const RESET_ENDPOINT = "/users/reset/password";
export const ROLES = "/roles";
export const CREA_ROLE = "/roles/store";
export const UPDATE_ROLE = "/roles/update";
export const PERMISSIONS = "/permissions";
export const USERS = "/users";
export const STATS = "/stats";

export const UNITS = "/units";
export const CREATE_UNITS = "/units/store";
export const UPDATE_UNITS = "/units/update";

export const EXPENSES = "/expenses";
export const CREATE_EXPENSES = "/expenses/store";
export const UPDATE_EXPENSES = "/expenses/update";
export const EXPENSE_CATEGORIES = "/expense-categories";
export const CREATE_EXPENSE_CATEGORIES = "/expense-categories/store";
export const UPDATE_EXPENSE_CATEGORIES = "/expense-categories/update";

export const CONFIGRATIONS = "/configurations";
export const CREATE_CONFIGRATIONS = "/configurations/store";
export const UPDATE_CONFIGRATIONS = "/configurations/update";

export const UNIT_CATEGORIES = "unitCategories";
export const CREATE_UNIT_CATEGORIES = "unitCategories/store";

export const SINGLE_USERS = "/getUser";

export const GET_ALL_USERS = "/getAllUsers";

export const ATTRIBUTE_KEYS = "attributesKeys";
export const CREATE_ATTRIBUTE_KEYS = "attributesKeys/store";

export const ATTRIBUTE = "/attributes";
export const ATTRIBUTESKEYS = "/attributesKeys";

export const CREATE_PRODUCTS = "/products/store";
export const UPDATE_PRODUCTS = "products/update";

export const ATTRIBUTE_VALUES = "attributesValues";
export const CREATE_ATTRIBUTE_VALUES = "attributesValues/store";
export const UPDATE_ATTRIBUTE_VALUES = "attributesValues/update";

export const CREATE_USER = "createUser";
export const CREATE_ROLE = "createRole";

export const ATTRIBUTE_VALUES_BY_KEY = "attributeValuesBykey";
export const ATTRIBUTESVALUES = "attributesValues";

export const GET_ALL_PROFILES = "profiles";
export const CREATE_PROFILES = "profiles/store";
export const UPDATE_PROFILES = "profiles";

// new routes for profiles
export const GET_ALL_CUSTOMER_PROFILES = "customer-profiles";
export const GET_ALL_SUPPLIER_PROFILES = "supplier-profiles";

export const CREATE_CUSTOMER_PROFILES = "customer-profiles/store";
export const CREATE_SUPPLIER_PROFILES = "supplier-profiles/store";

export const UPDATE_CUSTOMER_PROFILES = "customer-profiles";
export const UPDATE_SUPPLIER_PROFILES = "supplier-profiles";

export const ORDERS = "orders";
export const CREATE_ORDERS = "orders/store";
export const CUSTOMER_CREATE_ORDERS = "customerOrders/store";
export const UPDATE_ORDERS = "orders/update";
export const CUSTOMER_UPDATE_ORDERS = "customerOrders/update/";
export const CUSTOMER_ORDERS = "customerOrders";

export const CUSTOMER_RETURN_ORDERS = "customerOrders/return-order/";
export const SUPPLIER_RETURN_ORDERS = "orders/return-order/";

export const All_UNITS = "/units";

// order add on
export const ORDER_ADDON = "/order-add-on";
export const CREATE_ORDER_ADD_ON = "/order-add-on/store";
export const UPDATE_ORDER_ADD_ON = "/order-add-on/update";

export const All_PRODUCTS = "/products";
export const CREATE_PRODUCT = "/products";
export const SINGLE_PRODUCT = "/products";
export const PRODUCT_PRICE_UPDATE = "/products/price/update";
export const STOCK_IN_OUT = "/products/in-out/stock";

export const CREATE_TRANSACTION = "transactions/store";
export const GET_TRANSACTION = "/transactions";
export const WALLET_TRANSACTIONS = "/wallet-transactions";

export const MARK_PAID = "mark-paid";
export const MARK_RECEIVED = "mark-received";

export const SUPPLIER_MAKE_PAYMENT = "/supplier/make-payment/store";
export const CUSTOMER_MAKE_PAYMENT = "/customer/make-payment/store";

export const All_RETURNS_SUPPLIER = "/return-orders/supplier";
export const All_RETURNS_CUSTOMER = "/return-orders/customer";
