import React, { Fragment, useEffect, useState, useRef } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import { Link, useLocation, useParams } from "react-router-dom";
import { getAttributeValuesByKey } from "../../services";
import show_Toast from "../../helpers/toast.helper";
import CustomLoader from "./../../components/common/customLoader/customLoader";
import AttributeForm from "./form";
import queryString from "query-string";
import { Edit } from "react-feather";
import { PER_PAGE_API } from "../../constant/pagination";
import { Pagination } from "../../components/common/pagination";
import "./index.scss";

const AttributeListing = () => {
  const [attributeList, setAttributeList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const { id } = useParams();
  const [transition, setTransition] = useState(false);
  const formRef = useRef(null);
  const [initialValues, setIntialValues] = useState({
    attr_type: "",
    attr_value: "",
  });
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
    perPage: 10,
  });
  const [editId, setEditid] = useState("");
  const [formType, setFormType] = useState("add");
  const [hideForm, setHideForm] = useState(null);

  useEffect(() => {
    if (id) {
      setIntialValues({
        ...initialValues,
        attr_type: id,
      });
    }
  }, [id]);

  useEffect(() => {
    setHideForm(id);
    if (hideForm) {
      setTransition(false);
    }
  }, [id]);

  const getAttributesListing = async (id) => {
    try {
      setLoading(true);
      const { data } = await getAttributeValuesByKey(
        id,
        paginationData.perPage,
        paginationData.currentPage
      );
      if (data.status === "Success") {
        setAttributeList(data?.data?.data || []);
      }
      setLoading(false);
      setPaginationData((pre) => ({
        ...pre,
        currentPage: data?.data?.current_page,
        totalRecords: data?.data?.total,
        perPage: data?.data?.per_page,
        totalPages: Math.ceil(data?.data?.total / data?.data?.per_page),
      }));
    } catch (error) {
      setLoading(false);
      show_Toast({
        status: false,
        message:
          error?.response?.data?.message ||
          "Something went wrong please reload the page or contact with admin",
      });
    }
  };

  const getKeyListing = useEffect(() => {
    getAttributesListing(id);
  }, [id, paginationData.currentPage]);

  const formSuccessHandler = () => {
    getAttributesListing(id);
    setTransition(false);
  };

  return (
    <Fragment>
      <Breadcrumb parent="Attribute" title="listing" />
      {isLoading ? (
        <CustomLoader />
      ) : attributeList?.length ? (
        <div className="container-fluid position-relative">
          <div className="row">
            <div className="col-sm-12">
              <div
                className={`card mt-2 section ${
                  transition ? "show-all-attributes" : "hide"
                }`}
                ref={formRef}
              >
                <AttributeForm
                  type={formType}
                  initialValues={initialValues}
                  formSuccessHandler={formSuccessHandler}
                  editId={editId}
                  setTransition={setTransition}
                  formRef={formRef}
                />
              </div>
            </div>
          </div>
          <div className={`row`}>
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header attributes-header">
                  <h5>All Attribute</h5>
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      setFormType("add");
                      setTransition(true);
                      setIntialValues({
                        attr_type: id,
                        attr_value: "",
                      });
                    }}
                  >
                    New Attribute Value
                  </button>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">{"Attribute Type"}</th>
                          <th scope="col">{"Attribute Value"}</th>
                          <th scope="col">{"Actions"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* will populate real time data  */}
                        {attributeList.map((attri, ind) => (
                          <tr key={ind}>
                            <td>{attri?.title}</td>
                            <td>{attri.value}</td>
                            <td>
                              <span
                                onClick={() => {
                                  setEditid(attri.attribute_value_id);
                                  setIntialValues({
                                    ...initialValues,
                                    attr_type: id,
                                    attr_value: attri.value,
                                  });
                                  setFormType("edit");
                                  setTransition(true);
                                  window.scrollTo({
                                    top: formRef.current.offsetTop,
                                    behavior: "smooth",
                                  });
                                }}
                                className="pointer-cursor"
                              >
                                <Edit size={20} color="green" />
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="mt-4 mb-1">
                      <Pagination
                        selectedPage={paginationData.currentPage}
                        pageCount={paginationData.totalPages}
                        onPageChangeHandler={(page) => {
                          setPaginationData({
                            ...paginationData,
                            currentPage: page,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>not found</div>
      )}
    </Fragment>
  );
};

export default AttributeListing;
