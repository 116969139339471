import React from "react";
import BarChart from "./BarChart";
import PieCharts from "./PieChart";
import "./index.css";

const Charts = ({ pieChartValues, barChartValues }) => {
  return (
    <div className="w-100 row mx-0 my-2">
      <div className="chart-container bord1 card shadow-none col-sm-12 col-lg-5 col-md-5">
        <div>
          <div className="card-header">
            <h5 className="text-capitalize font-weight-bold">
              Last 7 days stats
            </h5>
          </div>
          <PieCharts pieChartValues={pieChartValues} />
        </div>
      </div>
      <div className="chart-container bord2 card  shadow-none col-sm-12 col-lg-7 col-md-7">
        {/* d-flex justify-content-center align-items-center */}
        <div className="w-100">
          <div>
            <div className="card-header d-sm-none d-lg-block d-md-block">
              <h5 className="text-capitalize font-weight-bold">&nbsp;</h5>
            </div>
            <BarChart barChartValues={barChartValues} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Charts;
