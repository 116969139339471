import React, { Fragment, useState } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import ProfileForm from "./profileForm";
import { useParams } from "react-router-dom";
import HelmetComponent from "../../components/common/Helmet";
import { PAGE_TITLES } from "../../constant/pageTitles";

const AddSupplierProfile = () => {
  const { type } = useParams();
  const [initialValues, setIntialValues] = useState({
    name: "",
    mobile_number: "",
    account_number: "",
    email: "",
    address: "",
    profile_type: type,
  });
  const { id } = useParams();

  return (
    <Fragment>
      <HelmetComponent titleText={PAGE_TITLES["SupplierProfiles"]} />
      <Breadcrumb
        title="Add New Profile"
        parent="Profiles"
        parentlink="Profiles"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card mt-2">
              <div className="card-header">
                <h5 className="text-capitalize">{`${"Add Supplier"}`}</h5>
              </div>
              <ProfileForm
                initialValues={initialValues}
                id={id}
                type="add"
                profileTypes={type}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddSupplierProfile;
