import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import { getReportSummary } from "../../services";
import show_Toast from "../../helpers/toast.helper";
import CustomLoader from "../../components/common/customLoader/customLoader";
// import UnitForm from "./form";
import Search from "../../components/common/search/search";
import { Pagination } from "../../components/common/pagination";
import { PER_PAGE_API } from "../../constant/pagination";
import InputField from "../../components/form/input/input";
import BalanceSheet from "../../components/common/balanceSheet/balanceSheet";
import "./index.scss";
import ButtonField from "../../components/form/button";
import ReportFilter from "./reportFilter";
import { PAGE_TITLES } from "../../constant/pageTitles";
import HelmetComponent from "../../components/common/Helmet";

const SummaryReport = () => {
  const [reportListing, setReportList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
    perPage: 10,
  });
  const [apply, setApply] = useState(false);
  const [filters, setFilters] = useState({
    start_date: new Date().toISOString().split("T")[0],
    end_date: new Date().toISOString().split("T")[0],
  });
  const onChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  const currentDate = new Date().toISOString().split("T")[0];
  const [fromDate, setFromdate] = useState(currentDate);
  const [toDate, setTodate] = useState(currentDate);

  const getReportListing = async () => {
    try {
      setLoading(true);
      const { data } = await getReportSummary(
        paginationData.perPage,
        paginationData.currentPage,
        filters
      );

      if (data.status === "Success") {
        setReportList(data?.data);
      }
      setPaginationData((pre) => ({
        ...pre,
        currentPage: data?.data?.current_page,
        totalRecords: data?.data?.total,
        perPage: data?.data?.per_page,
        totalPages: Math.ceil(data?.data?.total / data?.data?.per_page),
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      show_Toast({
        status: false,
        message:
          error?.response?.data?.message ||
          "Something went wrong please reload the page or contact with admin",
      });
    }
  };

  useEffect(() => {
    getReportListing();
  }, [paginationData.currentPage, apply]);

  return (
    <Fragment>
      <HelmetComponent titleText={PAGE_TITLES["SummaryReport"]} />

      <Breadcrumb parent="Reports" title="listing" parentlink="users" />

      {isLoading ? (
        <CustomLoader />
      ) : (
        <div className="container-fluid">
          <div className={`row`}>
            <div className="col-sm-12">
              <div className="card mt-2">
                <div className="card-header d-flex justify-content-between items-center">
                  <h5 className="text-capitalize">{"Report Summary"}</h5>
                </div>
                <div className="card-body">
                  <div className="mb-3">
                    <ReportFilter
                      filters={filters}
                      setFilters={setFilters}
                      onChange={onChange}
                      setApply={setApply}
                      type="summry"
                    />
                  </div>
                  <BalanceSheet data={reportListing} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default SummaryReport;
