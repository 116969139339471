import { Form, Formik } from "formik";
import InputField from "../../components/form/input/input";
import ButtonField from "../../components/form/button";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import httpRequest from "../../axios";
import { CREATE_PROFILES, UPDATE_PROFILES } from "../../constant/apiEndPoints";
import show_Toast from "../../helpers/toast.helper";
import { UpdateProfileValidations } from "../../utils/validations/profile.validations";
import SelectField from "../../components/form/select";
import { profile_type } from "../../constant";
import CustomLoader from "../../components/common/customLoader/customLoader";
import { setUser } from "../../store/slices/login";
import { useDispatch } from "react-redux";
import { setProfile } from "../../store/slices/profile";

const OwnProfileForm = ({
  initialValues,
  type,
  id,
  profileTypes = "",
  setReload,
  setIntialValues,
}) => {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const updateProfile = async (values, setSubmitting) => {
    setLoading(true);
    try {
      var { data } = await httpRequest.post("/users/change/profile", values);
      //   console.log(data?.data);
      const { name, email, mobile, address } = data?.data;
      setIntialValues({
        name,
        email,
        mobile,
        address,
      });
      setSubmitting(false);
      setLoading(false);

      dispatch(setProfile(data?.data));
      show_Toast({
        status: true,
        message: data.status === "Success" && "Profile Updated successfully",
      });
    } catch (error) {
      setSubmitting(false);
      setLoading(false);
      show_Toast({
        status: false,
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };

  const handleReset = (resetForm) => {
    resetForm(initialValues);
  };

  return (
    <>
      {isLoading ? (
        <CustomLoader />
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            updateProfile(values, setSubmitting);
          }}
          validationSchema={UpdateProfileValidations}
          enableReinitialize
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              handleBlur,
              handleChange,
              resetForm,
              isSubmitting,
            } = props;
            return (
              <Form className="form theme-form">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <InputField
                        placeholder="Name"
                        label="Name"
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={52}
                        errorMessage={
                          errors.name && touched.name && errors.name
                        }
                        error={errors.name && touched.name ? true : false}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputField
                        placeholder="Email"
                        label="Email"
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={true}
                        errorMessage={
                          errors.email && touched.email && errors.email
                        }
                        error={errors.email && touched.email ? true : false}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputField
                        placeholder="Phone Number"
                        label="Phone Number"
                        type="text"
                        name="mobile"
                        value={values.mobile}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={
                          errors.mobile && touched.mobile && errors.mobile
                        }
                        error={
                          errors.mobile_number && touched.mobile_number
                            ? true
                            : false
                        }
                      />
                    </div>

                    <div className="col-md-6">
                      <InputField
                        placeholder="Address"
                        label="Address"
                        type="text"
                        name="address"
                        value={values.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={
                          errors.address && touched.address && errors.address
                        }
                        error={errors.address && touched.address ? true : false}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-footer d-flex justify-content-end gap-2">
                  <ButtonField
                    className={"btn btn-red"}
                    buttonText={"Reset"}
                    type="button"
                    onClick={() => handleReset(resetForm)}
                  />
                  <ButtonField
                    className={"btn btn-primary"}
                    buttonText={"Update"}
                    disabled={isSubmitting}
                    type="submit"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default OwnProfileForm;
