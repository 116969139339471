export const PERMISSION_NAMES = {
  create_user: "create_user",
  read_user: "read_user",
  update_user: "update_user",
  delete_user: "delete_user",

  read_role: "read_role",
  create_role: "create_role",
  update_role: "update_role",
  delete_role: "delete_role",

  create_attribute_values: "create_attribute_values",
  read_attribute_values: "read_attribute_values",
  update_attribute_values: "update_attribute_values",
  delete_attribute_values: "delete_attribute_values",

  create_attribute_keys: "create_attribute_keys",
  read_attribute_keys: "read_attribute_keys",
  update_attribute_keys: "update_attribute_keys",
  delete_attribute_keys: "delete_attribute_keys",

  // Add On permission
  create_order_add_on: "create_order_add_on",
  read_order_add_on: "read_order_add_on",
  update_order_add_on: "update_order_add_on",
  delete_order_add_on: "delete_order_add_on",
  // Add On permission

  //Transaction permission

  read_transaction: "read_transaction",

  create_units: "create_units",
  read_units: "read_units",
  update_units: "update_units",
  delete_units: "delete_units",

  create_unit_categories: "create_unit_categories",
  read_unit_categories: "read_unit_categories",
  update_unit_categories: "update_unit_categories",
  delete_unit_categories: "delete_unit_categories",

  create_products: "create_products",
  read_products: "read_products",
  update_products: "update_products",
  delete_products: "delete_products",

  // create_profile: "create_profile",
  // read_profile: "read_profile",
  // update_profile: "update_profile",
  // delete_profile: "delete_profile",

  create_customer_profile: "read_customer_profile",
  read_customer_profile: "read_customer_profile",
  update_customer_profile: "read_customer_profile",
  delete_customer_profile: "read_customer_profile",

  create_supplier_profile: "read_supplier_profile",
  read_supplier_profile: "read_supplier_profile",
  update_supplier_profile: "read_supplier_profile",
  delete_supplier_profile: "read_supplier_profile",

  create_transaction: "create_transaction",
  read_transaction: "read_transaction",
  delete_transaction: "delete_transaction",
  update_transaction: "update_transaction",

  //

  create_return_order_by_customer: "create_return_order_by_customer",
  create_return_order_by_supplier: "create_return_order_by_supplier",

  read_return_orders_by_supplier: "read_return_orders_by_supplier",
  read_return_orders_by_customer: "read_return_orders_by_customer",

  create_supplier_orders: "create_supplier_orders",
  read_supplier_orders: "read_supplier_orders",
  update_supplier_orders: "update_supplier_orders",
  delete_supplier_orders: "delete_supplier_orders",

  create_customer_orders: "create_customer_orders",
  read_customer_orders: "read_customer_orders",
  update_customer_orders: "update_customer_orders",
  delete_customer_orders: "delete_customer_orders",

  create_expense: "create_expense",
  read_expense: "read_expense",
  update_expense: "update_expense",
  delete_expense: "delete_expense",

  create_expense_categories: "create_expense_categories",
  read_expense_categories: "read_expense_categories",
  update_expense_categories: "update_expense_categories",
  delete_expense_categories: "delete_expense_categories",

  // cru
  create_configuration: "create_configuration",
  read_configuration: "read_configuration",
  update_configuration: "update_configuration",

  // cr
  create_expense_report: "create_expense_report",
  read_expense_report: "read_expense_report",

  create_product_detail_report: "create_product_detail_report",
  read_product_detail_report: "read_product_detail_report",

  create_product_summary_report: "create_product_summary_report",
  read_product_summary_report: "read_product_in_out_stock_report",

  create_product_in_out_stock_report: "create_product_in_out_stock_report",
  read_product_in_out_stock_report: "read_product_in_out_stock_report", //same

  update_own_profile_password: "update_own_profile_password",
  update_own_profile: "update_own_profile",

  read_wallet_transactions: "read_wallet_transactions",
};
