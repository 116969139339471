import React, { useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const MultiSelect = ({
  options,
  value,
  selectedOptions,
  handleChangeOptions,
  isMulti,
  defaultOption,
}) => {
  const animatedComponents = makeAnimated();

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: "#2f3c4e",
      borderRadius: "4px",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      fontSize: "14px",
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "8px 12px",
      backgroundColor: "#2f3c4e",
      color: "#ced4da",
      "&:hover": {
        backgroundColor: "#4E6E81",
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#333",
      color: "#fff",
    }),
    control: (provided, state) => ({
      ...provided,
      minWidth: "150px",
      backgroundColor: state.isSelected ? "red" : "white",
      color: state.isSelected ? "white" : "black",
      backgroundColor: "transparent",
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      backgroundColor: "#ced4da",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "gray",
      "&:hover": {
        color: "lightgray",
      },
    }),
  };

  return (
    <Select
      closeMenuOnSelect={true}
      components={animatedComponents}
      isMulti={isMulti}
      options={options}
      value={value}
      onChange={(e) => handleChangeOptions(e)}
      styles={customStyles}
      defaultOption={defaultOption}
    />
  );
};

export default MultiSelect;
