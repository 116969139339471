import React, { Suspense, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import AppRoutes from "./routes";
import httpRequest from "./axios";
import { CONFIGRATIONS } from "./constant/apiEndPoints";
import { useDispatch } from "react-redux";
import { setConfigurations } from "./store/slices/configurations";
import { findConfigurationByKeyPublic } from "./services";

const App = () => {
  const dispatch = useDispatch();
  const [shortName, setShortName] = useState("");
  const [logo, setLogo] = useState("");
  useEffect(() => {
    async function fetchData() {
      try {
        const companyNameResponse = await findConfigurationByKeyPublic(
          "COMPANY_SHORT_NAME"
        );
        const mainLogoResponse = await findConfigurationByKeyPublic(
          "APP_MAIN_PAGE_LOGO"
        );
        const appLogoResponse = await findConfigurationByKeyPublic("APP_LOGO");
        const companyMainNameResponse = await findConfigurationByKeyPublic(
          "COMPANY_NAME"
        );
        dispatch(
          setConfigurations({
            companyShortName: companyNameResponse?.data?.data?.value || "",
            mainLogo: mainLogoResponse?.data?.data?.value || "",
            companyName: companyMainNameResponse?.data?.data?.value || "",
            appLogo: appLogoResponse?.data?.data?.value || "",
          })
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="App">
      <Suspense fallback={<div>Loading</div>}>
        <AppRoutes />
      </Suspense>
    </div>
  );
};

export default App;
