import { Form, Formik } from "formik";
import InputField from "../../components/form/input/input";
import ButtonField from "../../components/form/button";
import CheckBoxField from "../../components/form/checkbox/checkbox";
import { useState } from "react";
import httpRequest from "../../axios";
import {
  CREATE_ORDER_ADD_ON,
  UPDATE_ORDER_ADD_ON,
} from "../../constant/apiEndPoints";
import { useSelector } from "react-redux";
import show_Toast from "../../helpers/toast.helper";
import { AddonValidations } from "../../utils/validations/orderaddon.validations";
import { hasPermissions } from "../../helpers/permissions.helper";
import { PERMISSION_NAMES } from "../../constant/routePermissions";
import { Redirect } from "react-router-dom";
import SearchSelectField from "../../components/form/select/SearchSelect";

const AddonForm = ({
  initialValues = {
    id: "",
    title: "",
    is_shop_income: "",
  },
  type = "add",
  setTransition,
  formSuccessHandler = () => {},
  editId = "",
  setApply,
  setIntialValues,
}) => {
  const { permissions } = useSelector((state) => state.login);

  const createAddon = async (values, setSubmitting) => {
    try {
      if (type === "add") {
        var { data } = await httpRequest.post(CREATE_ORDER_ADD_ON, values);
      } else {
        var { data } = await httpRequest.put(
          UPDATE_ORDER_ADD_ON + "/" + editId,
          values
        );
      }

      setSubmitting(false);
      setTransition(false);
      show_Toast({
        status: true,
        message:
          data.status === "Success" && type === "edit"
            ? "Add-on Updated successfully"
            : "Add-on created successfully",
      });
      setApply((pre) => !pre);
    } catch (error) {
      setSubmitting(false);
      show_Toast({
        status: false,
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };

  const [checked, setChecked] = useState(true);
  const isClickedChecked = () => {
    if (checked) {
      setChecked(false);
    } else {
      setChecked(true);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        if (permissions.length) {
          if (
            !hasPermissions(
              permissions,
              PERMISSION_NAMES["create_order_add_on"]
            )
          ) {
            return <Redirect to={{ pathname: "/not-allowed" }} />;
          }
        }
        createAddon(values, setSubmitting);
      }}
      validationSchema={AddonValidations}
      enableReinitialize
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          resetForm,
          isSubmitting,
          setFieldValue,
        } = props;
        return (
          <>
            <Form className={`form theme-form`}>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-4">
                    <InputField
                      placeholder="Title"
                      label="Title"
                      type="text"
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorMessage={
                        errors.title && touched.title && errors.title
                      }
                      error={errors.title && touched.title ? true : false}
                    />
                  </div>
                  <div className="col-sm-2">
                    {/* <CheckBoxField
                      title="Is shop Income"
                      checkId=""
                      checkfor=""
                      defaultChecked={checked}
                      value={
                        checked
                          ? (values.is_shop_income = "YES")
                          : (values.is_shop_income = "NO")
                      }
                      onClick={isClickedChecked}
                    /> */}

                    <label className="text-capitalize">Shop Income</label>
                    <SearchSelectField
                      className={"inputclass rounded flex-grow-1"}
                      name="shopIncome"
                      value={values?.is_shop_income}
                      valueKey="value"
                      labelKey="label"
                      onChange={(e) => {
                        setFieldValue("is_shop_income", e.value);
                        // setInitia;
                      }}
                      options={[
                        { label: "Yes", value: "YES" },
                        { label: "No", value: "NO" },
                      ]}
                    />
                  </div>
                  <div className="col-sm-4 d-flex align-items-center justify-content-end justify-content-sm-start gap-2">
                    <ButtonField
                      type="button"
                      className="btn btn-red"
                      onClick={() => {
                        setTransition(false);
                        resetForm();
                      }}
                      buttonText="Cancel"
                    />
                    <ButtonField
                      type="Submit"
                      className={"btn btn-primary me-2"}
                      buttonText={type === "edit" ? "Update" : "Create"}
                      disabled={isSubmitting}
                    />
                  </div>
                </div>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default AddonForm;
