import React, { memo, useEffect, useState } from "react";
import "./index.scss";

import ReactPaginate from "react-paginate";

import show_Toast from "../../../helpers/toast.helper";

export const Pagination = memo(
  ({
    pageRangeDisplay = 2,
    pageCount,
    onPageChangeHandler,
    selectedPage = 1,
  }) => {
    const [page, setPage] = useState("");

    return (
      <div className="pagination-width d-flex justify-content-between align-items-center">
        <ReactPaginate
          nextLabel=">"
          onPageChange={({ selected }) => {
            onPageChangeHandler(selected + 1);
          }}
          pageRangeDisplayed={pageRangeDisplay}
          pageCount={pageCount}
          previousLabel="<"
          pageClassName="page-item pagination-item"
          pageLinkClassName="page-link pagination-link mx-1"
          previousClassName="page-item mx-1"
          previousLinkClassName="page-link pagination-controls"
          nextClassName="page-item"
          nextLinkClassName="page-link pagination-controls mx-1"
          breakLabel="..."
          breakClassName="page-item pagination-item"
          breakLinkClassName="page-link pagination-link"
          containerClassName="pagination"
          activeClassName="pagination-active"
          renderOnZeroPageCount={null}
          forcePage={selectedPage - 1}
        />
        {pageCount > 1 ? (
          <div class="goto-page d-flex align-items-center">
            <form className="d-flex align-items-center justify-content-end gap-2">
              <label className="pt-2" for="pageNumber" style={{ width: "120px" }}>
                Go to Page&nbsp;
              </label>
              <input
                type="number"
                className="goto-page-input px-1 form-control paginationInput"
                name="pageNumber"
                id="pageNumber"
                min="1"
                max={pageCount}
                onChange={(e) => {
                  const enteredValue = parseInt(e.target.value);
                  if (enteredValue >= 1 && enteredValue <= pageCount) {
                    setPage(enteredValue);
                  } else {
                    setPage("");
                  }
                }}
              />
              <button
                className="btn btn-custom-pagination next-page-button-spacings"
                disabled={!page || page <= 0 || page > pageCount}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onPageChangeHandler(page);
                }}
              >
                Go&nbsp;&gt;
              </button>
            </form>
          </div>
        ) : null}
      </div>
    );
  }
);
