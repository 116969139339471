import React, { useState, useEffect } from 'react'
import html2pdf from 'html2pdf.js';
import { useParams } from 'react-router-dom';

import { useLocation } from 'react-router-dom';

const PdfViewer = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const pdf = params.get('pdf');

    const [pdfBlob, setPdfBlob] = useState(null);

    const generatePdf = (html) => {
        const opt = {
            pagebreak: { mode: 'avoid-all' },
            margin: [20, 20, 20, 20],
            filename: 'document.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { dpi: 192, letterRendering: true },
            jsPDF: { unit: 'pt', format: 'letter', orientation: 'portrait' },
            imageTimeout: 30000,
            onBeforeRenderHTML: function (pdf, htmlElement, options) {
                const imageElements = htmlElement.querySelectorAll('img');
                const imgPromises = [];
                imageElements.forEach((img) => {
                    const imgPromise = new Promise((resolve) => {
                        img.onload = () => {
                            resolve();
                        };
                    });
                    imgPromises.push(imgPromise);
                });

                return Promise.all(imgPromises).then(() => {
                    return htmlElement;
                });
            },
        };
        html2pdf()
            .set(opt)
            .from(html)
            .output('blob')
            .then((pdf) => {
                setPdfBlob(pdf);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    useEffect(() => {
        generatePdf(pdf);
    }, [])

    return (
        <>
            {pdfBlob && (
                <iframe
                    src={URL.createObjectURL(pdfBlob)}
                    width="100%"
                    style={{ width: '100%', height: '100vh', border: 'none' }}
                    title="Bill Pdf" />
            )}
        </>
    )
}

export default PdfViewer;