import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  profile: [],
};

const profileReducer = createSlice({
  name: "profile",
  initialState: initialState,
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    setProfileEmpty: (state, action) => {
      state.profile = [];
    },
  },
});

export const { setProfile, setProfileEmpty } = profileReducer.actions;
export default profileReducer.reducer;
