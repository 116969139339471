import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../components/common/breadcrumb";

import { useHistory, useParams } from "react-router-dom";
import { getSingleUnitCategoryService } from "../../services";
import show_Toast from "../../helpers/toast.helper";
import ExpenseForm from "./form";

const EditExpenseCategory = () => {
  const history = useHistory();
  const { id } = useParams();

  const [initialValues, setIntialValues] = useState({
    title: "",
  });
  const [isLoading, setLoading] = useState(false);

  const getUnitValues = async (id) => {
    try {
      const { data } = await getSingleUnitCategoryService(id);
      if (data.status === "Success") {
        const { title = "" } = data?.data;
        setIntialValues({
          title,
        });
      }
    } catch (error) {
      show_Toast({
        status: false,
        message:
          error?.response?.data?.message ||
          "Something went wrong please reload the page or contact with admin",
      });
    }
  };

  useEffect(() => {
    if (id) {
      getUnitValues(id);
    } else {
      history.push("/users");
    }
  }, [id]);

  return (
    <Fragment>
      <Breadcrumb title="Edit Units" parent="Users" parentlink="units" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Edit Units</h5>
              </div>
              {isLoading && <div>Loading...</div>}
              <ExpenseForm id={id} type="edit" initialValues={initialValues} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditExpenseCategory;
