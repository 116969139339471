import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  exportToExcel,
  getPaymentHistoryService,
  generatePdfFromTable,
  generatePdfFromApi,
} from "../../services";
import show_Toast from "../../helpers/toast.helper";
import CustomLoader from "../../components/common/customLoader/customLoader";
import { Pagination } from "../../components/common/pagination";
import Search from "../../components/common/search/search";
import { PER_PAGE_API } from "../../constant/pagination";
import ButtonField from "./../../components/form/button/index";
import HelmetComponent from "../../components/common/Helmet";
import { PAGE_TITLES } from "../../constant/pageTitles";

const PaymentHistory = () => {
  const [paymentList, setPaymentList] = useState([]);
  const { id } = useParams();
  const history = useHistory();

  const [isLoading, setLoading] = useState(false);
  const [search, setSearch] = useState([]);

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
    perPage: 10,
  });

  const getUsersListing = async () => {
    try {
      setLoading(true);
      const {
        data: { data = [] },
      } = await getPaymentHistoryService(
        paginationData.perPage,
        paginationData.currentPage,
        search,
        id
      );
      setPaymentList(data?.data);
      setPaginationData((pre) => ({
        ...pre,
        currentPage: data.current_page,
        totalRecords: data.total,
        perPage: data.per_page,
        totalPages: Math.ceil(data.total / data.per_page),
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      show_Toast({
        status: false,
        message:
          error?.response?.data?.message ||
          "Something went wrong please reload the page or contact with admin",
      });
    }
  };

  const exportToExcelFile = async () => {
    const res = await getPaymentHistoryService(-1, 1, "", id);
    const data = res?.data?.data?.data?.map((user) => ({
      Id: user?.id,
      Name: user?.profile[0]?.data?.name,
      Amount: user?.amount,
      "Payment Method": user?.transaction_type?.payment_method,
      "Deposit Date": user?.deposit_date,
    }));
    const headers = ["Id", "Name", "Amount", "Payment Method", "Deposit Date"];
    exportToExcel(data, headers, "PaymentHistory");
  };

  // const exportToPdfFile = () => {
  //   generatePdfFromTable("paymentHistoryTable", (doc) => {
  //     doc.save("PaymentHistoryTable.pdf");
  //   });
  // };

  const exportToPdfFile = () => {
    generatePdfFromApi(id, (doc) => {
      doc.save("PaymentHistoryTable.pdf");
    });
  };

  const goBack = () => {
    history.goBack();
  };

  useEffect(() => {
    getUsersListing();
  }, [paginationData.currentPage, search]);

  return (
    <Fragment>
      <HelmetComponent titleText={PAGE_TITLES["Payments"]} />
      <Breadcrumb parent="Users" title="listing" parentlink="users" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card mt-2">
              <div className="card-header d-flex justify-content-between items-center">
                <div>
                  <ButtonField
                    to={"/profile"}
                    className="btn btn-primary"
                    buttonText="Back"
                    onClick={goBack}
                  />
                </div>
                <div className="d-flex gap-2">
                  <ButtonField
                    className="btn btn-success mr-2"
                    buttonText="Download Excel"
                    onClick={exportToExcelFile}
                  />
                  <ButtonField
                    className="btn btn-success"
                    buttonText="Download Pdf"
                    onClick={exportToPdfFile}
                  />
                </div>
              </div>
              <div className="card-body">
                <div className="mb-3 d-flex justify-content-end">
                  <Search
                    classes="form-control search-input"
                    placeholder="Search"
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setPaginationData((pre) => ({
                        ...pre,
                        currentPage: 1,
                      }));
                    }}
                    value={search}
                  />
                </div>
                {isLoading ? <CustomLoader /> : null}
                <div className="table-responsive">
                  <table className="table" id="paymentHistoryTable">
                    <thead>
                      <tr>
                        <th scope="col">Id</th>
                        <th scope="col">Name</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Payment Method</th>
                        <th scope="col">Deposit Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentList.length
                        ? paymentList.map((user, ind) => (
                            <tr key={ind}>
                              <td scope="row">{user?.id}</td>
                              <td>{user?.profile[0]?.data?.name}</td>
                              <td>{user?.amount}</td>
                              <td>{user?.transaction_type?.payment_method}</td>
                              <td>{user?.deposit_date}</td>
                            </tr>
                          ))
                        : "No data found"}
                    </tbody>
                  </table>
                  <div className="mt-4 mb-1">
                    <Pagination
                      selectedPage={paginationData.currentPage}
                      pageCount={paginationData.totalPages}
                      onPageChangeHandler={(page) => {
                        setPaginationData({
                          ...paginationData,
                          currentPage: page,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PaymentHistory;
