import React, { useEffect } from "react";
import { calculateGST } from "../../../services";

const OrderAmount = ({
  products,
  discount,
  setFieldValue,
  total,
  setTotal,
  addons, // New prop containing an array of objects with "amount" key
}) => {
  let totalPriceSingleProduct = products.reduce(
    (sum, item) =>
      sum +
      parseFloat(
        item.totalPrice -
          parseFloat(item.discount || 0) +
          calculateGST(
            item.totalPrice - parseFloat(item.discount || 0),
            item.productGst
          ) || 0
      ),
    0
  );

  let totalAmountFromAddons = addons.reduce(
    (sum, addon) => sum + parseFloat(addon.amount || 0),
    0
  );

  useEffect(() => {
    const calculateOrderAmount = () => {
      // Calculate the total amount including both products and addons
      const totalAmount = Number(
        (totalPriceSingleProduct + totalAmountFromAddons).toFixed(2)
      );
      setTotal(totalAmount);
    };

    calculateOrderAmount();
  }, [products, setFieldValue, discount, addons]);

  return <h3>Total: {total}</h3>;
};

export default OrderAmount;
