import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import { useParams } from "react-router-dom";
import OrderForm from "./orderForm";
import { PAGE_TITLES } from "../../constant/pageTitles";
import HelmetComponent from "../../components/common/Helmet";

const AddOrder = () => {
  const { type } = useParams();
  const { id } = useParams();
  const [initialValues, setIntialValues] = useState({
    products: [],
    addOns: [],
    profile_id: "",
    payment_method: "CASH",
    is_paid: "YES",
    is_received: "YES",
    scheduled_delivery_date: "",
    paid_amount: "",
    description: "",
    tag: "",
    adjust_from_wallet: "YES",
    wallet_balance: "",
  });

  return (
    <Fragment>
      <HelmetComponent
        titleText={
          type === "customer"
            ? PAGE_TITLES["CustomerOrders"]
            : PAGE_TITLES["SupplierOrders"]
        }
      />
      <Breadcrumb title="New Order" parent="Orders" parentlink="Orders" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card mt-2 position-relative">
              <div className="card-header">
                <h5 className="text-capitalize">{`${
                  type === "customer"
                    ? "Add Customer Order"
                    : "Add Supplier Order"
                }`}</h5>
              </div>
              <OrderForm
                initialValues={initialValues}
                id={id}
                type="add"
                orderType={type}
                user={type}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddOrder;
