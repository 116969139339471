import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import { useParams, useLocation } from "react-router-dom";
import OrderForm from "./orderForm";
import httpRequest from "../../axios";
import HelmetComponent from "../../components/common/Helmet";
import { PAGE_TITLES } from "../../constant/pageTitles";

const EditOrder = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const order = searchParams.get("order");
  const { type } = useParams();
  const [preSelctedProducts, setPreSelectedProducts] = useState([]);

  const [initialValues, setIntialValues] = useState({
    products: [
      {
        name: "",
        price: 0,
        units: [
          {
            id: "",
            value: "",
            name: "",
          },
        ],
        discount: 0,
        productcode: "",
        productGst: "",
        totalPrice: "",
        baseUnit: "",
        note: "",
      },
    ],
    addOns: [{ id: "", name: "", amount: "" }],
    supplier_id: "",
    payment_method: "",
    is_paid: "",
    is_received: "",
    scheduled_delivery_date: "",
    paid_amount: "",
    tag: "",
    adjust_from_wallet: "YES",
    wallet_balance: "",
  });

  const { id } = useParams();
  var allProductId = [];

  const getOrderValues = async (id) => {
    try {
      const { data } = await httpRequest.get(
        type === "customer" ? "customerOrders/" + id : "orders/" + id
      );
      if (data.status === "Success") {
        let order = data?.data || null;
        if (order && order?.data?.length) {
          let products = order?.data?.map((item) => {
            let units;
            if (item.requested_stock.length) {
              units = item.requested_stock.map((stocks) => ({
                id: stocks.unit_id,
                value: stocks.stock || 0,
                name: stocks.unit_name,
              }));
            }
            allProductId.push(parseInt(item.product_id));
            return {
              name: item.product_id,
              price: item.product_cost,
              units: units,
              discount: item?.discount,
              productcode: item?.product_id,
              productGst: item?.gst,
              totalPrice: parseFloat(item?.amount_without_gst),
              sellingUnitName: item?.selling_unit_title,
              label: item?.product_name,
              note: item?.note,
            };
          });
          setPreSelectedProducts(allProductId);
          // remove extra data to payload!
          delete order.data;
          delete initialValues.supplier_id;
          setIntialValues({
            ...initialValues,
            ...order,
            products: products,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOrderValues(id);
  }, [id]);

  return (
    <Fragment>
      <HelmetComponent
        titleText={
          type === "customer"
            ? PAGE_TITLES["CustomerOrders"]
            : PAGE_TITLES["SupplierOrders"]
        }
      />
      <Breadcrumb title="Edit Order" parent="Orders" parentlink="Orders" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card mt-2">
              <div className="card-header">
                <h5 className="text-capitalize">Edit Order</h5>
              </div>
              <OrderForm
                initialValues={initialValues}
                id={id}
                type="edit"
                preSelctedProducts={preSelctedProducts}
                orderType={type}
                order={order}
                user={type}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditOrder;
