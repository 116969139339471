import React, { useState, useEffect } from "react";
import { Chart } from "react-google-charts";

const PieCharts = ({
  pieChartValues: {
    pieChartExpenses = 0,
    pieChartPurchase = 0,
    pieChartSales = 0,
  },
}) => {
  const [data, setDate] = useState([]);

  const options = {
    // chartArea: { height: "60%", width: "60%" },
  };

  useEffect(() => {
    setDate([
      ["Task", "Hours per Day"],
      ["Sales", pieChartSales],
      ["Purchases", pieChartPurchase],
      ["Expenses", pieChartExpenses],
    ]);
  }, [pieChartExpenses, pieChartPurchase, pieChartSales]);

  return <Chart chartType="PieChart" data={data} options={options} />;
};

export default PieCharts;
