import { useSelector } from "react-redux";

import { useState, useEffect } from "react";
import {
  Home,
  Users,
  User,
  Sliders,
  Grid,
  Lock,
  Key,
  Bag,
  ShoppingCart,
  ShoppingBag,
  CreditCard,
  Briefcase,
  Settings,
  DollarSign,
} from "react-feather";
import { hasPermissions } from "../helpers/permissions.helper";

const allMenus = [
  {
    title: "Dashboard",
    icon: Home,
    type: "link",
    path: "/",
    givenPermission: [
      "create_customer_orders",
      "read_customer_orders",
      "update_customer_orders",
      "delete_customer_orders",
    ],
  },
  {
    title: "Customer Orders",
    icon: Home,
    type: "link",
    path: "/orders/customer",
    givenPermission: [
      "create_customer_orders",
      "read_customer_orders",
      "update_customer_orders",
      "delete_customer_orders",
    ],
  },

  {
    title: "Supplier Orders",
    icon: ShoppingBag,
    type: "link",
    path: "/orders/supplier",
    givenPermission: [
      "create_supplier_orders",
      "read_supplier_orders",
      "update_supplier_orders",
      "delete_supplier_orders",
    ],
  },

  {
    title: "Customer Return Orders",
    icon: ShoppingBag,
    type: "link",
    path: "/return-orders/customer",
    givenPermission: ["read_return_orders_by_customer"],
  },
  {
    title: "Supplier Return Orders",
    icon: ShoppingBag,
    type: "link",
    path: "/return-orders/supplier",
    givenPermission: ["read_return_orders_by_supplier"],
  },
  {
    title: "Order Add-On",
    icon: ShoppingBag,
    type: "link",
    path: "/orderaddon",
    givenPermission: [
      "create_order_add_on",
      "read_order_add_on",
      "update_order_add_on",
      "delete_order_add_on",
    ],
  },
  {
    title: "Products",
    icon: ShoppingCart,
    type: "link",
    path: "/products",
    givenPermission: [
      "create_products",
      "read_products",
      "update_products",
      "delete_products",
    ],
  },
  {
    title: "Customers",
    icon: Briefcase,
    type: "link",
    path: "/profiles/customer",
    givenPermission: [
      "create_customer_profile",
      "read_customer_profile",
      "update_customer_profile",
      "delete_customer_profile",
    ],
  },
  {
    title: "Suppliers",
    icon: User,
    type: "link",
    path: "/profiles/supplier",
    givenPermission: [
      "create_supplier_profile",
      "read_supplier_profile",
      "update_supplier_profile",
      "delete_supplier_profile",
    ],
  },
  {
    title: "Attributes",
    icon: Sliders,
    type: "sub",
    badgeType: "primary",
    active: false,
    children: [
      {
        path: "/attributes",
        title: "Attributes",
        type: "link",
        givenPermission: [
          "create_attribute_values",
          "read_attribute_values",
          "update_attribute_values",
          "delete_attribute_values",
        ],
      },
      {
        path: "/attributes/keys",
        title: "Attributes Key",
        type: "link",
        givenPermission: [
          "create_attribute_keys",
          "read_attribute_keys",
          "update_attribute_keys",
          "delete_attribute_keys",
        ],
      },
    ],
  },
  {
    title: "Units",
    icon: Grid,
    type: "sub",
    badgeType: "primary",
    active: false,
    children: [
      {
        path: "/units",
        title: "Unit ",
        type: "link",
        givenPermission: [
          "create_units",
          "read_units",
          "update_units",
          "delete_units",
        ],
      },
      {
        path: "/unit-categories",
        title: "Unit Categories",
        type: "link",
        givenPermission: [
          "create_unit_categories",
          "read_unit_categories",
          "update_unit_categories",
          "delete_unit_categories",
        ],
      },
    ],
  },
  {
    title: "Expenses",
    icon: Grid,
    type: "sub",
    badgeType: "primary",
    active: false,
    children: [
      {
        path: "/expense",
        title: "Expense ",
        type: "link",
        givenPermission: [
          "create_expense",
          "read_expense",
          "update_expense",
          "delete_expense",
        ],
      },
      {
        path: "/expense-categories",
        title: "Expense Categories",
        type: "link",
        givenPermission: [
          "create_expense_categories",
          "read_expense_categories",
          "update_expense_categories",
          "delete_expense_categories",
        ],
      },
    ],
  },
  {
    title: "Transactions",
    icon: DollarSign,
    type: "link",
    path: "/transaction",
    active: false,
    givenPermission: ["read_transaction"],
  },
  {
    title: "Wallet Transactions",
    icon: DollarSign,
    type: "link",
    path: "/wallet-transaction",
    active: false,
    givenPermission: ["read_transaction"],
  },
  {
    title: "In Out Stocks",
    icon: DollarSign,
    type: "link",
    path: "/in-out-stock",
    active: false,
    givenPermission: ["read_transaction"],
  },
  {
    title: "Users",
    icon: Users,
    type: "link",
    path: "/users",
    active: true,
    givenPermission: ["create_user", "read_user", "update_user", "delete_user"],
  },
  {
    title: "Reports",
    icon: Grid,
    type: "sub",
    badgeType: "primary",
    active: false,
    children: [
      {
        path: "/summary/report",
        title: "Summary Report ",
        type: "link",
        givenPermission: [
          "read_product_detail_report",
          "create_product_detail_report",
        ],
      },
      {
        path: "/detail/report",
        title: "Detail Report",
        type: "link",
        givenPermission: [
          "create_product_summary_report",
          "read_product_in_out_stock_report",
        ],
      },
    ],
  },
  {
    title: "Roles & Permissions",
    icon: Key,
    type: "link",
    path: "/roles",
    givenPermission: ["read_role", "create_role", "update_role", "delete_role"],
  },
  {
    title: "Configurations",
    icon: Settings,
    type: "link",
    path: "/configurations",
    givenPermission: [
      "create_configuration",
      "read_configuration",
      "update_configuration",
    ],
  },
];
const useMenus = () => {
  const { permissions = [] } = useSelector((state) => state.login);

  const [newAllMenus, setNewAllMenus] = useState(allMenus);

  useEffect(() => {
    if (permissions.length) {
      const updatedAllMenus = filterMenus(newAllMenus);
      setNewAllMenus(updatedAllMenus);
    }
  }, [permissions]);

  const filterMenus = (menus) => {
    return menus.filter((menu) => {
      if (menu.children) {
        const filteredChildren = filterMenus(menu.children);
        if (filteredChildren.length) {
          menu.children = filteredChildren;
          return true;
        }
        return false;
      } else {
        if (menu.givenPermission) {
          return hasPermissions(permissions, menu.givenPermission);
        }
        return true;
      }
    });
  };

  return [newAllMenus];
};

export default useMenus;
