import React, { useEffect, useState } from "react";
import { calculateGST } from "../../../services";

const ReturnProductDetailTable = ({
  code,
  totalPrice,
  discount,
  productGst,
  label,
}) => {
  const Subtotal = totalPrice - discount;

  const GstTotal =
    productGst && totalPrice
      ? calculateGST(totalPrice - discount, productGst)
      : 0;

  const Total = totalPrice
    ? totalPrice - discount + calculateGST(totalPrice - discount, productGst)
    : "";

  return (
    <table
      className="table table-bordered"
      style={{ fontSize: "12px", marginBottom: 0 }}
    >
      <thead>
        <tr>
          <th scope="col" className="p-1 text-start">
            Code
          </th>
          <th scope="col" className="p-1 text-start">
            Title
          </th>
          <th scope="col" className="p-1 text-start">
            GST ({productGst}%)
          </th>
          <th scope="col" className="p-1 text-start">
            Subtotal
          </th>
          <th scope="col" className="p-1 text-start">
            Total
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="p-1 text-start">{code}</td>
          <td className="p-1 text-start">{label}</td>
          <td className="p-1 text-start">
            {GstTotal ? Number(GstTotal.toFixed(2)) : ""}
          </td>
          <td className="p-1 text-start">
            {Subtotal ? Number(Subtotal.toFixed(2)) : ""}
          </td>
          <td className="p-1 text-start">
            {Total ? Number(Total?.toFixed(2)) : ""}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ReturnProductDetailTable;
