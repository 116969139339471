import { useMemo, useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { html2pdf } from "html2pdf.js";

import { Form, Formik } from "formik";
import InputField from "../../components/form/input/input";
import ButtonField from "../../components/form/button";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import httpRequest from "../../axios";
import {
  CREATE_ORDERS,
  CUSTOMER_CREATE_ORDERS,
  CUSTOMER_UPDATE_ORDERS,
  ORDERS,
  UPDATE_ORDERS,
} from "../../constant/apiEndPoints";
import show_Toast from "../../helpers/toast.helper";
import SelectField from "../../components/form/select";
import { ProductsSchema } from "../../utils/validations/order.validation";
import { ErrorMessage, Field, FieldArray } from "formik";
import MultipleUnits from "./ProductUnits/MultipleUnits";
import "./index.css";

import SearchSelectField from "../../components/form/select/SearchSelect";
import {
  BillHtml,
  CalculatePayableAmount,
  TotalPriceSingleOrder,
  calculateGST,
  calculateTotalAmount,
  findConfigurationByKey,
  findConfigurationByKeyPublic,
  formatNumber,
  getAddonServices,
  getAllProfiles,
  getProductService,
} from "../../services";
import TextArea from "../../components/form/textarea/input";
import { PaymentOptionsOrder } from "../../constant";
import OrderAmount from "./TotalAmounts/OrderAmount";
import PayableAmount from "./TotalAmounts/PayableAmount";
import Select from "react-select";
import "./index.scss";
import { parseInt } from "lodash";
import ProductCalculation from "./ProductCalculation";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import ProductDetailTable from "./ProductDetailTable/ProductDetailTable";
import PayableInFormation from "./payableInFormation";
import { HtmlToPdf, getValuesofSingleOrder } from "../../services/common";

const OrderForm = ({
  initialValues,
  type,
  id,
  preSelctedProducts = [],
  orderType = "",
  order = "",
  user,
}) => {
  const history = useHistory();
  const printRef = useRef();

  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate());
  const minDate = currentDate.toISOString().split("T")[0];

  const [isLoading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [profiles, setprofiles] = useState([]);
  const [removedProducts, setRemovedProducts] = useState([]);
  const [removeIndex, setRemoveIndex] = useState(null);
  const [isReceived, setIsReceived] = useState(initialValues.is_received);
  const [isPaid, setIsPaid] = useState(initialValues.is_paid);
  const [selectedProductNames, setSelectedProductNames] = useState([]);
  const [selectedProfileId, setSelectedProfileId] = useState();
  const [walletBalance, setWalletBalance] = useState();
  const [selectedProductOptions, setSelectedProductOptions] = useState([]);
  const [payable, setPayable] = useState(0);
  const [payableCopy, setPayableCopy] = useState(0);
  const [addonsList, setAddonsList] = useState([]);
  const [filteredAddonsList, setFilteredAddonsList] = useState(addonsList);
  const [html, setHtml] = useState("");

  const [selectedAddons, setSelectedAddons] = useState([]);
  const [BasicLineTab, setBasicLineTab] = useState("1");

  const [total, setTotal] = useState(0);
  const [discountPerProduct, setDiscountPerProduct] = useState(0);
  const [supplierCost, setSupplierCost] = useState();
  const [limit, setLimit] = useState();

  const [name, setName] = useState("");
  const [tel, setTel] = useState("");
  const [termsConditions, setTermsConditions] = useState("");
  const [address, setAddress] = useState("");

  const [showNoteField, setShowNoteField] = useState(false);

  const compantDetails = async () => {
    try {
      const [dataAddress, dataName, dataTel, dataTerms] = await Promise.all([
        findConfigurationByKeyPublic("ADDRESS"),
        findConfigurationByKeyPublic("COMPANY_NAME"),
        findConfigurationByKeyPublic("TELEPHONE"),
        findConfigurationByKey("C_ORDER_TERMS_CONDITIONS"),
      ]);

      setAddress(dataAddress.data.data.value);
      setName(dataName.data.data.value);
      setTel(dataTel.data.data.value);
      setTermsConditions(dataTerms?.data?.data?.value);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    compantDetails();
  }, []);

  const [firstRowProduct, setFirstRowProduct] = useState({
    name: "",
    price: "",
    units: [],
    discount: "",
    productcode: "",
    totalPrice: "",
    sellingUnitName: "",
    sellingUnitSymbol: "",
  });
  const [totalPriceSingleProduct, setTotalPriceSingleProduct] = useState(0);
  if (user === "customer" && type === "add") {
    initialValues.profile_id = 0;
  }
  const getPosts = async () => {
    try {
      let AllProducts = user === "customer" ? "YES" : "ALL";
      const { data } = await getProductService(-1, 1, "", {}, AllProducts);
      if (data.status === "Success") {
        let poss = data?.data?.data;
        if (poss.length) {
          poss = poss.map((item) => {
            return {
              ...item,
              label: item?.data?.code + " - " + item?.data?.name,
              productcode: item?.data?.code,
              unitPrice: item?.data?.unit_price,
              productgst: item?.data?.gst,
              sellingUnitId: item?.data?.selling_unit_id,
              sellingUnitName: item?.data?.selling_unit_title,
              sellingUnitSymbol: item?.data?.selling_unit_symbol,
            };
          });
          setPosts(poss);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProfiles = async () => {
    try {
      const { data } = await getAllProfiles(-1, 1, "", orderType);
      if (data.status === "Success") {
        let poss = data?.data?.data;

        if (poss.length) {
          poss = poss.map((item) => {
            return {
              ...item,
              label:
                item?.data?.name + "     ( " + item?.data?.mobile_number + " )",
              value:
                item?.data?.name + "     ( " + item?.data?.mobile_number + " )",
            };
          });
          if (user === "customer") {
            poss.unshift({
              label: "walk-in customer",
              value: "walk-in customer",
              id: 0,
            });
          }
        } else {
          if (user === "customer") {
            poss.unshift({
              label: "walk-in customer",
              value: "walk-in customer",
              id: 0,
            });
          }
        }
        setprofiles(poss);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAddons = async () => {
    try {
      const { data } = await getAddonServices(-1, 1, {}, "");
      if (data.status === "Success") {
        setAddonsList(data?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPosts();
    getProfiles();
    getAddons();
  }, []);

  useEffect(() => {
    if (type === "edit") {
      setSelectedProductOptions([...preSelctedProducts]);
    }
  }, [type, preSelctedProducts]);

  useEffect(() => {
    setSelectedProfileId(initialValues.profile_id);
  }, [initialValues.profile_id]);

  useEffect(() => {
    setIsPaid(initialValues.is_paid);
    setIsReceived(initialValues.is_received);
  }, [
    initialValues.is_paid,
    initialValues.is_received,
    initialValues.profile_id,
  ]);

  const createOrder = async (values, print = false) => {
    setLoading(true);
    let setValuesForApi;
    if (values.is_received === "YES") {
      delete values.scheduled_delivery_date;
    }
    if (values.is_paid === "YES") {
      delete values.paid_amount;
    }
    if (values.tag === "") {
      delete values.tag;
    }
    setValuesForApi = { ...values };

    let modifyProducts = setValuesForApi.products.map((item) => {
      let stocks = [];
      if (item.units.length) {
        stocks = item.units.map((uni) => ({
          unit_id: uni.id,
          stock: uni.value,
        }));
      }
      return {
        product_id: item.name,
        product_cost: item.price,
        gst: item.productGst,
        discount: item.discount,
        selling_unit_id: item?.sellingUnitId,
        requested_stock: stocks,
        note: item?.note,
      };
    });
    try {
      setLoading(false);

      if (type === "add") {
        var resp = await httpRequest.post(
          orderType === "customer" ? CUSTOMER_CREATE_ORDERS : CREATE_ORDERS,
          {
            ...setValuesForApi,
            products: modifyProducts,
            profile_id: parseInt(values.profile_id),
          }
        );
      } else {
        var resp = await httpRequest.put(
          orderType === "customer"
            ? CUSTOMER_UPDATE_ORDERS + id
            : UPDATE_ORDERS + "/" + id,
          {
            ...setValuesForApi,
            products: modifyProducts,
            ...(order === "partial"
              ? { nonDlieverProducts: removedProducts }
              : {}),
            ...(order === "full" ? { is_received: "YES" } : {}),
          }
        );
      }
      setLoading(false);
      show_Toast({
        status: true,
        message:
          resp?.data.status === "Success" && type === "edit"
            ? "Order Updated successfully"
            : "Order Created successfully",
      });
      history.push("/orders/" + orderType);

      if (print) {
        let orderValues = await getValuesofSingleOrder(
          resp?.data?.data?.id,
          orderType
        );
        const customer =
          orderType === "customer"
            ? orderValues?.customer?.data?.name
            : orderValues?.supplier?.data?.name;

        const {
          id,
          created_at,
          total_order_amount,
          payment_method,
          data,
          description,
          tag,
          addOns,
        } = orderValues;

        const timestamp = new Date(created_at);

        const formattedDate = timestamp.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });

        const formattedTime = timestamp.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
        });

        const formattedDateTime = `${formattedDate} ${formattedTime}`;

        const htmlContent = BillHtml(
          id,
          formattedDateTime,
          total_order_amount,
          payment_method,
          name,
          tel,
          termsConditions,
          address,
          description,
          tag,
          data,
          customer,
          addOns
        );
        HtmlToPdf(htmlContent, true);
      }
    } catch (error) {
      setLoading(false);
      show_Toast({
        status: false,
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };

  const handlerSelectProduct = (obj) => {
    return new Promise((resolve) => {
      if (obj && obj?.data?.units?.length) {
        let allUnits = obj?.data?.units?.map((item) => ({
          id: item.unit_id,
          value: "",
          name: item.name,
          symbol: item.symbol,
          isBaseUnit: item?.is_base_unit,
          originalValue: item?.originalValue,
        }));
        setSelectedProductOptions([obj.value]);
        resolve({
          // totalPrice: obj?.data?.unit_price,
          discount: 0,
          units: allUnits?.length ? [...allUnits] : [],
          name: obj?.value,
          label: obj?.label,
          price: obj?.unitPrice,
          productcode: obj?.productcode,
          sellingUnitName: obj?.sellingUnitName,
          sellingUnitSymbol: obj?.sellingUnitSymbol,
          productGst: obj?.productgst,
          sellingUnitId: obj?.sellingUnitId,
        });
      }
    });
  };

  useEffect(() => {
    let arr = initialValues.products;
    let totalPrice = 0;
    for (let product of arr) {
      totalPrice += product.price;
    }
  }, [removeIndex]);

  // Calculate total cost based on unit values and cost input
  const calculateTotalCost = (units, cost, sellingunitid) => {
    const unitSumOtherThanBaseUnit = units
      .filter((item) => !item.isBaseUnit)
      .map(
        ({ value, originalValue }) =>
          (parseFloat(value) || 0) * (parseFloat(originalValue) || 0)
      )
      .reduce((sum, value) => sum + value, 0);

    const baseUnitValue = units.find((item) => item?.isBaseUnit)?.value || 0;

    const sum = unitSumOtherThanBaseUnit + parseFloat(baseUnitValue);

    const sellingUnitOriginalVal = units.find(
      (item) => item?.id === parseInt(sellingunitid)
    )?.originalValue;
    return calculateTotalAmount(sum / sellingUnitOriginalVal, parseFloat(cost));
  };

  useEffect(() => {
    let pro = profiles.find((item) => {
      return item?.id === initialValues.profile_id;
    });
    setWalletBalance(pro?.balance);
  }, [profiles, initialValues]);

  // const printHtml = (htmlContent) => {
  //   const iframe = document.createElement("iframe");
  //   iframe.style.display = "none";
  //   document.body.appendChild(iframe);
  //   iframe.contentDocument.write(htmlContent);
  //   iframe.contentDocument.close();
  //   iframe.contentWindow.print();
  //   setTimeout(() => {
  //     document.body.removeChild(iframe);
  //   }, 1000);
  // };

  // const printHtml = (htmlContent, width = "400px", height = "600px") => {
  //   const iframe = document.createElement("iframe");
  //   iframe.style.display = "none";
  //   document.body.appendChild(iframe);

  //   iframe.contentDocument.write(htmlContent);
  //   iframe.contentDocument.close();

  //   const style = document.createElement("style");
  //   style.textContent = `
  //       @page {
  //           size: ${width} ${height};
  //       }
  //       body {
  //           width: ${width};
  //           height: ${height};
  //           margin: 0; /* Reset margin to ensure the content fills the entire page */
  //       }
  //   `;
  //   iframe.contentDocument.head.appendChild(style);

  //   iframe.contentWindow.print();

  //   setTimeout(() => {
  //     document.body.removeChild(iframe);
  //   }, 1000);
  // };

  // const printHtml = (
  //   htmlContent,
  //   options = {
  //     scale: 0.8,
  //     filename: "custom.pdf",
  //     margin: [10, 10],
  //   }
  // ) => {
  //   // console.log(htmlContent, ":", options);

  //   const iframe = document.createElement("iframe");
  //   iframe.style.display = "none";
  //   document.body.appendChild(iframe);
  //   iframe.contentDocument.write(htmlContent);
  //   iframe.contentDocument.close();

  //   const element = document.createElement("div");
  //   element.innerHTML = htmlContent;

  //   const elementCopy = element.cloneNode(true);
  //   elementCopy.style.position = "absolute";
  //   elementCopy.style.left = "-9999px";
  //   document.body.appendChild(elementCopy);

  //   const divHeight = elementCopy.offsetHeight;
  //   elementCopy.remove();
  //   const height = divHeight / 96 + (divHeight <= 5 ? 0.5 : 1.5);

  //   const pdf = html2pdf();
  //   pdf.set({
  //     scale: options.scale || 0.5,
  //     filename: options.filename || "output.pdf",
  //     margin: options.margin || [0, 0],
  //     image: options.image || { type: "jpeg", quality: 0.98 },
  //     html2canvas: options.html2canvas || { dpi: 192, letterRendering: true },
  //   });
  //   pdf.from(element);
  //   pdf.outputPdf().then((pdf) => {
  //     const blob = new Blob([pdf], { type: "application/pdf" });
  //     const url = URL.createObjectURL(blob);
  //     const printWindow = window.open(url, "_blank");
  //     printWindow.print();
  //     setTimeout(() => {
  //       document.body.removeChild(iframe);
  //     }, 1000);
  //   });
  // };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        createOrder(values);
      }}
      validationSchema={ProductsSchema(isReceived, type, total)}
      enableReinitialize
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          setFieldValue,
          handleBlur,
          handleChange,
          isSubmitting,
        } = props;
        const isEditMode = type === "edit";
        return (
          <Form className="form theme-form">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  {!isEditMode ? (
                    <div className="absolute-string">
                      <PayableInFormation
                        payable={payable}
                        paidAmount={values?.paid_amount}
                        isPaid={values?.is_paid}
                        walletBalance={walletBalance}
                        total={total}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="d-flex justify-content-end absolute-total gap-4">
                    <OrderAmount
                      setFieldValue={setFieldValue}
                      total={total}
                      setTotal={setTotal}
                      products={values?.products}
                      discount={values?.discount}
                      addons={values?.addOns}
                    />
                    <PayableAmount
                      payable={payable}
                      setPayable={setPayable}
                      setFieldValue={setFieldValue}
                      products={values?.products}
                      paid_amount={values?.paid_amount}
                      addons={values?.addOns}
                      walletBalance={walletBalance}
                      total={total}
                      isPaid={isPaid}
                      isEditMode={isEditMode}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8">
                    <Nav className="border-tab customNav" tabs>
                      <NavItem className=" customNavItem">
                        <NavLink
                          className={BasicLineTab === "1" ? "active" : ""}
                          onClick={() => setBasicLineTab("1")}
                        >
                          Products
                        </NavLink>
                      </NavItem>
                      <NavItem className=" customNavItem">
                        <NavLink
                          className={BasicLineTab === "2" ? "active" : ""}
                          onClick={() => setBasicLineTab("2")}
                        >
                          Add-On's
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={BasicLineTab}>
                      <TabPane className="fade show" tabId="1">
                        <>
                          {type !== "edit" ? (
                            <div className="mb-3">
                              <h6 className="mb-3">Add Product</h6>
                              <SearchSelectField
                                className={"inputclass rounded"}
                                name={"productfieldRow"}
                                onChange={async (value) => {
                                  const data = await handlerSelectProduct(
                                    value
                                  );
                                  setFieldValue("products", [
                                    { ...data, totalPrice: 0 },
                                    ...values.products,
                                  ]);
                                  setSelectedProductNames([
                                    ...selectedProductNames,
                                    data.name,
                                  ]);
                                }}
                                placeholder="Select Product"
                                labelKey={"label"}
                                onBlur={handleBlur}
                                options={posts.filter(
                                  (post) =>
                                    !selectedProductNames.includes(post.id)
                                )}
                                disabled={isEditMode}
                              />
                            </div>
                          ) : (
                            <h6 className="mb-2">Products</h6>
                          )}
                          <div className="row">
                            <div className="col-md-12 product-scroll-custom mt-3">
                              <FieldArray name="products">
                                {({ push, remove }) => (
                                  <>
                                    {values.products.map((product, index) => {
                                      const productOptionsForThisRow = [];
                                      posts.forEach((option) => {
                                        if (product.name) {
                                          if (
                                            product.name == option.id &&
                                            selectedProductOptions.includes(
                                              option.id
                                            )
                                          ) {
                                            productOptionsForThisRow.push(
                                              option
                                            );
                                          } else {
                                            if (
                                              !selectedProductOptions.includes(
                                                option.id
                                              )
                                            ) {
                                              productOptionsForThisRow.push(
                                                option
                                              );
                                            }
                                          }
                                        } else {
                                          if (
                                            !selectedProductOptions.includes(
                                              option.id
                                            )
                                          ) {
                                            productOptionsForThisRow.push(
                                              option
                                            );
                                          }
                                        }
                                      });
                                      const sellingUnit = posts.find(
                                        (post) => post.id == product.name
                                      );
                                      return (
                                        <div
                                          key={index}
                                          className={`row highlight mb-2 pt-0`}
                                          style={{ fontSize: "12px" }}
                                        >
                                          <div className="d-flex justify-content-end align-items-start mb-2 mt-1 p-0">
                                            <button
                                              type="button"
                                              className="btn btn-red p-0"
                                              style={{
                                                width: "35px",
                                                borderRadius: "3px",
                                                marginRight: "3px",
                                              }}
                                              onClick={() => {
                                                const newSelectedProductOptions =
                                                  selectedProductOptions.filter(
                                                    (item) =>
                                                      item !== product.name
                                                  );

                                                setSelectedProductOptions(
                                                  newSelectedProductOptions
                                                );
                                                setRemoveIndex(index);
                                                remove(index);
                                                setRemovedProducts(
                                                  (prevRemovedProducts) => [
                                                    ...prevRemovedProducts,
                                                    product,
                                                  ]
                                                );
                                                setSelectedProductNames(
                                                  selectedProductNames.filter(
                                                    (name) =>
                                                      name !== product.name
                                                  )
                                                );
                                              }}
                                              disabled={isEditMode}
                                            >
                                              -
                                            </button>
                                          </div>

                                          <div>
                                            <ProductDetailTable
                                              code={product.productcode}
                                              totalPrice={product.totalPrice}
                                              productPrice={product.price}
                                              discount={product.discount}
                                              productGst={product.productGst}
                                              label={product.label}
                                            />
                                          </div>

                                          <div className="col-lg-2 mt-2">
                                            <label htmlFor="">
                                              Cost{" "}
                                              {product.sellingUnitSymbol
                                                ? `${`( per ${product.sellingUnitSymbol})`}`
                                                : ""}
                                            </label>
                                            <Field
                                              className="form-control inputclass"
                                              name={`products.${index}.price`}
                                              placeholder="cost"
                                              type="number"
                                              disabled={
                                                isEditMode ||
                                                user === "customer"
                                              }
                                              style={{ fontSize: "12px" }}
                                              onChange={(e) => {
                                                const { value } = e.target;

                                                const units =
                                                  values.products[index].units;

                                                // Calculate the updated totalPrice
                                                const updatedTotalPrice =
                                                  calculateTotalCost(
                                                    units,
                                                    parseFloat(value),
                                                    values.products[index]
                                                      ?.sellingUnitId
                                                  );

                                                const updatedProducts =
                                                  values.products.map(
                                                    (prod, idx) =>
                                                      idx === index
                                                        ? {
                                                            ...prod,
                                                            totalPrice:
                                                              updatedTotalPrice,
                                                            price: value,
                                                          }
                                                        : prod
                                                  );

                                                // Update the products array in form values
                                                setFieldValue(
                                                  "products",
                                                  updatedProducts
                                                );
                                              }}
                                            />
                                            <ErrorMessage
                                              name={`products.${index}.price`}
                                              component="div"
                                              className="error-message"
                                            />
                                          </div>
                                          <div className="col-lg-2 mt-2">
                                            <label htmlFor="">Discount</label>
                                            <Field
                                              className="form-control inputclass"
                                              name={`products.${index}.discount`}
                                              placeholder="discount"
                                              type="number"
                                              style={{ fontSize: "12px" }}
                                              disabled={isEditMode}
                                            />
                                            <ErrorMessage
                                              name={`products.${index}.price`}
                                              component="div"
                                              className="error-message"
                                            />
                                          </div>
                                          <MultipleUnits
                                            index={index}
                                            product={product}
                                            currentSelectedUnits={
                                              sellingUnit?.data?.units || []
                                            }
                                            setFieldValue={setFieldValue}
                                            values={values}
                                            disabled={isEditMode}
                                            onChangeUnit={(
                                              e,
                                              productIndex,
                                              unitIndex
                                            ) => {
                                              const { value } = e.target;
                                              const updatedUnits =
                                                values.products[
                                                  productIndex
                                                ].units.map((item, index) =>
                                                  index === unitIndex
                                                    ? { ...item, value }
                                                    : item
                                                );

                                              const updatedProducts =
                                                values.products.map(
                                                  (prod, idx) =>
                                                    idx === productIndex
                                                      ? {
                                                          ...prod,
                                                          units: updatedUnits,
                                                        }
                                                      : prod
                                                );
                                              setFieldValue(
                                                "products",
                                                updatedProducts
                                              );

                                              const updatedTotalPrice =
                                                calculateTotalCost(
                                                  updatedUnits,
                                                  parseInt(
                                                    updatedProducts[
                                                      productIndex
                                                    ].price
                                                  ),
                                                  product?.sellingUnitId
                                                );

                                              const updatedProduct = {
                                                ...updatedProducts[
                                                  productIndex
                                                ],
                                                totalPrice: updatedTotalPrice,
                                              };
                                              const updatedProductsArray = [
                                                ...updatedProducts,
                                              ];
                                              updatedProductsArray[
                                                productIndex
                                              ] = updatedProduct;

                                              setFieldValue(
                                                "products",
                                                updatedProductsArray
                                              );
                                            }}
                                          />
                                          {!isEditMode ? (
                                            <div className="mt-2">
                                              <ButtonField
                                                className={
                                                  "btn btn-link p-0 m-0"
                                                }
                                                buttonText={"Add Notes"}
                                                onClick={() =>
                                                  setShowNoteField(true)
                                                }
                                                type="button"
                                              />
                                            </div>
                                          ) : (
                                            <></>
                                          )}

                                          {showNoteField || isEditMode ? (
                                            <div>
                                              <div className="col-lg-4 mt-2">
                                                {isEditMode &&
                                                  showNoteField === false && (
                                                    <label>Notes</label>
                                                  )}
                                                <Field
                                                  className="form-control inputclass"
                                                  name={`products.${index}.note`}
                                                  placeholder="Add Notes"
                                                  type="text"
                                                  style={{ fontSize: "12px" }}
                                                  maxlength="30"
                                                  disabled={isEditMode}
                                                />
                                              </div>
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      );
                                    })}
                                  </>
                                )}
                              </FieldArray>
                            </div>
                          </div>
                        </>
                      </TabPane>
                      <TabPane tabId="2">
                        <>
                          <h6 className="mb-3">Add Order Add-On's</h6>
                          <SearchSelectField
                            className={"inputclass rounded w-100"}
                            name={`addOnsFirstColumn`}
                            onChange={(selectedOption) => {
                              let newAddon = {
                                id: selectedOption?.id,
                                name: selectedOption?.title,
                                amount: "",
                              };
                              if (
                                values?.addOns[values?.addOns?.length - 1]
                                  ?.amount !== ""
                              ) {
                                setFieldValue("addOns", [
                                  newAddon,
                                  ...values.addOns,
                                ]);
                              } else {
                                show_Toast({
                                  status: false,
                                  message: "Please fill all fields",
                                });
                              }
                            }}
                            labelKey={"title"}
                            valueKey={"title"}
                            options={addonsList.filter((option) => {
                              return !values.addOns.some(
                                (attr) => attr.id == option.id
                              );
                            })}
                            disabled={isEditMode}
                          />

                          <div className="row">
                            <div className="col-md-12 mt-3">
                              <FieldArray name="addOns">
                                {({ push, remove }) => {
                                  return (
                                    <>
                                      {values?.addOns?.map((item, index) => {
                                        const updatedOptions =
                                          addonsList.filter((option) => {
                                            return (
                                              !values.addOns.some(
                                                (attr) => attr.id == option.id
                                              ) || item.id == option.id
                                            );
                                          });

                                        return (
                                          <div
                                            key={index}
                                            className={`row mb-4 highlight pb-4`}
                                            style={{ margin: "0 0" }}
                                          >
                                            <div className="col-md-6">
                                              <InputField
                                                placeholder="addon name"
                                                className={
                                                  "inputclass rounded w-100 bg-white"
                                                }
                                                type="text"
                                                name={`addOns.${index}.name`}
                                                value={item?.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                disabled={isEditMode}
                                              />
                                            </div>

                                            <div className="col-md-6 d-flex align-items-center gap-2">
                                              <div className="flex-grow-1">
                                                <InputField
                                                  placeholder="Amount"
                                                  type="number"
                                                  name={`addOns.${index}.amount`}
                                                  value={item?.amount}
                                                  onChange={handleChange}
                                                  onBlur={handleBlur}
                                                  disabled={isEditMode}
                                                />
                                              </div>
                                              <ButtonField
                                                type="button"
                                                className="btn btn-red"
                                                buttonText="-"
                                                onClick={() => {
                                                  const newSelectedProductOptions =
                                                    selectedProductOptions.filter(
                                                      (item) =>
                                                        item != item.name
                                                    );

                                                  setSelectedProductOptions(
                                                    newSelectedProductOptions
                                                  );
                                                  remove(index);
                                                }}
                                                disabled={isEditMode}
                                              />
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </>
                                  );
                                }}
                              </FieldArray>
                            </div>
                          </div>
                        </>
                      </TabPane>
                    </TabContent>
                  </div>
                  <div className="col-md-4">
                    <h6 className="mb-2">Details</h6>
                    <div className="row">
                      <div className="col-md-6">
                        <InputField
                          placeholder="Paid Amount"
                          label="Paid Amount"
                          type="number"
                          name="paid_amount"
                          value={values.paid_amount}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={isPaid === "YES" || isEditMode}
                          errorMessage={
                            errors.paid_amount &&
                            touched.paid_amount &&
                            errors.paid_amount
                          }
                          error={
                            errors.paid_amount && touched.paid_amount
                              ? true
                              : false
                          }
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="" className="text-capitalize">
                          Payment method
                        </label>
                        <SearchSelectField
                          className={
                            "inputclass rounded flex-grow-1 zindex-top"
                          }
                          name="payment_method"
                          value={values.payment_method}
                          onChange={(e) => {
                            setFieldValue("payment_method", e.id);
                          }}
                          onBlur={handleBlur}
                          options={PaymentOptionsOrder}
                          disabled={isEditMode}
                          errorMessage={
                            errors.payment_method &&
                            touched.payment_method &&
                            errors.payment_method
                          }
                          error={
                            errors.payment_method && touched.payment_method
                              ? true
                              : false
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <InputField
                          placeholder="Scheduled Delivery Date"
                          label="Delivery Date"
                          type="date"
                          name="scheduled_delivery_date"
                          value={values?.scheduled_delivery_date?.split(" ")[0]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={
                            isReceived === "YES" ? true : false || isEditMode
                          }
                          min={minDate}
                          errorMessage={
                            errors.scheduled_delivery_date &&
                            touched.scheduled_delivery_date &&
                            isReceived === "NO" &&
                            errors.scheduled_delivery_date
                          }
                          error={
                            errors.scheduled_delivery_date &&
                            touched.scheduled_delivery_date &&
                            isReceived === "NO"
                              ? true
                              : false
                          }
                        />
                      </div>
                      <div className="col-md-6 d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-start">
                          <div className="Is_Received d-flex">
                            <div className="d-flex align-items-center">
                              <input
                                id="is_received_yes"
                                type="checkbox"
                                name="is_received"
                                className="checkbox_animated"
                                checked={values.is_received === "YES"}
                                onChange={(e) => {
                                  const value = e.target.checked ? "YES" : "NO";
                                  setFieldValue("is_received", value);
                                  setIsReceived(value);
                                }}
                                value={values.is_received}
                                style={{ height: "16px", width: "16px" }}
                                disabled={selectedProfileId === 0 || isEditMode}
                              />
                            </div>
                          </div>
                          <label htmlFor="is_received_yes">Received</label>
                          <span className="error-message">
                            {errors.is_received ? (
                              <div>{errors.is_received}</div>
                            ) : null}
                          </span>
                        </div>
                        <div className="d-flex align-items-start">
                          <div className="is_Paid d-flex">
                            <div className="d-flex align-items-center">
                              <input
                                id="is_paid_yes"
                                type="checkbox"
                                name="is_paid"
                                className="cursor-pointer checkbox_animated"
                                checked={values.is_paid == "YES"}
                                disabled={selectedProfileId === 0 || isEditMode}
                                onChange={(e) => {
                                  const value = e.target.checked ? "YES" : "NO";
                                  setFieldValue("is_paid", value);
                                  setIsPaid(value);
                                }}
                                value={values.is_paid}
                              />
                            </div>
                          </div>
                          <label>Paid</label>
                          <span className="error-message">
                            {errors.is_paid ? (
                              <div>{errors.is_paid}</div>
                            ) : null}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6 mt-3 mb-3">
                        <label>
                          {orderType === "customer" ? "Customer" : "Supplier"}
                        </label>
                        <Select
                          className="border rounded"
                          options={profiles}
                          name="profile_id"
                          value={profiles.find(
                            (profile) => profile.id === values.profile_id
                          )}
                          onChange={(selectedOption) => {
                            setSelectedProfileId(selectedOption.id);
                            setWalletBalance(selectedOption?.balance);
                            setFieldValue(
                              "wallet_balance",
                              selectedOption?.balance
                            );
                            setFieldValue("profile_id", selectedOption.id);
                            if (selectedOption.id === 0) {
                              setFieldValue("is_paid", "YES");
                              setFieldValue("is_received", "YES");
                            }
                          }}
                          onBlur={handleBlur}
                          placeholder={`Search for ${
                            orderType === "customer" ? "Customer" : "Seller"
                          }`}
                          isSearchable
                          isDisabled={isSubmitting || isEditMode}
                        />
                        <ErrorMessage
                          name={`profile_id`}
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <div className="col-md-6  mt-3 mb-3">
                        <InputField
                          placeholder="Tag"
                          label="Tag"
                          type="text"
                          name="tag"
                          value={values.tag}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={isEditMode}
                          errorMessage={
                            errors?.tag && touched?.tag && errors?.tag
                          }
                          error={errors?.tag && touched?.tag ? true : false}
                        />
                      </div>

                      {walletBalance > 0 &&
                      values.profile_id &&
                      values.profile_id !== 0 ? (
                        <>
                          <div className="col-md-6  mt-3 mb-3">
                            <InputField
                              placeholder="Wallet Balance"
                              label="Wallet Balance"
                              type="text"
                              name="walletBalance"
                              value={walletBalance}
                              disabled={isEditMode}
                              readonly={true}
                              classes={"text-secondary"}
                            />
                          </div>

                          <div
                            className={`col-md-6 d-flex align-items-center justify-content-between opacity-75`}
                          >
                            <div className="d-flex align-items-start">
                              <div className="is_Paid d-flex">
                                <div className="d-flex align-items-center">
                                  <input
                                    id="adjust_from_wallet"
                                    type="checkbox"
                                    name="adjust_from_wallet"
                                    className="cursor-pointer checkbox_animated"
                                    checked={values.adjust_from_wallet == "YES"}
                                    disabled={true}
                                    //  selectedProfileId === 0 ||
                                    //   isEditMode ||
                                    //   walletBalance === 0 ||
                                    //   values?.is_paid === "YES"
                                    onChange={(e) => {
                                      const value = e.target.checked
                                        ? "YES"
                                        : "NO";
                                      setFieldValue(
                                        "adjust_from_wallet",
                                        value
                                      );
                                    }}
                                    value={values.adjust_from_wallet}
                                  />
                                </div>
                              </div>
                              <label>Adjust from wallet</label>
                              <span className="error-message">
                                {errors.is_paid ? (
                                  <div>{errors.is_paid}</div>
                                ) : null}
                              </span>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      <div className="col-md-12" style={{ width: "100%" }}>
                        <div className="row">
                          <div className="col-md-12">
                            <TextArea
                              placeholder="Description"
                              label="Description"
                              type="text"
                              name="description"
                              textAreaRows="2"
                              value={`${
                                values?.description ? values?.description : ""
                              }`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={isEditMode}
                              error={
                                errors.description && touched.description
                                  ? true
                                  : false
                              }
                              errorMessage={
                                errors.description &&
                                touched.description &&
                                errors.description
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer me-0 pe-0">
                    {type !== "edit" ? (
                      <div className="d-flex justify-content-end align-items-center gap-2">
                        <div>
                          <ButtonField
                            className={"btn btn-red btn-sm"}
                            buttonText={"Cancel"}
                            type="button"
                            onClick={() => {
                              history.goBack();
                            }}
                          />
                        </div>
                        <div>
                          <ButtonField
                            className={"btn btn-primary btn-sm"}
                            buttonText={type === "edit" ? "Update" : "Create"}
                            type="submit"
                          />
                        </div>
                        <div>
                          <ButtonField
                            className={"btn btn-primary btn-sm"}
                            buttonText={"Create & Print"}
                            onClick={() => createOrder(values, true)}
                            type="button"
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-end align-items-center gap-2">
                        <div>
                          <ButtonField
                            className={"btn btn-danger"}
                            buttonText={"Back"}
                            type="button"
                            onClick={() => {
                              history.goBack();
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {isLoading && <div>Loading...</div>}
          </Form>
        );
      }}
    </Formik>
  );
};

export default OrderForm;
