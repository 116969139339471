import React from "react";
import FilterAccordian from "../../components/common/Filter/filter";
import InputField from "../../components/form/input/input";
import SearchSelectField from "../../components/form/select/SearchSelect";
import ButtonField from "../../components/form/button";

const UnitFilters = ({
  filters,
  setFilters,
  setApply,
  onChange,
  unitCategoriesList,
}) => {
  const handleReset = () => {
    setFilters({
      name: "",
      symbol: "",
      value: "",
      unit_category_id: "",
      is_base_unit: "",
    });
  };
  return (
    <FilterAccordian>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setApply((pre) => !pre);
        }}
      >
        <div className="border-top p-3">
          <div className="row">
            <div className="col-2">
              <InputField
                label="Name"
                placeholder={"Name"}
                name="name"
                value={filters.name}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </div>
            <div className="col-2">
              <InputField
                label="symbol"
                placeholder={"symbol"}
                name="symbol"
                value={filters.symbol}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </div>
            <div className="col-2">
              <InputField
                label="value"
                placeholder={"value"}
                name="value"
                value={filters.value}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </div>
            {/* ///////////////////////////// */}
            <div className="col-2">
              <label htmlFor="" className="text-capitalize">
                Unit Category
              </label>
              <SearchSelectField
                className={"inputclass rounded flex-grow-1"}
                name="unit_category_id"
                value={filters.unit_category_id}
                valueKey="id"
                labelKey="title"
                onChange={(e) => onChange("unit_category_id", e.id)}
                options={unitCategoriesList}
              />
            </div>
            {/* <div className="col-2">
              <label className="text-capitalize">Base Unit</label>
              <div className="d-flex gap-3">
                <div className="d-flex">
                  <label className="d-block">
                    <input
                      id="is_base_unit_yes"
                      type="radio"
                      name="is_base_unit"
                      className="radio_animated"
                      onChange={(e) => {
                        onChange("is_base_unit", e.target.value);
                      }}
                      value={1}
                      style={{ height: "16px", width: "16px" }}
                    />
                  </label>
                  <label htmlFor="" className="text-capitalize">
                    Yes
                  </label>
                </div>
                <div className="d-flex">
                  <label className="d-block">
                    <input
                      id="is_base_unit_no"
                      type="radio"
                      name="is_base_unit"
                      className="radio_animated"
                      onChange={(e) => {
                        onChange("is_base_unit", e.target.value);
                      }}
                      value={0}
                      style={{ height: "16px", width: "16px" }}
                    />
                  </label>
                  <label htmlFor="" className="text-capitalize">
                    No
                  </label>
                </div>
              </div>
            </div> */}
            <div className="col-2">
              <label className="text-capitalize">Base Unit</label>
              <SearchSelectField
                className={"inputclass rounded flex-grow-1"}
                name="is_base_unit"
                value={filters.is_base_unit}
                valueKey="value"
                labelKey="label"
                onChange={(e) => onChange("is_base_unit", e.value)}
                options={[
                  { label: "All", value: "all" },
                  { label: "Yes", value: 1 },
                  { label: "No", value: 0 },
                ]}
              />
            </div>
            {/* // end */}
          </div>
          <div className="d-flex gap-2 justify-content-end">
            <ButtonField
              buttonText="Reset"
              onClick={handleReset}
              className="btn btn-danger"
            />
            <ButtonField type="Submit" buttonText="Apply" />
          </div>
        </div>
      </form>
    </FilterAccordian>
  );
};

export default UnitFilters;
