import React from "react";
import { DebounceInput } from "react-debounce-input";
import "./index.scss";

const Search = ({ placeholder, value = "", onChange, classes }) => {
  return (
    <DebounceInput
      debounceTimeout={1000}
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className={`${classes}`}
    />
  );
};

export default Search;
