import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { useEffect } from "react";

const MultipleUnits = ({
  index,
  product,
  currentSelectedUnits,
  setFieldValue,
  values,
  disabled,
  onChangeUnit,
}) => {
  useEffect(() => {
    if (product.name) {
      currentSelectedUnits.map((item, currentindex) => {
        setFieldValue(
          `products.${index}.units.${currentindex}.id`,
          item.unit_id
        );
        setFieldValue(
          `products.${index}.units.${currentindex}.value`,
          product?.units[currentindex]?.value || ""
        );
      });
    }
  }, [currentSelectedUnits, product.name]);
  return (
    <FieldArray name={`products.${index}.units`}>
      {() => (
        <>
          {product.name &&
            product?.units.length > 0 &&
            product?.units?.map((singleUnit, attrIndex) => (
              <div key={attrIndex} className={`col-lg-2 mt-2`}>
                <label htmlFor={`products.${index}.units.${attrIndex}.value`}>
                  {product.units[attrIndex]?.name}
                </label>
                <div className="">
                  <input
                    name={`products.${index}.units.${attrIndex}.value`}
                    className="form-control inputclass"
                    disabled={disabled}
                    style={{ fontSize: "12px" }}
                    value={values.products[index].units[attrIndex]?.value || ""}
                    onChange={(e) => onChangeUnit(e, index, attrIndex)}
                    type="number"
                  />
                  <ErrorMessage
                    name={`products.${index}.units.${attrIndex}.value`}
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>
            ))}
        </>
      )}
    </FieldArray>
  );
};

export default MultipleUnits;
