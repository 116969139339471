import * as Yup from "yup";

export const AddProfileValidations = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  mobile_number: Yup.string().required("Phone Number is required"),
  // account_number: Yup.string().required("Please enter account number"),
  // email: Yup.string().required("Email is required").email("Email is not valid"),
  // account_number: Yup.string().required("Account Number is required"),
  // address: Yup.string().required("Address is required"),
  // profile_type: Yup.string().required("Please select a profile type"),
});

export const UpdateProfileValidations = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().required("Email is required").email("Email is not valid"),
  mobile: Yup.string().required("Phone Number is required"),
  address: Yup.string().required("Address is required"),
});

export const UpdatePasswordValidations = Yup.object().shape({
  current_password: Yup.string().required("Current Password is required"),
  password: Yup.string().required("New Password is required"),
});
