import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import { Link, useParams } from "react-router-dom";
import { getAllCustomerProfiles, getAllProfiles } from "../../services";
import show_Toast from "../../helpers/toast.helper";
import CustomLoader from "../../components/common/customLoader/customLoader";
import ProfileForm from "./profileForm";
import { PER_PAGE_API } from "../../constant/pagination";
import { Pagination } from "../../components/common/pagination";
import Search from "../../components/common/search/search";
import PaymentForm from "./createPayment";
import RoundedDropdown from "../../components/common/dropdown/dropdown";
import Dropdowns from "./../../components/common/dropdown/dropdown";
import { Edit, Clock, CreditCard, Send } from "react-feather";
import "./index.scss";
import { CharacterLimit } from "../../services";
import TooltipCustom from "../../components/common/tooltip/tooltip";
import PermissionBase from "../../components/common/permissionBase/permissionBase";
import { PAGE_TITLES } from "../../constant/pageTitles";
import HelmetComponent from "../../components/common/Helmet";

const AllCustomerProfiles = () => {
  const [isLoading, setLoading] = useState(false);
  const [allProfiles, setAllProfiles] = useState([]);
  const [transition, setTransition] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);

  const [fullProfile, setFullProfile] = useState({});

  const [search, setSearch] = useState([]);
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
    perPage: 10,
  });

  const [initialValuesPayment, setIntialValuesPayment] = useState({
    amount: "",
    description: "",
    deposit_date: new Date().toISOString().split("T")[0],
    payment_method: "CASH",
    payment_type: "",
  });
  const [profileId, setProfileId] = useState("");
  const [orderId, setOrderId] = useState("");
  const [payable, setPayable] = useState();

  const { type } = useParams();

  const [initialValues, setIntialValues] = useState({
    amount: "",
    description: "",
    deposit_date: new Date().toISOString().split("T")[0],
    payment_mod: "Cash",
    cheque_number: "",
    is_walk_in: 0,
  });

  const getAllProfile = async () => {
    try {
      setLoading(true);
      const { data } = await getAllCustomerProfiles(
        paginationData.perPage,
        paginationData.currentPage,
        search
        // "CUSTOMER"
      );
      if (data.status === "Success") {
        setAllProfiles(data?.data?.data);

        setPaginationData((pre) => ({
          ...pre,
          currentPage: data?.data.current_page,
          totalRecords: data?.data.total,
          perPage: data?.data?.per_page,
          totalPages: Math.ceil(data?.data.total / data?.data?.per_page),
        }));
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      show_Toast({
        status: false,
        message:
          error?.response?.data?.message ||
          "Something went wrong please reload the page or contact with admin",
      });
    }
  };

  useEffect(() => {
    getAllProfile();
  }, [paginationData.currentPage, search, formSubmit]);

  useEffect(() => {
    setPaginationData({
      currentPage: 1,
      totalPages: 0,
      totalRecords: 0,
    });
    setAllProfiles([]);
    setSearch("");
    getAllProfile();
  }, [type]);

  const handlerAfterSubmitted = () => {
    setFullProfile({});
    getAllProfile();
    setTransition(true);
  };

  useEffect(() => {
    setTransition(false);
  }, [type]);

  const dropdownList = [
    {
      name: "Edit",
      link: `/profile/customer/edit/`,
      givenPermissions: ["update_customer_profile"],
    },
    {
      name: "Make Payment",
      givenPermissions: ["make_payment_by_customer"],
    },
    {
      name: "Payment History",
      link: `/payment-history/`,
      givenPermissions: ["read_transaction"],
    },
  ];

  return (
    <Fragment>
      <HelmetComponent titleText={PAGE_TITLES["CustomerProfiles"]} />
      <Breadcrumb
        title="Profile List"
        parent="Profiles"
        parentlink="Profiles"
      />

      {isLoading ? (
        <CustomLoader />
      ) : (
        <>
          <div className="container-fluid">
            <div className={`row `}>
              <div className="col-sm-12">
                <div
                  className={`card mt-2 section ${
                    transition ? "show-congfigrations_customers" : "hide"
                  }`}
                >
                  <PaymentForm
                    initialValues={initialValuesPayment}
                    profileTypes={type}
                    profileId={profileId}
                    orderId={orderId}
                    type={"add"}
                    handlerAfterSubmitted={handlerAfterSubmitted}
                    setTransition={setTransition}
                    payable={payable}
                    setProfileId={setProfileId}
                    setOrderId={setOrderId}
                    setPayable={setPayable}
                    setFormSubmit={setFormSubmit}
                  />
                </div>

                <div className="card">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="text-capitalize">Customers</h5>

                    <PermissionBase
                      permissionName={["create_customer_profile"]}
                    >
                      <Link
                        className="btn btn-success"
                        to={`/profile/customer/add`}
                      >
                        Add Customers
                      </Link>
                    </PermissionBase>
                  </div>

                  <div className="card-body">
                    <div className="mb-3 d-flex justify-content-end">
                      <Search
                        classes="form-control search-input"
                        placeholder="Search"
                        onChange={(e) => {
                          setSearch(e.target.value);
                          setPaginationData((pre) => ({
                            ...pre,
                            currentPage: 1,
                          }));
                        }}
                        value={search}
                      />
                    </div>
                    <div
                      className="table-responsive"
                      style={{ overflowY: "", minHeight: "" }}
                    >
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Phone Number</th>
                            <th scope="col">Email</th>
                            <th scope="col">Address</th>
                            <th scope="col">
                              Balance{" "}
                              <TooltipCustom tooltipText="(+) balance means customer will pay that amount and (-) balance means customer paid extra amount and it's useful for new order." />
                            </th>
                            <PermissionBase
                              permissionName={[
                                "update_customer_profile",
                                "read_transaction",
                              ]}
                            >
                              <th scope="col">Action</th>
                            </PermissionBase>
                          </tr>
                        </thead>
                        <tbody>
                          {allProfiles?.length
                            ? allProfiles?.map((role, ind) => (
                                <tr key={ind} className="">
                                  <td className="truncate-line-custom">
                                    {CharacterLimit(role?.data?.name, 20)}
                                  </td>
                                  <td className="truncate-line-custom">
                                    {CharacterLimit(
                                      role?.data?.mobile_number,
                                      20
                                    )}
                                  </td>
                                  <td className="truncate-line-custom">
                                    {role?.data.email || "no email"}
                                  </td>
                                  <td className="truncate-line-custom">
                                    {CharacterLimit(role?.data.address, 40) ||
                                      "no address"}
                                  </td>
                                  <td>{role.balance}</td>

                                  <PermissionBase
                                    permissionName={[
                                      "update_customer_profile",
                                      "read_transaction",
                                    ]}
                                  >
                                    <td>
                                      <Dropdowns
                                        dropdownList={dropdownList}
                                        id={role.id}
                                        onclick={() => {
                                          setProfileId(role?.id);
                                          window.scrollTo(0, 0);
                                          setTransition(true);
                                        }}
                                      />
                                    </td>
                                  </PermissionBase>
                                </tr>
                              ))
                            : null}
                          {!isLoading && !allProfiles?.length
                            ? "No profiles found"
                            : null}
                        </tbody>
                      </table>
                    </div>
                    <div className="my-4">
                      <Pagination
                        selectedPage={paginationData.currentPage}
                        pageCount={paginationData.totalPages}
                        onPageChangeHandler={(page) => {
                          setPaginationData({
                            ...paginationData,
                            currentPage: page,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Fragment>
  );
};

export default AllCustomerProfiles;
