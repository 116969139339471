import React from "react";
import ButtonField from "../../components/form/button";
import InputField from "../../components/form/input/input";
import SearchSelectField from "../../components/form/select/SearchSelect";
import FilterAccordian from "../../components/common/Filter/filter";

const Filterform = ({ filters, onChange, onFilterApply, onResetApply, profileType}) => {
  return (
    <FilterAccordian>
        <div className="border-top p-3">
          <div className="row">
            <div className="row ">
                  <div className="col-2">
                        <InputField
                          type="date"
                          label="Date"
                          placeholder={"Date"}
                          classes={"mb-0"}
                          name="date"
                          value={filters.date}
                          onChange={(e) => onChange("date",e.target.value)}
                        />
                      </div>
                      <div className="col-2">
                        <label className="text-capitalize">Profile Type</label>
                        <SearchSelectField
                          className={"inputclass rounded flex-grow-1"}
                          name="profileType"
                          value={filters.profileType}
                          valueKey="value"
                          labelKey="label"
                          onChange={(e) => {
                            onChange("profileType", e.value);
                          }}
                          options={profileType}
                        />
                      </div>
                      <div className="col-2">
                        <div className="d-flex align-items-center gap-2 h-100">
                          <ButtonField
                            className="btn btn-danger"
                            buttonText="Clear"
                            onClick={onResetApply}
                          />
                          <ButtonField
                            buttonText="Apply"
                            type="submit"
                            onClick={onFilterApply}
                          />
                        </div>
                  </div>
                </div>
              </div>
            </div>
    </FilterAccordian>
  );
};

export default Filterform;
