import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import ProfileForm from "./profileForm";
import { useHistory, useParams } from "react-router-dom";
import show_Toast from "../../helpers/toast.helper";
import { getProfileById, getSupplierProfileById } from "../../services";
import CustomLoader from "../../components/common/customLoader/customLoader";
import HelmetComponent from "../../components/common/Helmet";
import { PAGE_TITLES } from "../../constant/pageTitles";

const EditSupplierProfile = () => {
  const history = useHistory();
  const { id } = useParams();
  const [isLoading, setLoading] = useState(false);
  const { type = "" } = useParams();

  const [initialValues, setIntialValues] = useState({
    name: "",
    mobile_number: "",
    account_number: "",
    email: "",
    address: "",
    profile_type: "",
  });

  const getSingleProfile = async (id) => {
    try {
      setLoading(true);
      const { data } = await getSupplierProfileById(id);
      if (data.status === "Success") {
        const {
          name = "",
          mobile_number = "",
          account_number = "",
          email = "",
          address = "",
        } = data?.data?.data;
        setIntialValues({
          name,
          mobile_number,
          account_number,
          email,
          address,
          profile_type: type,
        });
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      show_Toast({
        status: false,
        message:
          error?.response?.data?.message ||
          "Something went wrong please reload the page or contact with admin",
      });
    }
  };

  useEffect(() => {
    if (id) {
      getSingleProfile(id);
    } else {
      history.push("/profiles/suppliers");
    }
  }, [id]);

  return (
    <Fragment>
      <HelmetComponent titleText={PAGE_TITLES["SupplierProfiles"]} />
      <Breadcrumb
        title="Add New Profile"
        parent="Profiles"
        parentlink="Profiles"
      />
      {isLoading ? (
        <CustomLoader />
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mt-2">
                <div className="card-header">
                  <h5>Edit Profile</h5>
                </div>
                <ProfileForm
                  initialValues={initialValues}
                  id={id}
                  type="edit"
                  profileTypes={type}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default EditSupplierProfile;
