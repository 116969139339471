import React, { useEffect, Fragment } from "react";
import { Form, Formik } from "formik";
import InputField from "../../components/form/input/input";
import TextArea from "../../components/form/textarea/input";
import ButtonField from "../../components/form/button";
import { useState } from "react";
import httpRequest from "../../axios";
import { CREATE_PRODUCT, UNIT_CATEGORIES } from "../../constant/apiEndPoints";
import { CreateProductValidations } from "../../utils/validations/products.validations";
import SelectField from "../../components/form/select";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getProductById } from "../../services";
import show_Toast from "../../helpers/toast.helper";
import ProductFrom from "./productFrom";
import Breadcrumb from "../../components/common/breadcrumb";
import CustomLoader from "../../components/common/customLoader/customLoader";
import HelmetComponent from "../../components/common/Helmet";
import { PAGE_TITLES } from "../../constant/pageTitles";

const EditProduct = () => {
  const history = useHistory();
  const { id } = useParams();
  const [attribute, setAttribute] = useState([]);
  const [preSelctedProducts, setPreSelctedProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const [initialValues, setInitialValues] = useState({
    product_name: "",
    code: "",
    gst: "",
    description: "",
    status: "",
    unit_price: "",
    attributes: [
      {
        name: "",
        attribute_value: "",
      },
    ],
    units: [],
    category_unit_id: "",
    selling_unit_id: "",
  });

  const getProductValues = async (id) => {
    setLoading(true);
    try {
      const { data } = await getProductById(id);
      const {
        data: {
          data: { status },
        },
      } = await getProductById(id);
      if (data.status === "Success") {
        const { category_unit_id } = data?.data;
        const {
          attributes,
          code,
          description,
          gst,
          name,
          unit_price,
          units,
          selling_unit_id,
        } = data?.data?.data;
        let preAtt = [];
        let attributeMOdify = attributes?.map((element, i) => {
          preAtt.push(parseInt(element.key_id));
          return {
            name: element.key_id,
            attribute_value: parseInt(element.value_id),
          };
        });
        let UnitModify = units?.map((item) => {
          return {
            ...item,
            value: item.unit_id,
            label: item.name,
            id: item.unit_id,
          };
        });
        setPreSelctedProducts(preAtt);
        setInitialValues({
          attributes: attributeMOdify,
          code,
          description,
          gst,
          name,
          selling_unit_id,
          status: status,
          unit_price,
          units: UnitModify,
          category_unit_id: category_unit_id,
        });
      }
      setLoading(false);
    } catch (error) {
      show_Toast({
        status: false,
        message:
          error?.response?.data?.message ||
          "Something went wrong please reload the page or contact with admin",
      });
    }
  };

  useEffect(() => {
    if (id) {
      getProductValues(id);
    } else {
      history.push("/users");
    }
  }, [id]);

  return (
    <Fragment>
      <HelmetComponent titleText={PAGE_TITLES["Products"]} />
      <Breadcrumb title="Edit User" parent="Users" parentlink="users" />
      {loading ? (
        <CustomLoader />
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mt-2">
                <div className="card-header">
                  <h5 className="text-capitalize">Edit Product</h5>
                </div>
                <ProductFrom
                  id={id}
                  type="edit"
                  initialValues={initialValues}
                  preSelctedProducts={preSelctedProducts}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default EditProduct;
