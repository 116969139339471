import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import { Link, useParams } from "react-router-dom";
import {
  CalculatePayableAmount,
  TotalPriceSingleOrder,
  findConfigurationByKeyPublic,
  formatNumber,
  getAllOrders,
  getCustomerOrders,
} from "../../services";
import show_Toast from "../../helpers/toast.helper";
import CustomLoader from "../../components/common/customLoader/customLoader";
import Search from "../../components/common/search/search";
import { PER_PAGE_API } from "../../constant/pagination";
import { Pagination } from "../../components/common/pagination";
import httpRequest from "../../axios";
import {
  MARK_PAID,
  MARK_RECEIVED,
  UPDATE_ORDERS,
} from "../../constant/apiEndPoints";
import { PAGE_TITLES } from "../../constant/pageTitles";
import Dropdowns from "../../components/common/dropdown/dropdown";
import { Download, Edit, Eye, Edit3, Filter, Divide } from "react-feather";
import html2canvas from "html2canvas";
import html2pdf from "html2pdf.js";
import { BillHtml } from "../../services";
import { useHistory } from "react-router-dom";
import Filterform from "./OrderFilters/filterform";
import { ChevronUp, ChevronDown } from "react-feather";
import { Accordion } from "react-bootstrap";
import { Card, CardBody, Collapse, Button } from "reactstrap";
import { findConfigurationByKey } from "../../services";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ButtonField from "../../components/form/button";
import PaymentForm from "../Payments/createPayment";
import SweetAlert from "sweetalert2";
import {
  HtmlToPdf,
  getValuesofSingleOrder,
  sweetAlertWarning,
} from "../../services/common";
import SelectField from "../../components/form/select";
import PermissionBase from "../../components/common/permissionBase/permissionBase";
import { PERMISSION_NAMES } from "../../constant/routePermissions";
import { hasPermissions } from "../../helpers/permissions.helper";
import { useSelector } from "react-redux";
import HelmetComponent from "../../components/common/Helmet";
import DropdownMenue from "../../components/common/dropdownMenue/dropdownMenue";

const AllOrders = () => {
  const { permissions } = useSelector((state) => state.login);
  const [isLoading, setLoading] = useState(false);
  const [allOrders, setAllOrders] = useState([]);
  const { type } = useParams();
  const history = useHistory();
  const [preSelctedProducts, setPreSelectedProducts] = useState([]);
  const [edit, setEdit] = useState(true);
  const [apply, setApply] = useState(false);
  const [isOpen, setIsOpen] = useState(0);
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
  const [selectAll, setSelectAll] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [markPaid, setMarkPaid] = useState(false);

  const handleCheckboxChange = (event) => {
    const checkboxValue = +event.target?.value;
    const isChecked = event.target.checked;
    if (checkboxValue === -1) {
      setSelectAll(isChecked);
      if (isChecked) {
        const uncheckedOrders = allOrders.filter(
          (order) => order.is_paid !== "YES" || order.is_received !== "YES"
        );
        setCheckboxes([...uncheckedOrders]);
      } else {
        setCheckboxes([]);
      }
    } else {
      if (isChecked) {
        const singleOrder = allOrders.find((item) => item.id == checkboxValue);

        setCheckboxes([...checkboxes, singleOrder]);
      } else {
        const singleOrder = checkboxes.filter(
          (item) => item.id != checkboxValue
        );
        setCheckboxes([...singleOrder]);
        setSelectAll(false);
      }
    }
  };
  const [filters, setFilters] = useState({
    profile: "",
    OrderId: "",
    orderStatus: "",
    isReceived: "ALL",
    isPaid: "ALL",
    paymentMethod: "",
    createdAt: "",
    totalAmount: "",
    from: "",
    to: "",
    tag: "",
  });

  const [name, setName] = useState("");
  const [tel, setTel] = useState("");
  const [termsConditions, setTermsConditions] = useState("");
  const [address, setAddress] = useState("");
  const [profileId, setProfileId] = useState("");
  const [orderId, setOrderId] = useState("");
  const [payable, setPayable] = useState();
  const [transition, setTransition] = useState(false);

  const compantDetails = async () => {
    try {
      const [dataAddress, dataName, dataTel, dataTerms] = await Promise.all([
        findConfigurationByKeyPublic("ADDRESS"),
        findConfigurationByKeyPublic("COMPANY_NAME"),
        findConfigurationByKeyPublic("TELEPHONE"),
        findConfigurationByKey("C_ORDER_TERMS_CONDITIONS"),
      ]);

      setAddress(dataAddress.data.data.value);
      setName(dataName.data.data.value);
      setTel(dataTel.data.data.value);
      setTermsConditions(dataTerms?.data?.data?.value);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    compantDetails();
  }, []);

  const onChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  const [initialValues, setIntialValues] = useState({
    products: [
      {
        name: "",
        price: 0,
        units: [
          {
            id: "",
            value: "",
          },
        ],
      },
    ],
    supplier_id: "",
    order_amount: "",
    payment_method: "",
    is_paid: "",
    is_received: "",
    scheduled_delivery_date: "",
    paid_amount: "",
    discount: "",
  });
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);
  const defDate = currentDate.toISOString().split("T")[0];
  const [initialValuesPayment, setIntialValuesPayment] = useState({
    amount: "",
    description: "",
    deposit_date: new Date().toISOString().split("T")[0],
    payment_method: "CASH",
  });

  const [cachedValues, setCachedValues] = useState({});

  const [searchQuery, setSearchQuery] = useState("");
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
    perPage: 10,
  });

  const getAllOrder = async () => {
    try {
      setLoading(true);
      const { data } =
        type === "supplier"
          ? await getAllOrders(
              paginationData.perPage,
              paginationData.currentPage,
              searchQuery,
              filters
            )
          : await getCustomerOrders(
              paginationData.perPage,
              paginationData.currentPage,
              searchQuery,
              filters
            );
      if (data.status === "Success") {
        setAllOrders(data?.data?.data || []);
        setPaginationData((pre) => ({
          ...pre,
          currentPage: data?.data.current_page,
          totalRecords: data?.data.total,
          perPage: data?.data.per_page,
          totalPages: Math.ceil(data?.data.total / data?.data.per_page),
        }));
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      show_Toast({
        status: false,
        message:
          error?.response?.data?.message ||
          "Something went wrong please reload the page or contact with admin",
      });
    }
  };

  useEffect(() => {
    getAllOrder();
    setCheckboxes([]);
    setSelectAll(false);
  }, [edit, paginationData.currentPage, searchQuery, apply, markPaid]);

  useEffect(() => {
    setPaginationData({
      currentPage: 1,
      totalPages: 0,
      totalRecords: 0,
    });
    setAllOrders([]);
    setSearchQuery("");
    getAllOrder();
    setCheckboxes([]);
    setSelectAll(false);
    setPayable();
    setFilters({
      profile: "",
      OrderId: "",
      orderStatus: "",
      isReceived: "ALL",
      isPaid: "ALL",
      paymentMethod: "",
      createdAt: "",
      totalAmount: "",
      from: "",
      to: "",
      tag: "",
    });
  }, [type]);

  var allProductId = [];
  const getOrderValues = async (id) => {
    try {
      const { data } = await httpRequest.get("orders/" + id);
      if (data?.status === "Success") {
        let order = data?.data || null;
        if (order && order?.data?.length) {
          let products = order?.data?.map((item) => {
            let units;
            if (item.requested_stock.length) {
              units = item.requested_stock.map((stocks) => ({
                id: stocks.unit_id,
                value: parseInt(stocks.stock),
              }));
            }
            allProductId.push(parseInt(item.product_id));
            return {
              name: item.product_id,
              price: item.product_cost,
              units: units,
            };
          });
          setPreSelectedProducts(allProductId);
          setIntialValues({
            ...initialValues,
            ...order,
            // is_received: order.is_received == "YES" ? 1 : 0,
            products: products,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickOrderRecieved = async (orderId) => {
    var result = toast.success("Order is Fully Recieved", {
      position: toast.POSITION.TOP_CENTER,
    });
    if (result) {
      await getOrderValues(orderId);
      let setValuesForApi = { ...initialValues };
      let modifyProducts = setValuesForApi.products.map((item) => {
        let stocks = [];
        if (item.units.length) {
          stocks = item.units.map((uni) => ({
            unit_id: uni.id,
            stock: uni.value,
          }));
        }
        return {
          product_id: item.name,
          product_cost: item.price,
          requested_stock: stocks,
        };
      });
      var { data } = await httpRequest.put(UPDATE_ORDERS + "/" + orderId, {
        ...setValuesForApi,
        products: modifyProducts,
        is_received: "YES",
      });

      setEdit((prevState) => !prevState);
    } else {
      console.log("Cancelled!");
    }
  };

  const downloadBillPdf = async (pid) => {
    const values =
      cachedValues[pid] || (await getValuesofSingleOrder(pid, type));
    const customer =
      type === "customer"
        ? values?.customer?.data?.name
        : values?.supplier?.data?.name;
    const {
      id,
      created_at,
      total_order_amount,
      payment_method,
      description,
      tag,
      data,
      addOns,
      product_cost,
    } = values;

    const timestamp = new Date(created_at);

    const formattedDate = timestamp.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    const formattedTime = timestamp.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });

    const formattedDateTime = `${formattedDate} ${formattedTime}`;
    const html = BillHtml(
      id,
      formattedDateTime,
      total_order_amount,
      payment_method,
      name,
      tel,
      termsConditions,
      address,
      description,
      tag,
      data,
      customer,
      addOns
    );
    const element = document.createElement("div");
    element.innerHTML = html;

    const elementCopy = element.cloneNode(true);
    elementCopy.style.position = "absolute";
    elementCopy.style.left = "-9999px";
    document.body.appendChild(elementCopy);

    const divHeight = elementCopy.offsetHeight;
    elementCopy.remove();
    const height = divHeight / 96 + (divHeight <= 5 ? 0.5 : 1.5);

    html2pdf()
      .set({
        scale: 0.5,
        filename: "bill.pdf",
        margin: [0, 0],
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: {
          format: [4, height],
          autoPaging: false,
          orientation: "portrait",
          unit: "in",
          hotfixes: ["px_scaling"],
        },
      })
      .from(element)
      .save("bill.pdf");
  };

  const viewBillPdf = async (pid) => {
    const values =
      cachedValues[pid] || (await getValuesofSingleOrder(pid, type));

    const customer =
      type === "customer"
        ? values?.customer?.data?.name
        : values?.supplier?.data?.name;

    const {
      id,
      created_at,
      total_order_amount,
      payment_method,
      data,
      description,
      tag,
      addOns,
    } = values;
    const timestamp = new Date(created_at);
    const formattedDate = timestamp.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    const formattedTime = timestamp.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });

    const formattedDateTime = `${formattedDate} ${formattedTime}`;
    const html = BillHtml(
      id,
      formattedDateTime,
      total_order_amount,
      payment_method,
      name,
      tel,
      termsConditions,
      address,
      description,
      tag,
      data,
      customer,
      addOns
    );

    HtmlToPdf(html);
  };

  const handlerAfterSubmitted = () => {
    setProfileId("");
    setOrderId("");
    getAllOrder();
  };

  useEffect(() => {
    setProfileId("");
    setOrderId("");
    setTransition(false);
    setPayable();
  }, [type]);

  const MarkAsPaid = async (values) => {
    const payload = {
      order_id: values.map((item) => item.id),
    };
    const { data } = await httpRequest.post(
      type === "customer"
        ? "customerOrders/" + MARK_PAID
        : "orders/" + MARK_PAID,
      payload
    );
    if (data.status === "Success") {
      setMarkPaid((pre) => !pre);
    }
    return data;
  };

  const MarkAsRecived = async (values) => {
    const payload = {
      order_id: values.map((item) => item.id),
    };

    const { data } = await httpRequest.post(
      type === "customer"
        ? "customerOrders/" + MARK_RECEIVED
        : "orders/" + MARK_RECEIVED,
      payload
    );
    if (data.status === "Success") {
      setMarkPaid((pre) => !pre);
    }
    return data;
  };

  const handleClickPay = async (checkboxes) => {
    let Ids = "";
    checkboxes.forEach((item, index) => {
      if (item.is_paid !== "YES") {
        Ids += item.id;
        if (index !== item.length - 1) {
          Ids += ",";
        }
      }
    });

    try {
      const responseData = await sweetAlertWarning(
        "Are you sure?",
        `Once paid, you will not be able to recover this amount! Order ids=[${Ids}]`,
        () => MarkAsPaid(checkboxes.filter((item) => item.is_paid !== "YES"))
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickRecived = async (checkboxes) => {
    let Ids = "";
    checkboxes.forEach((item, index) => {
      if (item.is_received !== "YES") {
        Ids += item.id;
        if (index !== item.length - 1) {
          Ids += ",";
        }
      }
    });
    try {
      const responseData = await sweetAlertWarning(
        "Are you sure?",
        `Once received, you will not be able to recover this amount! Order ids=[${Ids}]`,
        () =>
          MarkAsRecived(checkboxes.filter((item) => item.is_received !== "YES"))
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Fragment>
      <HelmetComponent
        titleText={
          type === "customer"
            ? PAGE_TITLES["CustomerOrders"]
            : PAGE_TITLES["SupplierOrders"]
        }
      />

      <Breadcrumb title="Order List" parent="Orders" parentlink="Orders" />

      {isLoading ? (
        <CustomLoader />
      ) : (
        <>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div
                  className={`card mt-2 section ${
                    transition ? "show-make-payment" : "hide"
                  }`}
                >
                  <PaymentForm
                    initialValues={initialValuesPayment}
                    profileTypes={type}
                    profileId={profileId}
                    orderId={orderId}
                    type={"add"}
                    handlerAfterSubmitted={handlerAfterSubmitted}
                    setTransition={setTransition}
                    payable={payable}
                    setProfileId={setProfileId}
                    setOrderId={setOrderId}
                    setPayable={setPayable}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card mt-2">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="text-capitalize">{`${
                      type === "customer"
                        ? "Customer Orders"
                        : "Supplier Orders"
                    }`}</h5>
                    <PermissionBase
                      permissionName={
                        type === "customer"
                          ? PERMISSION_NAMES["create_customer_orders"]
                          : PERMISSION_NAMES["create_supplier_orders"]
                      }
                    >
                      <Link
                        className="btn btn-success"
                        to={`/order/${type}/add`}
                      >
                        Add New Orders
                      </Link>
                    </PermissionBase>
                  </div>
                  <div className="card-body">
                    <div className="mb-3 d-flex justify-content-end gap-4">
                      {checkboxes.length >= 1 && (
                        <div>
                          <SelectField
                            className={"form-control digits"}
                            name="markas"
                            selectLabel="Mark as"
                            onChange={(e) => {
                              if (e.target.value === "paid") {
                                handleClickPay(checkboxes);
                              } else if (e.target.value === "received") {
                                handleClickRecived(checkboxes);
                              }
                            }}
                            options={[
                              {
                                disabled: checkboxes.filter(
                                  (item) => item.is_paid !== "YES"
                                )?.length
                                  ? false
                                  : true,
                                id: "paid",
                                name: `Paid (${
                                  checkboxes.filter(
                                    (item) => item.is_paid !== "YES"
                                  )?.length
                                })`,
                                givenPermission: [
                                  type === "customer"
                                    ? "mark_paid_customer_orders"
                                    : "mark_paid_supplier_orders",
                                ],
                              },
                              {
                                disabled: checkboxes.filter(
                                  (item) => item.is_received !== "YES"
                                )?.length
                                  ? false
                                  : true,
                                id: "received",
                                name: `Receviced (${
                                  checkboxes.filter(
                                    (item) => item.is_received !== "YES"
                                  )?.length
                                })`,
                                givenPermission: [
                                  type === "customer"
                                    ? "mark_recieved_customer_orders"
                                    : "mark_recieved_supplier_orders",
                                ],
                              },
                            ].filter((option) =>
                              hasPermissions(
                                permissions,
                                option.givenPermission
                              )
                            )}
                          />
                        </div>
                      )}
                      <Search
                        classes="form-control search-input"
                        placeholder="Search"
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                          setPaginationData((pre) => ({
                            ...pre,
                            currentPage: 1,
                          }));
                        }}
                        value={searchQuery}
                      />
                    </div>

                    <Filterform
                      type={type}
                      filters={filters}
                      setFilters={setFilters}
                      setApply={setApply}
                      onChange={onChange}
                    />

                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <PermissionBase
                              permissionName={
                                type === "customer"
                                  ? [
                                      "mark_recieved_customer_orders",
                                      "mark_paid_customer_orders",
                                    ]
                                  : [
                                      "mark_recieved_supplier_orders",
                                      "mark_paid_supplier_orders",
                                    ]
                              }
                            >
                              <th scope="col">
                                <input
                                  className="checkbox_animated"
                                  type="checkbox"
                                  value={-1}
                                  checked={selectAll}
                                  onChange={handleCheckboxChange}
                                />
                              </th>
                            </PermissionBase>
                            <th scope="col">Order Id</th>
                            <th scope="col">Order Amount</th>
                            <th scope="col">Pending Amount</th>
                            <th scope="col">Payment Method</th>
                            <th scope="col">Order Date</th>
                            <th scope="col">Paid</th>
                            <th scope="col">Recieved</th>
                            <th scope="col">Tag</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allOrders.length
                            ? allOrders?.map((order, ind) => (
                                <tr key={ind}>
                                  <PermissionBase
                                    permissionName={
                                      type === "customer"
                                        ? [
                                            "mark_recieved_customer_orders",
                                            "mark_paid_customer_orders",
                                          ]
                                        : [
                                            "mark_recieved_supplier_orders",
                                            "mark_paid_supplier_orders",
                                          ]
                                    }
                                  >
                                    <td>
                                      {JSON.parse(
                                        checkboxes.includes(order.id)
                                      )}
                                      <input
                                        type="checkbox"
                                        className="checkbox_animated"
                                        value={order.id}
                                        checked={checkboxes?.some(
                                          (item) => item.id == order.id
                                        )}
                                        onChange={handleCheckboxChange}
                                        disabled={
                                          order?.is_paid === "YES" &&
                                          order?.is_received === "YES"
                                        }
                                      />
                                    </td>
                                  </PermissionBase>
                                  <td>{order.id}</td>
                                  <td>
                                    {formatNumber(order.total_order_amount)}
                                  </td>
                                  <td>
                                    {Math.floor(order?.pending_amount * 100) /
                                      100}
                                  </td>
                                  <td>{order.payment_method}</td>
                                  <td>
                                    {new Date(
                                      order?.created_at
                                    ).toLocaleDateString("en-US", {
                                      year: "numeric",
                                      month: "long",
                                      day: "numeric",
                                    })}
                                  </td>
                                  <td>
                                    {order.is_paid === "YES" ? "Yes" : "No"}
                                  </td>

                                  <td>
                                    {order.is_received === "YES" ? "Yes" : "No"}
                                  </td>
                                  <td>{order.tag}</td>
                                  <td className="d-flex align-items-center">
                                    <PermissionBase
                                      permissionName={
                                        type === "customer"
                                          ? [
                                              "read_customer_orders",
                                              "update_customer_orders",
                                            ]
                                          : [
                                              "read_supplier_orders",
                                              "update_supplier_orders",
                                            ]
                                      }
                                    >
                                      <Link
                                        to={`/order/${type}/edit/${order.id}`}
                                      >
                                        <Edit size={18} color="#4466F2" />
                                      </Link>
                                    </PermissionBase>
                                    <PermissionBase
                                      permissionName={
                                        type === "customer"
                                          ? ["read_customer_orders"]
                                          : ["read_supplier_orders"]
                                      }
                                    >
                                      <button
                                        className={"border-0 bg-transparent"}
                                        onClick={() => {
                                          downloadBillPdf(order.id);
                                        }}
                                      >
                                        <Download size={18} color="#4466F2" />
                                      </button>
                                      <button
                                        className={"border-0 bg-transparent"}
                                        onClick={() => {
                                          viewBillPdf(order.id);
                                        }}
                                      >
                                        <Eye size={18} color="#4466F2" />
                                      </button>
                                    </PermissionBase>
                                    {type === "supplier" && (
                                      <div className="d-flex align-items-center ms-1">
                                        {/* <div>|</div>
                                        <Link
                                          className="mx-1"
                                          to={`/order/${type}/edit/${order.id}?order=partial`}
                                        >
                                          Partial Order
                                        </Link> */}
                                        {/* <div>|</div> */}
                                        <PermissionBase
                                          permissionName={
                                            type === "customer"
                                              ? [
                                                  "mark_recieved_customer_orders",
                                                ]
                                              : [
                                                  "mark_recieved_supplier_orders",
                                                ]
                                          }
                                        >
                                          <a
                                            className={`ms-2 ${
                                              order.is_received === "YES"
                                                ? "text-muted pe-none"
                                                : ""
                                            }`}
                                            href="#"
                                            onClick={() =>
                                              handleClickRecived([order])
                                            }
                                          >
                                            Full Order
                                          </a>
                                        </PermissionBase>
                                      </div>
                                    )}
                                    <td>
                                      <DropdownMenue
                                        dropdownList={[
                                          {
                                            name: "Mark Paid",
                                            givenPermissions:
                                              type === "customer"
                                                ? ["mark_paid_customer_orders"]
                                                : ["mark_paid_supplier_orders"],
                                            onclick: () =>
                                              handleClickPay([order]),
                                            disabled: order.is_paid === "YES",
                                          },
                                          {
                                            name: "Make Payment",
                                            givenPermissions: [
                                              "create_transaction",
                                            ],
                                            onclick: () => {
                                              setProfileId(order.profile_id);
                                              setOrderId(order.id);
                                              setPayable(
                                                Math.floor(
                                                  order?.pending_amount * 100
                                                ) / 100
                                              );
                                              setTransition(true);
                                              window.scrollTo(0, 0);
                                            },
                                            disabled: order.is_paid === "YES",
                                          },
                                          {
                                            name: "Return Order",
                                            link: `/order/${type}/return/`,
                                            givenPermissions:
                                              type === "customer"
                                                ? [
                                                    "create_return_order_by_customer",
                                                  ]
                                                : [
                                                    "create_return_order_by_supplier",
                                                  ],
                                            disabled:
                                              order?.is_return === "FULL",
                                          },
                                        ]}
                                        id={order.id}
                                      />
                                    </td>
                                  </td>
                                </tr>
                              ))
                            : null}
                        </tbody>
                      </table>
                      <div className="mt-4 mb-1">
                        <Pagination
                          selectedPage={paginationData.currentPage}
                          pageCount={paginationData.totalPages}
                          onPageChangeHandler={(page) => {
                            setPaginationData({
                              ...paginationData,
                              currentPage: page,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Fragment>
  );
};

export default AllOrders;
