import React, { useEffect, useState } from "react";
import ButtonField from "../../components/form/button";
import InputField from "../../components/form/input/input";
import SearchSelectField from "../../components/form/select/SearchSelect";
import FilterAccordian from "../../components/common/Filter/filter";
import { useSelector } from "react-redux";
import { ProfileType, StockInOutOptions } from "../../constant";

const Filterform = ({
  filters,
  onChange,
  onChangeOption,
  onFilterApply,
  onResetApply,
}) => {
  const [profilesList, setProfilesList] = useState();
  const { customerProfiles, supplierProfiles } = useSelector(
    (state) => state.profiles
  );

  useEffect(() => {
    setProfilesList([...customerProfiles, ...supplierProfiles]);
  }, []);

  return (
    <FilterAccordian>
      <div className="border-top p-3">
        <div className="row">
          <div className="row">
            <div className="col-2">
              <InputField
                type="number"
                label="Order Id"
                placeholder={"Order Id"}
                classes={"mb-0"}
                name="order_id"
                value={filters.order_id}
                onChange={(e) => onChange(e)}
              />
            </div>
            <div className="col-2">
              <label htmlFor="" className="text-capitalize">
                Profile
              </label>
              <SearchSelectField
                className={"inputclass rounded flex-grow-1"}
                name="profile_id"
                value={filters.profile_id}
                valueKey="id"
                labelKey="name"
                onChange={(e) => onChangeOption("profile_id", e.id)}
                options={profilesList}
              />
            </div>
            <div className="col-2">
              <label htmlFor="" className="text-capitalize">
                Type
              </label>
              <SearchSelectField
                className={"inputclass rounded flex-grow-1"}
                valueKey="value"
                labelKey="name"
                value={filters.type}
                name={"type"}
                onChange={(e) => onChangeOption("type", e.value)}
                options={StockInOutOptions}
              />
            </div>
            <div className="col-2">
              <label className="text-capitalize">Profile Type</label>
              <SearchSelectField
                className={"inputclass rounded"}
                name="profile_type"
                value={filters.profile_type}
                valueKey="value"
                labelKey="label"
                onChange={(e) => {
                  onChangeOption("profile_type", e.value);
                }}
                options={ProfileType}
              />
            </div>
            <div className="col-2">
              <div className="d-flex align-items-center gap-2 h-100">
                <ButtonField
                  className="btn btn-danger"
                  buttonText="Clear"
                  onClick={onResetApply}
                />
                <ButtonField
                  buttonText="Apply"
                  type="submit"
                  onClick={onFilterApply}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </FilterAccordian>
  );
};

export default Filterform;
