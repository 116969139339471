import { Form, Formik } from "formik";
import InputField from "../../components/form/input/input";

import ButtonField from "../../components/form/button";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUnitCategoryService } from "../../services";
import httpRequest from "../../axios";
import {
  CREATE_CONFIGRATIONS,
  UPDATE_CONFIGRATIONS,
} from "../../constant/apiEndPoints";
import show_Toast from "../../helpers/toast.helper";
import { UnitValidations } from "../../utils/validations/units.validations";
import CustomLoader from "../../components/common/customLoader/customLoader";

import { useSelector } from "react-redux";
import { hasPermissions } from "../../helpers/permissions.helper";
import { PERMISSION_NAMES } from "../../constant/routePermissions";
import { Route, Redirect } from "react-router-dom";

import SimpleMDE from "react-simplemde-editor";
import CKEditors from "react-ckeditor-component";

const ConfigurationForm = ({
  initialValues = {
    name: "",
    // key: "",
    value: "",
  },
  setTransition,
  type = "add",
  formSuccessHandler = () => {},
  editId = "",
  setIntialValues,
}) => {
  const [content, setContent] = useState("<Strong><i>content</i></strong>");
  const [isLoading, setLoading] = useState(false);
  const { permissions } = useSelector((state) => state.login);
  const createConfiguration = async (values) => {
    setLoading(true);
    try {
      if (type === "add") {
        var { data } = await httpRequest.post(CREATE_CONFIGRATIONS, values);
      } else {
        var { data } = await httpRequest.put(
          UPDATE_CONFIGRATIONS + "/" + editId,
          values
        );
      }

      setLoading(false);
      show_Toast({
        status: true,
        message:
          data.status === "Success" && type === "edit"
            ? "Configuration Updated successfully"
            : "Configuration created successfully",
      });
      formSuccessHandler();
      setTransition(false);
      setIntialValues({
        name: "",
        key: "",
        value: "",
      });
    } catch (error) {
      setTransition(false);

      setLoading(false);
      show_Toast({
        status: false,
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        if (
          !hasPermissions(
            permissions,
            PERMISSION_NAMES[("create_configuration", "update_configuration")]
          )
        ) {
          return <Redirect to={{ pathname: "/not-allowed" }} />;
        }
        createConfiguration(values, setSubmitting);
      }}
      validationSchema={UnitValidations}
      enableReinitialize
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          resetForm,
          isSubmitting,
          setFieldValue,
        } = props;
        return (
          <>
            {isLoading ? (
              <div>
                <CustomLoader />
              </div>
            ) : (
              <Form className={`form theme-form`}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4">
                      <InputField
                        placeholder="Name"
                        label="name"
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={
                          errors.name && touched.name && errors.name
                        }
                        error={errors.name && touched.name ? true : false}
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4">
                      {editId !== 13 ? (
                        <div>
                          <InputField
                            placeholder="Value"
                            label="value"
                            type="text"
                            name="value"
                            value={values.value}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            errorMessage={
                              errors.value && touched.value && errors.value
                            }
                            error={errors.value && touched.value ? true : false}
                          />
                        </div>
                      ) : (
                        <div style={{ height: "350px", width: "580px" }}>
                          <label>Value:</label>
                          <CKEditors
                            activeclassName="custom-ckeditor"
                            content={values.value}
                            events={{
                              change: (evt) => {
                                const newContent = evt.editor.getData();
                                setFieldValue("value", newContent);
                              },
                            }}
                          />
                        </div>
                      )}
                    </div>

                    <div className="col-lg-12 col-sm-6 col-md-12 d-flex justify-content-end align-items-center gap-2">
                      <ButtonField
                        type="button"
                        className="btn btn-red"
                        onClick={() => {
                          setTransition(false);
                          resetForm();
                        }}
                        buttonText="Cancel"
                      />
                      <ButtonField
                        className={"btn btn-primary me-2"}
                        buttonText={type === "edit" ? "Update" : "Create"}
                        type="submit"
                        onClick={() => {
                          createConfiguration(values);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </>
        );
      }}
    </Formik>
  );
};

export default ConfigurationForm;
