import React, { useEffect } from "react";
import { calculateGST } from "../../../services";

const OrderAmount = ({ totalOrderAmount, products }) => {
  // Ensure products is an array and contains valid discount values
  const validDiscounts = products
    ?.map((item, index) => Number(item.discount))
    .filter((value) => !isNaN(value));

  // Calculate the total discount
  // const AllProductDiscount =
  //   validDiscounts.length > 0
  //     ? validDiscounts.reduce((sum, value) => sum + value, 0)
  //     : 0;

  return (
    <h3>
      Total:
      {!isNaN(totalOrderAmount) //+ AllProductDiscount
        ? totalOrderAmount //+ AllProductDiscount
        : 0}
    </h3>
  );
};

export default OrderAmount;
