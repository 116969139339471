import { Form, Formik } from "formik";
import InputField from "../../components/form/input/input";
import SelectField from "../../components/form/select";
import ButtonField from "../../components/form/button";
import { AddPermission } from "../../constant";
import {
  createUserValidation,
  editUserValidation,
} from "../../utils/validations/auth.validations";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { getRolesService } from "../../services";
import httpRequest from "../../axios";
import { CREATE_USER, USERS } from "../../constant/apiEndPoints";
import show_Toast from "../../helpers/toast.helper";
import CustomLoader from "../../components/common/customLoader/customLoader";

const UserForm = ({
  initialValues = {
    name: "",
    username: "",
    email: "",
    password: "",
    userRole: "",
    status: "",
  },
  type = "add",
  id,
}) => {
  const history = useHistory();

  const [rolesList, setRolesList] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const getRolesList = async () => {
    try {
      const {
        data: { data },
      } = await getRolesService();
      setRolesList(data?.data);
    } catch (error) {
      show_Toast({
        status: false,
        message:
          error?.response?.data?.message ||
          "Something went wrong please reload the page or contact with admin",
      });
    }
  };

  useEffect(() => {
    getRolesList();
  }, []);

  const createUser = async (values, setSubmitting) => {
    setLoading(true);
    try {
      const { data } = await httpRequest.post(
        type === "edit" ? USERS + "/" + id : CREATE_USER,
        {
          ...values,
          name: values.name,
          username: values.username,
          role_id: values.userRole,
          ...(type === "add" && { status: "active" }),
        }
      );
      setSubmitting(false);
      setLoading(false);
      show_Toast({
        status: true,
        message:
          type === "edit"
            ? "User Updated successfully"
            : "User created successfully",
      });
      history.push("/users");
    } catch (error) {
      setSubmitting(false);
      setLoading(false);
      show_Toast({
        status: false,
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };

  const validateMaxLength = (value) => {
    if (value && value.length > 52) {
      return "Maximum length exceeded.";
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        createUser(values, setSubmitting);
      }}
      validationSchema={
        type === "add" ? createUserValidation : editUserValidation
      }
      enableReinitialize
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          resetForm,
          isSubmitting,
        } = props;

        return (
          <Form className="form theme-form">
            {isLoading ? (
              <div className="card-body">
                <CustomLoader />
              </div>
            ) : (
              <>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <InputField
                        placeholder="Name"
                        label="name"
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={52}
                        errorMessage={
                          errors.name && touched.name && errors.name
                        }
                        error={errors.name && touched.name ? true : false}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputField
                        placeholder="Username"
                        label="username"
                        type="text"
                        name="username"
                        value={values.username}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={52}
                        errorMessage={
                          errors.username && touched.username && errors.username
                        }
                        error={
                          errors.username && touched.username ? true : false
                        }
                      />
                    </div>
                    <div className="col-md-6">
                      <InputField
                        placeholder="Email"
                        label="email"
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={
                          errors.email && touched.email && errors.email
                        }
                        error={errors.email && touched.email ? true : false}
                        disabled={type === "edit"}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputField
                        placeholder="Password"
                        label="Password"
                        type="password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        maxLength={52}
                        onBlur={handleBlur}
                        errorMessage={
                          errors.password && touched.password && errors.password
                        }
                        error={
                          errors.password && touched.password ? true : false
                        }
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleFormControlSelect9">
                          Assign Role
                        </label>
                        <SelectField
                          className={"form-control digits"}
                          name="userRole"
                          value={values.userRole}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          options={rolesList}
                          errorMessage={errors.userRole}
                        />
                      </div>
                    </div>
                    {type === "edit" && (
                      <div className="col-md-6">
                        <label htmlFor="exampleFormControlSelect9">
                          Status
                        </label>
                        <SelectField
                          className={"form-control digits"}
                          labelKey={"title"}
                          valueKey={"title"}
                          name="status"
                          value={values.status}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          options={[
                            { id: 1, title: "ACTIVE", value: "active" },
                            { id: 2, title: "INACTIVE", value: "inactive" },
                          ]}
                          errorMessage={
                            errors.status && touched.status && errors.status
                          }
                          error={errors.status && touched.status ? true : false}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="card-footer d-flex justify-content-end gap-2">
                  <ButtonField
                    className={"btn btn-red"}
                    buttonText={`Reset`}
                    type="button"
                    onClick={() => resetForm()}
                  />
                  <ButtonField
                    className={"btn btn-primary"}
                    buttonText={type === "edit" ? "Update" : "Create"}
                    // disabled={isSubmitting}
                  />
                </div>
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default UserForm;
