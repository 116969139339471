import React, { Fragment, useEffect, useState, useRef } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import { Link } from "react-router-dom";
import {
  getUnitCategoryService,
  getUnitsService,
  getUsersService,
} from "../../services";
import show_Toast from "../../helpers/toast.helper";
import CustomLoader from "../../components/common/customLoader/customLoader";
import UnitForm from "./form";
import "./index.scss";
import Search from "../../components/common/search/search";
import { Pagination } from "../../components/common/pagination";
import { Edit } from "react-feather";
import UnitFilters from "./UnitFilters";
import PermissionBase from "../../components/common/permissionBase/permissionBase";
import { PAGE_TITLES } from "../../constant/pageTitles";
import HelmetComponent from "../../components/common/Helmet";
const UnitsListing = () => {
  const [unitsList, setUnitsList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const formRef = useRef(null);
  const [apply, setApply] = useState(false);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    name: "",
    symbol: "",
    value: "",
    unit_category_id: "",
    is_base_unit: "all",
  });
  const onChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
    perPage: 10,
  });
  const [transition, setTransition] = useState(false);
  const [editId, setEditid] = useState("");
  const [formType, setFormType] = useState("add");
  const [unitCategoriesList, setUnitCategoriesList] = useState([]);
  const [initialValues, setIntialValues] = useState({
    name: "",
    symbol: "",
    value: "",
    unit_category_id: "",
  });

  const getUnitsListing = async () => {
    try {
      setLoading(true);
      const { data } = await getUnitsService(
        paginationData.perPage,
        paginationData.currentPage,
        search,
        filters
      );
      if (data.status === "Success") {
        setUnitsList(data?.data?.data);
      }
      setPaginationData((pre) => ({
        ...pre,
        currentPage: data?.data?.current_page,
        totalRecords: data?.data?.total,
        perPage: data?.data?.per_page,
        totalPages: Math.ceil(data?.data?.total / data?.data?.per_page),
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      show_Toast({
        status: false,
        message:
          error?.response?.data?.message ||
          "Something went wrong please reload the page or contact with admin",
      });
    }
  };

  const getUnitCategories = async () => {
    // getUnitCategoryService
    try {
      const { data } = await getUnitCategoryService();
      setUnitCategoriesList(data?.data?.data);
    } catch (error) {
      show_Toast({
        status: false,
        message:
          error?.response?.data?.message ||
          "Something went wrong please reload the page or contact with admin",
      });
    }
  };
  useEffect(() => {
    getUnitsListing();
    getUnitCategories();
  }, [paginationData.currentPage, search, apply]);

  const formSuccessHandler = () => {
    getUnitsListing();
  };

  const handleAddUnit = () => {
    setFormType("add");
    setTransition(true);
    setIntialValues({
      name: "",
      symbol: "",
      value: "",
      unit_category_id: "",
    });
  };

  const handleEditUnit = (unit) => {
    setEditid(unit.id);
    setIntialValues({
      ...initialValues,
      name: unit.name,
      symbol: unit.symbol,
      value: unit.value,
      unit_category_id: unit?.unit_category?.id,
    });
    setFormType("edit");
  };

  return (
    <Fragment>
      <HelmetComponent titleText={PAGE_TITLES["Units"]} />
      <Breadcrumb parent="Units" title="listing" parentlink="users" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div
              className={`card mt-2 section ${
                transition ? "show-units" : "hide"
              }`}
              ref={formRef}
            >
              <UnitForm
                type={formType}
                initialValues={initialValues}
                formSuccessHandler={formSuccessHandler}
                editId={editId}
                setTransition={setTransition}
                formRef={formRef}
              />
            </div>
          </div>
        </div>
        {isLoading ? (
          <CustomLoader />
        ) : (
          <div className={`row`}>
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between items-center">
                  <h5 className="text-capitalize">{"All Units"}</h5>

                  <PermissionBase permissionName={["create_units"]}>
                    <button className="btn btn-success" onClick={handleAddUnit}>
                      Add Unit
                    </button>
                  </PermissionBase>
                </div>
                <div className="card-body">
                  <div className="mb-3 d-flex justify-content-end">
                    <Search
                      classes="form-control search-input"
                      placeholder="Search"
                      onChange={(e) => {
                        setSearch(e.target.value);
                        setPaginationData((pre) => ({
                          ...pre,
                          currentPage: 1,
                        }));
                      }}
                      value={search}
                    />
                  </div>
                  <div>
                    <UnitFilters
                      filters={filters}
                      setFilters={setFilters}
                      setApply={setApply}
                      onChange={onChange}
                      unitCategoriesList={unitCategoriesList}
                    />
                  </div>

                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Id</th>
                          <th scope="col">Name</th>
                          <th scope="col">Symbol</th>
                          <th scope="col">Value</th>
                          <th scope="col">Category</th>
                          <th scope="col">Base Unit</th>
                          <PermissionBase permissionName={["update_units"]}>
                            <th scope="col">Action</th>
                          </PermissionBase>
                        </tr>
                      </thead>

                      <tbody>
                        {unitsList.length ? (
                          unitsList?.map((unit, ind) => (
                            <tr key={ind}>
                              <td>{unit?.id}</td>
                              <td>{unit?.name}</td>
                              <td>{unit?.symbol}</td>
                              <td>{unit?.value}</td>
                              <td>{unit?.unit_category?.title}</td>
                              <td>{unit?.is_base_unit ? "Yes" : "No"}</td>

                              <PermissionBase permissionName={["update_units"]}>
                                <td>
                                  <span
                                    className="pointer-cursor"
                                    onClick={() => {
                                      handleEditUnit(unit);
                                      setTransition(true);
                                      window.scrollTo({
                                        top: formRef.current.offsetTop,
                                        behavior: "smooth",
                                      });
                                    }}
                                  >
                                    <Edit size={20} color="green" />
                                  </span>
                                </td>
                              </PermissionBase>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6">no data found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <div className="mt-4 mb-1">
                      <Pagination
                        selectedPage={paginationData.currentPage}
                        pageCount={paginationData.totalPages}
                        onPageChangeHandler={(page) => {
                          setPaginationData({
                            ...paginationData,
                            currentPage: page,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default UnitsListing;
