import { createSlice } from "@reduxjs/toolkit";
import { Home } from "react-feather";

const initialState = {
  attributeMenus: [],
  unitsMenus: [],
};

const menusReducer = createSlice({
  name: "menu",
  initialState: initialState,
  reducers: {
    setAttributeMenus: (state, action) => {
      state.attributeMenus = action.payload;
    },
    setUnitsMenus: (state, action) => {
      state.unitsMenus = action.payload;
    },
  },
});

export const { setAttributeMenus, setUnitsMenus } = menusReducer.actions;
export default menusReducer.reducer;
