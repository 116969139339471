import React, { Fragment, useEffect, useState, useRef } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import { Link } from "react-router-dom";
import { getTransactionServices } from "../../services";
import show_Toast from "../../helpers/toast.helper";
import CustomLoader from "../../components/common/customLoader/customLoader";
import FilterForm from "./filterform";
// import "./index.scss";
import Search from "../../components/common/search/search";
import { Pagination } from "../../components/common/pagination";
import { PER_PAGE_API } from "../../constant/pagination";
import { Edit, Filter, ChevronDown, ChevronUp } from "react-feather";
import InputField from "../../components/form/input/input";
import ButtonField from "../../components/form/button";
import { Accordion } from "react-bootstrap";
import { Card, CardBody, Collapse, Button } from "reactstrap";
import PermissionBase from "../../components/common/permissionBase/permissionBase";
import { PAGE_TITLES } from "../../constant/pageTitles";
import HelmetComponent from "../../components/common/Helmet";
import { ProfileType } from "../../constant";
import SearchSelectField from "../../components/form/select/SearchSelect";

const TransactionListing = () => {
  const [isLoading, setLoading] = useState(false);
  const [formDate, setFormDate] = useState("");
  const formRef = useRef(null);

  const [search, setSearch] = useState("");
  const [apply, setApply] = useState(false);
  const [filters, setFilters] = useState({
    date: "",
    profileType: "ALL",
  });
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
    perPage: 10,
  });

  const [transactionList, setTransactionList] = useState([]);

  const getTransactionListing = async () => {
    try {
      setLoading(true);
      const { data } = await getTransactionServices(
        paginationData.perPage,
        paginationData.currentPage,
        filters,
        search
      );
      if (data.status === "Success") {
        setTransactionList(data?.data?.data);
      }
      setPaginationData((pre) => ({
        ...pre,
        currentPage: data?.data?.current_page,
        totalRecords: data?.data?.total,
        perPage: data?.data?.per_page,
        totalPages: Math.ceil(data?.data?.total / data?.data?.per_page),
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      show_Toast({
        status: false,
        message:
          error?.response?.data?.message ||
          "Something went wrong please reload the page or contact with admin",
      });
    }
  };

  useEffect(() => {
    getTransactionListing();
  }, [paginationData.currentPage, search, apply]);

  const clearFilter = () => {
    setPaginationData((prevState) => ({
      ...prevState,
      currentPage: 1,
    }));
    setFilters({
      date: "",
      profileType: "ALL",
    });
    getTransactionListing();
  };

  const filterApply = () => {
    getTransactionListing();
  };

  const onChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  return (
    <Fragment>
      <HelmetComponent titleText={PAGE_TITLES["Transaction"]} />
      {isLoading ? (
        <CustomLoader />
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12"></div>
          </div>
          <div className={`row`}>
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between items-center">
                  <h5 className="text-capitalize">{"Transactions"}</h5>
                </div>
                <div className="card-body">
                  <div className="mb-3 d-flex justify-content-end">
                    <Search
                      classes="form-control search-input"
                      placeholder="Search"
                      onChange={(e) => {
                        setSearch(e.target.value);
                        setPaginationData((pre) => ({
                          ...pre,
                          currentPage: 1,
                        }));
                      }}
                      value={search}
                    />
                  </div>
                  <FilterForm
                    filters={filters}
                    onChange={onChange}
                    onResetApply={clearFilter}
                    onFilterApply={filterApply}
                    profileType={ProfileType}
                  />
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Order Id</th>
                          <th scope="col">Type</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Payment Method</th>
                          <th scope="col">Deposite Date</th>
                          <th scope="col">Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactionList?.length ? (
                          transactionList?.map((trans, index) => (
                            <tr key={index}>
                              <td>{trans.order_id}</td>
                              <td>{trans.type}</td>
                              <td>{trans.amount}</td>
                              <td>{trans.transaction_type.payment_method}</td>
                              <td>{trans.deposit_date}</td>
                              <td>
                                {trans.type === "RETURN-ORDER"
                                  ? JSON.parse(trans.description).description
                                  : trans.description}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6">no data found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <div className="mt-4 mb-1">
                      <Pagination
                        selectedPage={paginationData.currentPage}
                        pageCount={paginationData.totalPages}
                        onPageChangeHandler={(page) => {
                          setPaginationData({
                            ...paginationData,
                            currentPage: page,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default TransactionListing;
