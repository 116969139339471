import { Form, Formik } from "formik";
import InputField from "../../components/form/input/input";
import ButtonField from "../../components/form/button";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import httpRequest from "../../axios";
import {
  CREATE_PROFILES,
  CREATE_SUPPLIER_PROFILES,
  UPDATE_PROFILES,
  UPDATE_SUPPLIER_PROFILES,
} from "../../constant/apiEndPoints";
import show_Toast from "../../helpers/toast.helper";
import { AddProfileValidations } from "../../utils/validations/profile.validations";
import SelectField from "../../components/form/select";
import { profile_type } from "../../constant";
import CustomLoader from "../../components/common/customLoader/customLoader";

const ProfileForm = ({ initialValues, type, id, profileTypes = "" }) => {
  const history = useHistory();

  const [isLoading, setLoading] = useState(false);

  const profileType = [
    { name: "customer" },
    { name: "supplier" },
    { name: "system_user" },
  ];

  const createProfile = async (values, setSubmitting) => {
    setLoading(true);
    try {
      if (type === "add") {
        var { data } = await httpRequest.post(CREATE_SUPPLIER_PROFILES, {
          data: { ...values, status: "ACTIVE" },
          profile_type: profileTypes === "customer" ? "CUSTOMER" : "SUPPLIER",
        });
      } else {
        var { data } = await httpRequest.put(
          UPDATE_SUPPLIER_PROFILES + "/update/" + id,
          {
            data: values,
            status: "ACTIVE",
            profile_type: profileTypes === "customer" ? "CUSTOMER" : "SUPPLIER",
          }
        );
      }

      setSubmitting(false);
      setLoading(false);
      show_Toast({
        status: true,
        message:
          data.status === "Success" && type === "edit"
            ? "Profile Updated successfully"
            : "Profile Created successfully",
      });
      history.push("/profiles/supplier");
    } catch (error) {
      setSubmitting(false);
      setLoading(false);
      show_Toast({
        status: false,
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };

  const handleReset = (resetForm) => {
    resetForm(initialValues); // Reset the form values to initial values
  };

  return (
    <>
      {isLoading ? (
        <CustomLoader />
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            createProfile(values, setSubmitting);
          }}
          validationSchema={AddProfileValidations}
          enableReinitialize
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              handleBlur,
              handleChange,
              resetForm,
              isSubmitting,
            } = props;
            return (
              <Form className="form theme-form">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <InputField
                        placeholder="Name"
                        label="Name"
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={52}
                        errorMessage={
                          errors.name && touched.name && errors.name
                        }
                        error={errors.name && touched.name ? true : false}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputField
                        placeholder="Phone Number"
                        label="Phone Number"
                        type="text"
                        name="mobile_number"
                        value={values.mobile_number}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={
                          errors.mobile_number &&
                          touched.mobile_number &&
                          errors.mobile_number
                        }
                        error={
                          errors.mobile_number && touched.mobile_number
                            ? true
                            : false
                        }
                      />
                    </div>
                    <div className="col-md-6">
                      <InputField
                        placeholder="Account Number"
                        label="Account Number"
                        type="text"
                        name="account_number"
                        value={values.account_number}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={
                          errors.account_number &&
                          touched.account_number &&
                          errors.account_number
                        }
                        error={
                          errors.account_number && touched.account_number
                            ? true
                            : false
                        }
                      />
                    </div>
                    <div className="col-md-6">
                      <InputField
                        placeholder="Email"
                        label="Email"
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={
                          errors.email && touched.email && errors.email
                        }
                        error={errors.email && touched.email ? true : false}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputField
                        placeholder="Address"
                        label="Address"
                        type="text"
                        name="address"
                        value={values.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={
                          errors.address && touched.address && errors.address
                        }
                        error={errors.address && touched.address ? true : false}
                      />
                    </div>
                    <div className="col-md-6 d-none">
                      <div className="form-group">
                        <label htmlFor="exampleFormControlSelect9">
                          {profile_type}
                        </label>
                        <SelectField
                          className={"form-control digits"}
                          name="profile_type"
                          value={values.profile_type}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          options={profileType}
                          errorMessage={errors.profile_type}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer d-flex justify-content-end gap-2">
                  <ButtonField
                    className={"btn btn-red"}
                    buttonText={"Reset"}
                    type="button"
                    onClick={() => handleReset(resetForm)}
                  />
                  <ButtonField
                    className={"btn btn-primary"}
                    buttonText={type === "edit" ? "Update" : "Create"}
                    disabled={isSubmitting}
                    type="submit"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default ProfileForm;
