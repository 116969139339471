import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import { useParams } from "react-router-dom";
// import ProfileForm from "./../profiles/profileForm";
import { useSelector } from "react-redux";
import OwnProfileForm from "./ownProfileForm";
import httpRequest from "../../axios";
import { PAGE_TITLES } from "../../constant/pageTitles";
import HelmetComponent from "../../components/common/Helmet";

const UserProfile = () => {
  const { type } = useParams();
  const {
    user: { id },
  } = useSelector((state) => state.login);
  const [initialValues, setIntialValues] = useState({
    name: "",
    email: "",
    mobile: "",
    address: "",
  });

  const getCurrentUserValues = async (user) => {
    const { data } = await httpRequest.get(`/getUser/${user}?with[]=role`);
    const { name, email, mobile, address } = data?.data;
    setIntialValues({
      name,
      email,
      mobile,
      address,
    });
  };

  useEffect(() => {
    getCurrentUserValues(id);
  }, []);

  return (
    <Fragment>
      <HelmetComponent titleText={PAGE_TITLES["EditOwnProfile"]} />
      <Breadcrumb
        title="Add New Profile"
        parent="Profiles"
        parentlink="Profiles"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card mt-2">
              <div className="card-header">
                <h5 className="text-capitalize">{`Edit Profile`}</h5>
              </div>
              <OwnProfileForm
                initialValues={initialValues}
                id={id}
                type="add"
                profileTypes={type}
                setIntialValues={setIntialValues}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UserProfile;
