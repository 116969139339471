import React, { Fragment, useEffect, useState, useRef } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import { Link } from "react-router-dom";
import { getAddonServices } from "../../services";
import show_Toast from "../../helpers/toast.helper";
import CustomLoader from "../../components/common/customLoader/customLoader";
import AddonForm from "./form";
import FilterForm from "./filterform";
import "./index.scss";
import Search from "../../components/common/search/search";
import { Pagination } from "../../components/common/pagination";
import { PER_PAGE_API } from "../../constant/pagination";
import { Edit, Filter, ChevronDown, ChevronUp } from "react-feather";
import InputField from "../../components/form/input/input";
import ButtonField from "../../components/form/button";
import { Accordion } from "react-bootstrap";
import { Card, CardBody, Collapse, Button } from "reactstrap";
import PermissionBase from "../../components/common/permissionBase/permissionBase";
import { PAGE_TITLES } from "../../constant/pageTitles";
import HelmetComponent from "../../components/common/Helmet";
import { FieldOptions, StatusOptions } from "../../constant";
import SearchSelectField from "../../components/form/select/SearchSelect";

const AddonListing = () => {
  const [isLoading, setLoading] = useState(false);
  const formRef = useRef(null);

  const [search, setSearch] = useState("");
  const [apply, setApply] = useState(false);
  const [filters, setFilters] = useState({
    shopIncome: "ALL",
    status: "ALL",
  });
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
    perPage: 10,
  });

  const [orderAddonList, setOrderAddonList] = useState([]);
  const [transition, setTransition] = useState(false);
  const [editId, setEditid] = useState("");
  const [formType, setFormType] = useState("add");
  const [initialValues, setIntialValues] = useState({
    id: "",
    title: "",
    is_shop_income: "",
  });

  const [isOpen, setIsOpen] = useState(0);
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));

  const getAddonListing = async () => {
    try {
      setLoading(true);
      const { data } = await getAddonServices(
        paginationData.perPage,
        paginationData.currentPage,
        filters,
        search
      );
      if (data.status === "Success") {
        setOrderAddonList(data?.data?.data);
      }
      setPaginationData((pre) => ({
        ...pre,
        currentPage: data?.data?.current_page,
        totalRecords: data?.data?.total,
        perPage: data?.data?.per_page,
        totalPages: Math.ceil(data?.data?.total / data?.data?.per_page),
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      show_Toast({
        status: false,
        message:
          error?.response?.data?.message ||
          "Something went wrong please reload the page or contact with admin",
      });
    }
  };

  useEffect(() => {
    getAddonListing();
  }, [paginationData.currentPage, search, apply]);

  const formSuccessHandler = () => {
    getAddonListing();
  };

  const handleAddaddon = () => {
    setFormType("add");
    setTransition(true);
    setIntialValues({
      id: "",
      title: "",
      is_shop_income: "",
    });
  };

  const handleEditAddon = (addon) => {
    setEditid(addon?.id);
    setIntialValues({
      ...initialValues,
      id: addon?.id,
      title: addon.title,
      is_shop_income: addon.is_shop_income,
    });
    setFormType("edit");
  };

  const filterApply = () => {
    getAddonListing();
  };
  const clearFilter = () => {
    setPaginationData((prevState) => ({
      ...prevState,
      currentPage: 1,
    }));
    setFilters({
      shopIncome: "",
      status: "",
    });
    getAddonListing();
  };

  const onChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  return (
    <Fragment>
      <HelmetComponent titleText={PAGE_TITLES["OrderAddOn"]} />
      {isLoading ? (
        <CustomLoader />
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div
                className={`card mt-2 section ${
                  transition ? "show-units" : "hide"
                }`}
                ref={formRef}
              >
                <AddonForm
                  type={formType}
                  initialValues={initialValues}
                  formSuccessHandler={formSuccessHandler}
                  editId={editId}
                  setTransition={setTransition}
                  formRef={formRef}
                  setApply={setApply}
                  isLoading={isLoading}
                  setLoading={setLoading}
                  setIntialValues={setIntialValues}
                />
              </div>
            </div>
          </div>
          <div className={`row`}>
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between items-center">
                  <h5 className="text-capitalize">{"All Add On"}</h5>
                  <PermissionBase permissionName={["create_order_add_on"]}>
                    <button
                      className="btn btn-success"
                      onClick={handleAddaddon}
                    >
                      Add Add-On
                    </button>
                  </PermissionBase>
                </div>
                <div className="card-body">
                  <div className="mb-3 d-flex justify-content-end">
                    <Search
                      classes="form-control search-input"
                      placeholder="Search"
                      onChange={(e) => {
                        setSearch(e.target.value);
                        setPaginationData((pre) => ({
                          ...pre,
                          currentPage: 1,
                        }));
                      }}
                      value={search}
                    />
                  </div>
                  <FilterForm
                    filters={filters}
                    setFIlters={setFilters}
                    onChange={onChange}
                    onFilterApply={filterApply}
                    onResetApply={clearFilter}
                    fieldOptions={FieldOptions}
                    statusOptions={StatusOptions}
                  />
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Id</th>
                          <th scope="col">Title</th>
                          <th scope="col">Shop Income</th>
                          <th scope="col">Status</th>
                          <PermissionBase
                            permissionName={["update_order_add_on"]}
                          >
                            <th scope="col">Action</th>
                          </PermissionBase>
                        </tr>
                      </thead>
                      <tbody>
                        {orderAddonList?.length ? (
                          orderAddonList?.map((addon, ind) => (
                            <tr key={ind}>
                              <td>{addon?.id}</td>
                              <td>{addon?.title}</td>
                              <td>{addon?.is_shop_income}</td>
                              <td>{addon?.status}</td>
                              <PermissionBase
                                permissionName={["update_order_add_on"]}
                              >
                                <td>
                                  <span
                                    className="pointer-cursor"
                                    onClick={() => {
                                      handleEditAddon(addon);
                                      setTransition(true);
                                      window.scrollTo({
                                        top: formRef.current.offsetTop,
                                        behavior: "smooth",
                                      });
                                    }}
                                  >
                                    <Edit size={20} color="green" />
                                  </span>
                                </td>
                              </PermissionBase>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6">no data found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <div className="mt-4 mb-1">
                      <Pagination
                        selectedPage={paginationData.currentPage}
                        pageCount={paginationData.totalPages}
                        onPageChangeHandler={(page) => {
                          setPaginationData({
                            ...paginationData,
                            currentPage: page,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default AddonListing;
