import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../components/common/breadcrumb";

import RolesForm from "./form";
import { useParams, useHistory } from "react-router-dom";
import show_Toast from "../../helpers/toast.helper";
import { getRoleById } from "../../services";
import HelmetComponent from "../../components/common/Helmet";
import { PAGE_TITLES } from "../../constant/pageTitles";

const EditRole = () => {
  const history = useHistory();
  const { id } = useParams();

  const [preSelectedPermission, setPreSelectedPermission] = useState([]);

  const [initialValues, setIntialValues] = useState({
    name: "",
    terms_conditions: "",
    is_private: false,
  });

  const getRoleValues = async (id) => {
    try {
      const { data } = await getRoleById(id);
      if (data.status === "Success") {
        const {
          name = "",
          terms_conditions = "",
          is_private = 0,
          permissions = [],
        } = data?.data;
        setIntialValues({
          name,
          terms_conditions,
          is_private: is_private ? 1 : 0,
        });
        if (permissions.length) {
          const getIds = permissions.map((perm) => perm.id);
          setPreSelectedPermission(getIds);
        }
      }
    } catch (error) {
      show_Toast({
        status: false,
        message:
          error?.response?.data?.message ||
          "Something went wrong please reload the page or contact with admin",
      });
      history.push("/roles");
    }
  };

  useEffect(() => {
    if (id) {
      getRoleValues(id);
    } else {
      history.push("/roles");
    }
  }, [id]);

  return (
    <Fragment>
      <HelmetComponent titleText={PAGE_TITLES["Roles"]} />
      <Breadcrumb title="Edit  Role" parent="Form" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card mt-2">
              <div className="card-header">
                <h5 className="text-capitalize">Edit Role</h5>
              </div>

              <RolesForm
                type="edit"
                initialValues={initialValues}
                preSelectedPermission={preSelectedPermission}
                id={id}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditRole;
