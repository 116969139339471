import React from "react";
import "../input/index.scss";

const SelectField = ({
  className,
  value,
  name,
  onChange,
  options = [],
  valueKey = "id",
  labelKey = "name",
  errorMessage = "",
  disabled = false,
  selectLabel = "-Select-",
}) => {
  return (
    <>
      <select
        className={`text-capitalize ${className} ${
          errorMessage ? "inputError" : "inputclass"
        }`}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        <option value="">{selectLabel}</option>
        {options?.length &&
          options.map((option, i) => (
            <option key={i} value={option[valueKey]} disabled={option.disabled}>
              {option[labelKey]}
            </option>
          ))}
      </select>
      {errorMessage && <span className="error-message">{errorMessage}</span>}
    </>
  );
};

export default SelectField;
