import React, { Fragment, useState } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import KeyListings from "./keyListings";
import PermissionBase from "../../../components/common/permissionBase/permissionBase";
import { PAGE_TITLES } from "../../../constant/pageTitles";
import HelmetComponent from "../../../components/common/Helmet";

const AddAttributeKey = () => {
  return (
    <Fragment>
      <HelmetComponent titleText={PAGE_TITLES["AttributesKey"]} />
      <Breadcrumb title="Add Attribute" parent="Attribute" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <KeyListings />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddAttributeKey;
