import React from "react";
import FilterAccordian from "../../components/common/Filter/filter";
import InputField from "../../components/form/input/input";
import SearchSelectField from "../../components/form/select/SearchSelect";
import ButtonField from "../../components/form/button";
import KeyListings from "./keys/keyListings";

const AttributesFilters = ({
  filters,
  setFilters,
  setApply,
  onChange,
  keysList,
}) => {
  const handleReset = () => {
    setFilters({
      attribute_key: "",
      attribute_value: "",
    });
  };

  return (
    <FilterAccordian>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setApply((pre) => !pre);
        }}
      >
        <div className="border-top p-3">
          <div className="row">
            <div className="col-2">
              <label htmlFor="" className="text-capitalize">
                Attribute Key
              </label>
              <SearchSelectField
                className={"inputclass rounded flex-grow-1"}
                name="attribute_key"
                value={filters.attribute_key}
                valueKey="id"
                labelKey="title"
                onChange={(e) => onChange("attribute_key", e.id)}
                options={keysList}
              />
            </div>
            <div className="col-2">
              <InputField
                label="Attribute Value"
                placeholder={"Attribute Value"}
                name="attribute_value"
                value={filters.attribute_value}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </div>
            <div className="col-2">
              <div className="d-flex gap-2 h-100 align-items-center">
                <ButtonField
                  buttonText="Reset"
                  onClick={handleReset}
                  className="btn btn-danger"
                />
                <ButtonField type="Submit" buttonText="Apply" />
              </div>
            </div>
          </div>
        </div>
      </form>
    </FilterAccordian>
  );
};

export default AttributesFilters;
