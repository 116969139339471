import React from "react";
import "./index.css"
const TextArea = ({
  label,
  type,
  name,
  placeholder = "enter your name",
  value,
  onChange,
  onBlur,
  error,
  errorMessage,
  disabled = false,
  textAreaRows
}) => {
  return (
    <div className="form-group mb-4">
      <label className="col-form-label pt-0">{label}</label>
      <textarea
        className={`form-control textareaclass ${error ? "inputError" : ""}`}
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        rows={textAreaRows}
        // onBlur={onBlur}
        disabled={disabled}
      ></textarea>
      <span className="error-message"> {errorMessage ? errorMessage : ""}</span>
    </div>
  );
};

export default TextArea;
