import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import { Link } from "react-router-dom";
import { getProductService } from "../../services";
import show_Toast from "../../helpers/toast.helper";
import CustomLoader from "../../components/common/customLoader/customLoader";
import { Pagination } from "../../components/common/pagination";
import Search from "../../components/common/search/search";
import { Edit, Filter, Edit2, Copy } from "react-feather";
import ProductFilters from "./productFilters";
import PermissionBase from "../../components/common/permissionBase/permissionBase";
import ButtonField from "../../components/form/button";
import HelmetComponent from "../../components/common/Helmet";
import { PAGE_TITLES } from "../../constant/pageTitles";

const ProductListing = ({
  productsList,
  setSearch,
  search,
  paginationData,
  setPaginationData,
  isLoading,
  filters,
  setFilters,
  setApply,
  onChange,
  setIsOpen,
  isOpen,
  toggle,
  handlePriceUpdate,
  price,
  setPrice,
  setShowModal,
  setProductId,
}) => {
  return (
    <>
      <div className="card-body">
        <HelmetComponent titleText={PAGE_TITLES["Products"]} />
        <div className="mb-3 d-flex justify-content-end">
          <Search
            classes="form-control search-input"
            placeholder="Search"
            onChange={(e) => {
              setSearch(e.target.value);
              setPaginationData((pre) => ({
                ...pre,
                currentPage: 1,
              }));
            }}
            value={search}
          />
        </div>

        <ProductFilters
          filters={filters}
          setFilters={setFilters}
          setApply={setApply}
          onChange={onChange}
        />

        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Name</th>
                <th scope="col">Code</th>
                <th scope="col">Unit Price</th>
                <th scope="col">Gst</th>
                <th scope="col">Stock</th>
                <th scope="col">Description</th>
                <th scope="col">Status</th>
                <PermissionBase permissionName={["update_products"]}>
                  <th scope="col">Action</th>{" "}
                </PermissionBase>
              </tr>
            </thead>
            <tbody>
              {productsList.length
                ? productsList?.map((product, ind) => (
                    <tr key={ind}>
                      <td>{product?.id}</td>
                      <td>{product?.data?.name}</td>
                      <td>{product?.data?.code}</td>
                      <td>{product?.data?.unit_price}</td>
                      <td>{product?.data?.gst}</td>
                      <td>{product?.data?.stock_with_selling_unit}</td>
                      <td>{product?.data?.description}</td>
                      <td>{product?.status}</td>
                      <PermissionBase permissionName={["update_products"]}>
                        <td>
                          <div className="d-flex align-items-center justify-content-start gap-2">
                            <Link
                              to={`/product/edit/${product?.id}`}
                              className=""
                            >
                              <Edit size={20} color="green" />
                            </Link>
                            <button
                              // to={{
                              //   pathname: `/products/add`,
                              //   state: { cloneProduct: product?.id },
                              // }}
                              className="bg-transparent border-0 outline-0"
                              onClick={() => {
                                const id = product?.id;
                                if (id) {
                                  localStorage.setItem("cloneProduct", id);
                                  window.open("/products/add", "_blank");
                                }
                              }}
                              // target="_blank"
                            >
                              <Copy size={20} color="green" />
                            </button>
                            <ButtonField
                              buttonText="Update price"
                              className={"btn btn-primary btn-sm p-1"}
                              onClick={() => {
                                setPrice(product?.data?.unit_price);
                                setProductId(product?.id);
                                setShowModal(true);
                              }}
                            />
                          </div>
                        </td>
                      </PermissionBase>
                    </tr>
                  ))
                : null}
              {!isLoading && !productsList.length ? "no data found" : null}
            </tbody>
          </table>
          <div className="mt-4 mb-1">
            <Pagination
              selectedPage={paginationData.currentPage}
              pageCount={paginationData.totalPages}
              onPageChangeHandler={(page) => {
                setPaginationData({
                  ...paginationData,
                  currentPage: page,
                });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductListing;
