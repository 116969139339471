import { Form, Formik } from "formik";
import InputField from "../../components/form/input/input";
import ButtonField from "../../components/form/button";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import httpRequest from "../../axios";
import {
  CREATE_TRANSACTION,
  CUSTOMER_MAKE_PAYMENT,
} from "../../constant/apiEndPoints";
import show_Toast from "../../helpers/toast.helper";
import { useSelector } from "react-redux";
import CustomLoader from "../../components/common/customLoader/customLoader";
import SearchSelectField from "../../components/form/select/SearchSelect";
import { MakePaymentValidation } from "../../utils/validations/payment.validation";
import {
  PaymentOptions,
  PaymentOptionsOrder,
  PaymentTypeOptions,
} from "../../constant";
import "./index.scss";
import { hasPermissions } from "../../helpers/permissions.helper";
import { PERMISSION_NAMES } from "../../constant/routePermissions";
import { Route, Redirect } from "react-router-dom";

const PaymentForm = ({
  initialValues,
  type,
  id,
  profileTypes = "",
  profileId,
  orderId,
  setTransition,
  setProfileId,
  setOrderId,
  setPayable,
  payable,
  setFormSubmit,
}) => {
  const { permissions } = useSelector((state) => state.login);
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);
  const minDate = currentDate.toISOString().split("T")[0];

  const [isLoading, setLoading] = useState(false);

  const createPayment = async (values, setSubmitting) => {
    const { payment_method, ...restValues } = values;
    setLoading(true);

    let valuesModified = {
      ...restValues,
      transaction_type: { payment_method },
      profile_id: profileId,
    };
    try {
      var { data } = await httpRequest.post(
        CUSTOMER_MAKE_PAYMENT,
        valuesModified
      );
      setSubmitting(false);
      setLoading(false);
      if (data.status === "Success") {
        setProfileId("");
        setTransition(false);
      }
      setFormSubmit((pre) => !pre);
      show_Toast({
        status: true,
        message:
          data.status === "Success" ? "Payment successfull" : "Payment Fail",
      });
    } catch (error) {
      setSubmitting(false);
      setLoading(false);
      show_Toast({
        status: false,
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        if (permissions.length) {
          if (
            !hasPermissions(permissions, PERMISSION_NAMES["create_transaction"])
          ) {
            return <Redirect to={{ pathname: "/not-allowed" }} />;
          }
        }
        createPayment(values, setSubmitting);
      }}
      validationSchema={MakePaymentValidation(payable)}
      enableReinitialize
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          setFieldValue,
          resetForm,
          isSubmitting,
        } = props;
        return (
          <Form className={`form theme-form`}>
            <div className="card-header d-flex justify-content-between align-items-center">
              <h5 className="text-capitalize">{`Make Payment`}</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-2 col-md-2 col-lg-2">
                  <label htmlFor="" className="text-capitalize">
                    Payment Type
                  </label>
                  <SearchSelectField
                    className={"inputclass rounded flex-grow-1"}
                    name="payment_type"
                    value={values.payment_type}
                    onChange={(e) => {
                      setFieldValue("payment_type", e.id);
                    }}
                    onBlur={handleBlur}
                    options={PaymentTypeOptions}
                    errorMessage={errors.payment_type}
                  />
                </div>

                <div className="col-sm-2 col-md-2 col-lg-2">
                  <InputField
                    placeholder="Amount"
                    label="Amount"
                    type="number"
                    name="amount"
                    value={values.amount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    // max={payable}
                    // step="0.01"
                    errorMessage={
                      errors.amount && touched.amount && errors.amount
                    }
                    error={errors.amount && touched.amount ? true : false}
                  />
                </div>

                <div className="col-sm-2 col-md-2 col-lg-2">
                  <InputField
                    placeholder="Deposit date"
                    label="Deposit date"
                    type="date"
                    name="deposit_date"
                    value={values.deposit_date}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    min={new Date().toISOString().split("T")[0]}
                    errorMessage={
                      errors.deposit_date &&
                      touched.deposit_date &&
                      errors.deposit_date
                    }
                    error={
                      errors.deposit_date && touched.deposit_date ? true : false
                    }
                  />
                </div>

                <div className="col-sm-2 col-md-2 col-lg-2">
                  <label htmlFor="" className="text-capitalize">
                    Payment method
                  </label>
                  <SearchSelectField
                    className={"inputclass rounded flex-grow-1"}
                    name="payment_method"
                    value={values.payment_method}
                    onChange={(e) => {
                      setFieldValue("payment_method", e.id);
                    }}
                    onBlur={handleBlur}
                    options={PaymentOptionsOrder}
                    errorMessage={errors.payment_method}
                  />
                </div>
                <div className="col-sm-4 col-md-4 col-lg-4 mt-3 mt-sm-0">
                  <InputField
                    placeholder="Transaction id, reference, details"
                    label="Description"
                    type="text"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="d-flex justify-content-end align-items-center gap-2">
                  <ButtonField
                    type="button"
                    className="btn btn-red"
                    onClick={() => {
                      setTransition(false);
                      resetForm();
                    }}
                    buttonText="Cancel"
                  />
                  <ButtonField
                    className={"btn btn-primary"}
                    buttonText={type === "edit" ? "Update" : "Create"}
                    type="submit"
                  />
                </div>
              </div>
            </div>
            {isLoading && (
              <div>
                <CustomLoader />
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default PaymentForm;
