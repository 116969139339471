import React from "react";
import FilterAccordian from "../../../components/common/Filter/filter";
import SearchSelectField from "../../../components/form/select/SearchSelect";
import InputField from "../../../components/form/input/input";
import ButtonField from "../../../components/form/button";
import { StatusOptions } from "../../../constant";

const KeysFilter = ({ filters, setFilters, setApply, onChange }) => {
  const handleReset = () => {
    setFilters({
      id: "",
      name: "",
      status: "",
    });
  };
  return (
    <FilterAccordian>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setApply((pre) => !pre);
        }}
      >
        {/* keysList */}
        <div className="border-top p-3">
          <div className="row">
            <div className="col-2">
              <InputField
                label="Attribute Key Id"
                placeholder={"Attribute Key Id"}
                name="id"
                value={filters.id}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </div>
            <div className="col-2">
              <InputField
                label="Name"
                placeholder={"Name"}
                name="name"
                value={filters.name}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </div>
            <div className="col-2">
              <label htmlFor="" className="text-capitalize">
                Status
              </label>
              <SearchSelectField
                className={"inputclass rounded flex-grow-1"}
                name="status"
                value={filters.status}
                valueKey="value"
                labelKey="label"
                onChange={(e) => onChange("status", e.value)}
                options={StatusOptions}
              />
            </div>

            <div className="col-2">
              <div className="d-flex gap-2 h-100 align-items-center">
                <ButtonField
                  buttonText="Reset"
                  onClick={handleReset}
                  className="btn btn-danger"
                />
                <ButtonField type="Submit" buttonText="Apply" />
              </div>
            </div>
          </div>
        </div>
      </form>
    </FilterAccordian>
  );
};

export default KeysFilter;
