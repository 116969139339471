import React, { useEffect, useState } from "react";
import { calculateGST } from "../../../services";

const ReturnProductDetailTable = ({
  index,
  products,
  code,
  totalPrice,
  productPrice,
  discount,
  productGst,
  label,
}) => {
  const InitialGstTotal =
    products[index]?.productGst && products[index].totalPrice
      ? calculateGST(products[index].totalPrice, products[index].productGst)
      : 0;

  const InitialSubtotal = products[index].totalPrice;

  const InitialTotal = products[index].totalPrice
    ? products[index].totalPrice +
      calculateGST(products[index].totalPrice, products[index].productGst)
    : "";

  const Subtotal = totalPrice - discount;

  const GstTotal =
    productGst && Subtotal ? calculateGST(Subtotal, productGst) : 0;

  const Total = Subtotal ? Subtotal + calculateGST(Subtotal, productGst) : "";

  return (
    <table
      className="table table-bordered"
      style={{ fontSize: "12px", marginBottom: 0 }}
    >
      <thead>
        <tr>
          <th scope="col" className="p-1 text-start">
            Code
          </th>
          <th scope="col" className="p-1 text-start">
            Title
          </th>
          <th scope="col" className="p-1 text-start">
            GST ({productGst}%) / {InitialGstTotal}
          </th>
          <th scope="col" className="p-1 text-start">
            Subtotal / {InitialSubtotal}
          </th>
          <th scope="col" className="p-1 text-start">
            Total / {InitialTotal}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="p-1 text-start">{code}</td>
          <td className="p-1 text-start">{label}</td>
          <td className="p-1 text-start">
            {GstTotal ? Number(GstTotal.toFixed(2)) : ""}
          </td>
          <td className="p-1 text-start">
            {Subtotal ? Number(Subtotal.toFixed(2)) : ""}
          </td>
          <td className="p-1 text-start">
            {Total ? Number(Total?.toFixed(2)) : ""}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ReturnProductDetailTable;
